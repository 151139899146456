<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.admin_operation_logs}}</h2>
       <Breadcrumb :item_one_title="locale.manage+' '+ locale.admin_operation_logs" :item_one_active="true" :loading="data.loading" />
       
        <button @click="showFilters" class="btn btn-outline-secondary mb-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>
        <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-6">
                    <label class="form-label">{{locale.search}}</label>
                    <input v-model="data.search" type="text" class="form-control" placeholder="by username, module, operation"  aria-describedby="button-addon2">
                </div>
            </div>
            

            <button @click="searchRecords" class="btn btn-secondary my-1 mx-1" type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-1 mx-1" type="button" id="button-addon2">{{locale.reset}}</button>

            <hr>
        </div>

        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Admin UserID</th>
                         <th scope="col">Admin Username</th>
                        <th scope="col">Role</th>
                        <th scope="col">Module</th>
                        <th scope="col">Module ID</th>
                        <th scope="col">Operation</th>
                        <th scope="col">Operation Information</th>
                        <th scope="col">{{locale.date_created}}</th>
                        <th scope="col">{{locale.action}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,i) in data.records" v-bind:key="i">
                    <td>{{++i}}</td>
                    <td>{{item.admin_user_id}}</td>
                    <td>{{item.admin_username}}</td>
                    <td>{{item.user_role}}</td>
                    <td>{{item.module}}</td>
                    <td>{{item.module_mysql_id}}</td>
                    <td>{{item.operation}}</td>
                    <td>{{item.module_extra_info}}</td>
                    <td>{{ $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</td>
                    <td>
                        <button @click="showModals(item)" type="button" class="btn btn-primary btn-sm my-1 mx-1">{{locale.detail}}</button>
                        <!-- <button  @click="deleteRecord(item._id)" type="button" class="btn btn-danger btn-sm ms-1">{{locale.delete}}</button> -->
                    </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
        </div>

         <!-- Modal -->
        <div v-if="data.showModal" style="display: block;" class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                <div class="modal-header bg-primary text-white">
                    <h5 class="modal-title" id="exampleModalLabel">{{locale.detail}}</h5>
                    <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    
                   
                    <div v-for="(key,i) in Object.keys(data.obj)" class="mb-3" v-bind:key="i">
                        <label class="form-label">{{key.charAt(0).toUpperCase()+key.slice(1)}}: </label>
                        <b>&nbsp; {{data.obj[key]}}</b>
                    </div>
                    <hr>
                    <div class="mb-3">
                        <label class="form-label">JSON Data </label>
                        <br> 
                        <code>{{data.obj}}</code>
                    </div>
                       
                </div>
                <div class="modal-footer">
                    <button @click="closeModal" type="button" class="btn btn-danger" data-bs-dismiss="modal">{{locale.close}}</button>
                </div>
                </div>
            </div>
        </div>


    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store';
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"

export default{
    name:'ManageAdminOperationLogs',
    components: {
      Breadcrumb,
      VPagination
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/admin-operation-logs',
            page : 1,
            perPage : 20,
            totalPages : 0,
            
            records: [],
            loading: false,

            showFilter: false,
            search: '',
            
            showModal : false,

            

            obj: {
                _id : '',
                admin_user_id : '',
                admin_username : '',
                user_role : '',
                module : '',
                operation : '',
                module_mysql_id : '',
                module_extra_info : '',
                createdAt : '',
                updatedAt : ''
            },

        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            getRecords()

        })

        const getRecords = async() => {
            data.loading = true
            let url = data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&search="+data.search
            let resData = await callApi(url, 'GET')
           
            data.records = resData.data
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.loading = false
        }
        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
                data.loading = true
                let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
                data.loading = false
                if(resData.isSuccess){
                    notify({text: resData.message, type:'success'});
                    getRecords()
                }else{
                    notify({text: resData.message, type:'error'})
                }
          }
        }

        const searchRecords = async() => {
            data.page = 1
            getRecords()
        }
        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }
        const resetFilters = async() => {
            data.search = ''
            searchRecords()
        }

        const showModals = async(item) => {
            
            Object.assign(data.obj, item)
            data.showModal = true
        }

        const closeModal = () => {
            data.showModal = false
        }
        

        return {
            data,
            locale,
            deleteRecord,
            getRecords,
            searchRecords,
            showFilters,
            resetFilters,
            showModals,
            closeModal
        }
    }
}

</script>