
export function loadLang(lang) {
    return localeObj[lang]
}

const localeObj = {

    'en': {
        home: 'Home',
        settings: 'Settings',
        change_locale: 'Change Language',
        dashboard: 'Dashboard',
        login_username: 'Username',
        login_password: 'Password',
        submit: 'Submit',
        logout: 'Logout',
        manage_admin_users: 'Manage Admin Users',
        add_new_user: 'Add new user',
        username: 'Username',
        password: 'Password',
        password_change_info: 'If you want to change password then please enter new password otherwise leave this input field empty.',
        email: 'Email',
        phone: 'Phone',
        active: 'Active',
        in_active: 'In Active',
        status: 'Status', 
        display: 'Display',
        dispaly_all: 'For all Users',
        dispaly_only_for_main: 'Only for main App Users',
        dispaly_only_for_pro: 'Only for Pro App Users', 
        dispaly_table_for_main: 'Main',
        dispaly_table_for_pro: 'PRO',   
        dispaly_table_all: 'All', 
        role: 'Role',
        permission: 'Permission',
        date_created: 'Date Created',
        date_update: 'Date Updated',
        action: 'Action',
        add: 'Add',
        edit: 'Edit',
        delete: 'Delete',
        go_back: 'Go Back',
        select: 'Select',
        assign_permissions: 'Assign Permissions',
        manage_permissions: 'Manage Permissions',
        all_permissions: 'All permissions',
        manage_roles: 'Manage Roles',
        name: "Name",
        key: "Key",
        manage_currency: 'Manage Currency',
        currency_code: 'Currency Code',
        currency_symbol: 'Currency Symbol',
        confirm_delete: 'Are you sure to perform this action?',
        manage_package: 'Manage Package',
        billed: 'Billed',
        sorting_order: 'Sorting order',
        description: 'Description',
        monthly: 'Monthly',
        yearly: 'Yearly',
        manually: 'Manually',
        title: 'Title',
        for: "for",
        all: "All", 
        android: "Android", 
        ios: "IOS",
        languages: "Languages",
        price: 'Price',
        discount: 'Discount',
        currencies: "currencies",
        detail: 'Detail',
        speed_limit: 'Speed limit',
        manage: 'Manage',
        payment: 'Payment',
        methods: 'Methods',
        mode: 'Mode',
        live: 'Live',
        test: 'Test',
        data: 'Data',
        icon: 'Icon',
        code: 'Code',
        channels: 'Channels',
        groups: 'Groups',
        group: 'Group',
        remark: 'Remark',
        info: 'Info',
        assign: 'Assign',
        to: 'to',
        method: 'method',
        shortcuts: 'Shortcuts',
        link: 'Link',
        view: 'View',
        list: 'List',
        users: 'Users',
        user: 'User',
        search: 'Search',
        servers: 'Servers',
        areas: 'Areas',
        types: 'Types',
        ip: 'IP',
        port: 'Port',
        ports: 'Ports',
        total: 'Total',
        clients: 'Clients',
        announcements: 'Announcements',
        created: 'Created',
        expired: 'Expired',
        at: 'at',
        url: 'Url',
        yes: 'Yes',
        no: 'No',
        base_currency: 'Base Currency',
        config: 'Config',
        order: 'Order',
        orders: 'Orders',
        number: 'number',
        amount: 'Amount',
        package: 'Package',
        paid: 'Paid',
        un_paid: 'Unpaid',
        started: 'Started',
        completed: 'Completed',
        pending: 'Pending',
        cancelled: 'Cancelled',
        rejected: 'Rejected',
        refund: 'Refund',
        default: 'Default',
        allowed: 'Allowed',
        devices: 'Devices',
        additonal: 'Additonal',
        version: 'Version',
        version_old: 'Version old',
        sha256: 'sha256',
        pack_size: 'Pack size',
        force: 'Force',
        type: 'Type',
        force_type_zero: 'User chooses to update',
        force_type_one: 'Force to update all',
        currency: 'Currency',

        apply: 'Apply',
        filters: 'Filters',
        refresh: 'Refresh',
        reset: 'Reset',
        close: 'Close',
        plan_time: 'Plan time',
        minutes: 'Minutes',
        packages: 'Packages',
        start: 'Start',
        end: 'End',
        date: 'Date',
        free: 'Free',
        premium: 'Premium',
        both: 'Both',
        transfer: 'Transfer',
        id: 'Id',
        url_config: 'Url config',
        value: 'Value',
        extra: 'Extra',

        online: 'Online',
        today: 'Today',
        new: 'New',
        not: 'Not',
        confirmed: 'Confirmed',
        trial: 'Trial',
        reward: 'Reward',
        login: 'Login',
        level: 'Level',
        language: 'Language',
        invite: 'Invite',
        referrer: 'Referrer',
        faq: 'FAQ',
        question: 'Question',
        answer: 'Answer',
        blog: 'Blog',
        slug: 'Slug',
        viewed: 'Viewed',
        image: 'Image',
        meta: 'Meta',
        text: 'Text',
        optional: 'Optional',
        keywords: 'keywords',
        welcome: 'Welcome',
        console: 'Console',
        author: 'Author',
        category: 'Category',
        months: 'Months',
        recommended: 'Recommended',
        news: 'News',
        ticket: 'Tickets',
        subject: 'Subject',
        resolved: 'Resolved',
        replied: 'Replied',
        spam: 'Spam',
        reply: 'Reply',
        message: 'Message',
        admin_operation_logs: 'Admin Operation Logs',
        restricted: 'Restricted',
        area: 'Area',
        reports: 'Reports',
        registered: 'Registered',
        last_7_days: 'Last 7 days',
        last_30_days: 'Last 30 days',
        last_90_days: 'Last 90 days',
        last_365_days: 'Last 1 year',
        last_2_years_days: 'Last 2 Years',
        last_3_years_days: 'Last 3 Years',
        client: 'Client',
        tutorial: 'Tutorial',
        paying: 'Paying',
        other: 'other',
        yesterday: 'Yesterday',
        custom_date_filter: 'Custom date',
        vpn: 'VPN',
        lattitude: 'lattitude',
        longitude: 'longitude',
        max_network_bandwidth: 'Max network bandwidth',
        network_bandwidth: 'Network bandwidth',
        load: 'load',
        mid: 'MID',
        hourly: 'Hourly',
        udid: 'udid',
        token: 'Token',
        first: 'First',
        time: 'Time',
        bugs: 'Bugs',
        user_id: 'User ID',
        node_id: 'Node',
        log_detail: 'Log Detail',
        device_detail: 'Device Detail',
        client_id: 'Client_ID',
        unique_no: 'Unique No',
        clients_error: 'Errors',
        renewals: 'Renewals',
        download: 'download',
        last_active_time: 'Last active time',
        temp: 'Temp',
        offer: 'offer',
        push_notification: 'Push Notification',
        send: 'Send',
        device: 'Device',
        whitelist: 'Whitelist',
        points: 'Points',
        total_points: 'Total Points',
        deep_link: 'Deep Link',
        is_offer: 'Is Offer',
        is_disabled: 'Is Disabled',
        gift_code_builder: 'Gift Code Builder',
        batch: 'Batch',
        count: 'Count',
        purpose: 'Purpose',
        plan: 'Plan',
        generated_by: 'Generated By',
        receive_money: 'Receive Money',
        finance_comment: 'Finance comment',
        gift_codes: 'Gift Codes',
        cancel: 'Cancel',
        generate: 'Generate',
        btach_name: 'Batch Name',
        sr_no: 'SR.NO',
        card_code: 'Card-Code',
        redeem_status: 'Redeem Status',
        used_time: 'Used Time',
        demo: 'Preview',
        batch_name: 'Batch Name',
        serial_number: 'Serial Number',
        download_excel: 'Download Excel',
        use_by: 'Used By',
        ad_source:'Ad Source',
        ad_source_code: 'Ad Source Code',
        ads: 'Ads',
        ad_source_id: 'Ad Source ID',
        ad_code: 'Ad code',
        country_name: 'Country Name',
        client_name:'Client Name',
        ad_display: 'Ad Display',
        home_screen: 'Home Screen',
        splash_screen: 'Splash Screen',
        primary: 'Primary',
        reconciliation_by: 'reconciliation_by',
        firebase_token: 'Firbase token',
        firebase_updated_token: 'Firebase Updated Token',
        earn_points: "Earn Points",
        redeem_points: "Redeem Points",
        earn_from: "Earn From",
        purchase_1_month: "Purchased 1 month package",
        purchase_6_month: "Purchased 6 month package",
        purchase_12_month: "Purchased 12 month package",
        refer_friend:"Refer Friend",
        verify_email: "Verify Email",
        purcahse_1_1_month_package: "Purchased 1+1 month package",
        generate_pay_link: "Generate Pay Link",
        exclude_test_payments: "Exclude Test Payments",
        include_test_payments: "Include Test Payments",
        test_payments: "Test Payments",
        restricted_countries: "Restricted Country",
        total_free_servers: "Total Free Servers",
        total_premium_servers: "Total Premium Servers",
        copy_pay_link: "Copy Pay Link",
        enter_username_select: "Please Enter Username to Select",
        user_watch_ads: "Users Watch Ads",
        by: "by",
        watch_date: "Watch Date",
        expired_soon: "Expired Soon",
        tomorrow: "Tomorrow",
        seven_days: "7 Days",
        one_month: "One Month",
        click_to_add_buttons: "Click to Add Buttons",
        add_buttons: "Add Buttons",
        show_added_buttons: "Show Added Buttons",
        button_title: "Button Title",
        button_url: "Button Url",
        button_order: "Button Order",
        button_location: "Button Location",
        hide_button_list: "Hide List",
        show_close_button: "Show close button",
        select_language: "Select language",
        button_right: "Right",
        button_left: "Left",
        button_center: "Center",
        add_button: "Add Button",
        button_text: "Button text",
        add_new_port: "Add new port",
        last: "Last",
        country_code: "Country code",
        preffered_language: "Preffered language",
        preffered_theme: "Preffered theme",
        guest_user: "Guest user",
        deleted_user: "Deleted user",
        verification_code: "Verification Code",
        refferal_id: "Refferal ID",
        order_history: "Order history",
        packages_history: "Packages history",
        order_not_find:"No Orders found",
        packages_not_find:"No packages found",
        earn_points_not_found:"No Earn points found",
        redeem_points_not_found: "No Redeem Points found",
        devices_not_found: "Devices not found",
        tickets_not_found: "Tickets not found",
        lookup: "Lookup",
        transaction_id: "Transaction ID",
        check: "Check",
        allowed_countries: "Allowed Countries",
        guest: "Guest",
        redeem: "Redeem",
        account: "Account",
        current: "Current",
        change_password_heading: "Please change your password",
        country: 'Country',

        watch_time_reward_in_minutes: 'Watch time reward',
        ad_display_run: 'Ad display run',
        ad_display_main: 'Ad display main',
        display_guest_mode: 'Display guest mode',
        platforms: 'Apply for platform',
        ad_display_disconnect: 'Ad display disconnect',
        pro: 'Pro',
        total_pro_servers: 'Total pro servers',

    },





    




    'cn': {
        home: '首页',
        settings: '设置',
        change_locale: '改变区域',
        dashboard: '控制面板',
        login_username: '登录_用户名',
        login_password: '登录_密码',
        submit: '提交',
        logout: '退出账户',
        manage_admin_users: '编辑管理员',
        add_new_user: '添加新用户',
        username: '用户名',
        password: '密码',
        password_change_info: '密码更改信息。',
        email: '邮箱',
        phone: '电话',
        active: '活跃的',
        in_active: '不活跃的',
        status: '地位',
        display: 'Display',
        dispaly_all: 'For all Users',
        dispaly_only_for_main: 'Only for main App Users',
        dispaly_only_for_pro: 'Only for Pro App Users', 
        dispaly_table_for_main: 'Main',
        dispaly_table_for_pro: 'PRO',   
        dispaly_table_all: 'All', 
        role: '角色',
        permission: '权限',
        date_created: '创建日期',
        action: '行动',
        add: '添加',
        edit: '编辑',
        delete: '删除',
        go_back: '返回',
        select: '选择',
        assign_permissions: '分配权限',
        manage_permissions: '管理权限',
        all_permissions: '所有权限',
        manage_roles: '管理角色',
        name: "名称",
        key: "钥匙",
        manage_currency: '管理货币',
        currency_code: '货币代码',
        currency_symbol: '货币符号',
        confirm_delete: '确定删除',
        manage_package: '管理套餐',
        billed: '计费',
        sorting_order: '序号',
        description: '描述',
        monthly: '每月的',
        yearly: '每年的',
        manually: '手动',
        title: '标题',
        for: "为了",
        all: "全部", 
        android: "Android", 
        ios: "IOS",
        languages: "语言",
        price: '价格',
        discount: '折扣',
        currencies: "货币",
        detail: '明细',
        speed_limit: '限速',
        manage: '管理',
        payment: '支付',
        methods: '方法',
        mode: '模式',
        live: '线上',
        data: '数据',
        icon: '图标',
        code: '代码',
        channels: '渠道',
        groups: '团体',
        group: '团体',
        remark: '备注',
        info: '信息',
        assign: '分配',
        to: '至',
        method: '方法',
        shortcuts: '快捷方式',
        link: '链接',
        view: '浏览',
        list: '列表',
        users: '用户',
        user: '用户',
        search: '搜索',
        servers: '服务器',
        areas: '地区',
        types: '类型',
        port: 'Port',
        ports: 'Ports',
        total: '合计',
        clients: '客户端',
        announcements: '公告',
        created: '已创建',
        expired: '已到期',
        at: '在',
        url: '网址',
        yes: '是',
        no: '否',
        base_currency: '基础货币',
        config: '配置',
        order: '命令',
        orders: '订单',
        number: '数字',
        amount: '金额',
        package: '套餐',
        paid: '已支付',
        un_paid: '未支付',
        started: '已启动',
        completed: '完成的',
        pending: '待定',
        cancelled: '取消的',
        rejected: '被拒绝的',
        refund: '退款',
        default: '默认',
        allowed: '允许',
        devices: '设备',
        additonal: '附加的',
        version: '版本',
        version_old: '老版本',
        sha256: 'sha256',
        pack_size: '包装大小',
        force: '强制',
        type: '类型',
        force_type_zero: '用户选择更新',
        force_type_one: '强制全部更新l',
        currency: '货币',

        apply: '申请',
        filters: '过滤器',
        reset: '重置',
        close: '关',
        plan_time: '计划时间',
        minutes: '分钟',
        packages: '套餐',
        start: '开始',
        end: '结尾',
        date: '日期',
        free: '自由',
        premium: '高级套餐‘',
        both: '双方',
        transfer: '转移',
        id: 'ID',
        url_config: '网址配置',
        value: '价值',
        extra: '额外的',

        online: '在线的',
        today: '今天',
        new: '新的',
        not: '不是',
        confirmed: '确认的',
        trial: '审判',
        reward: '报酬',
        login: '登录',
        level: '等级',
        language: '语',
        invite: '邀请',
        referrer: '推荐人',
        faq: 'FAQ',
        question: '题',
        answer: '回答',

        blog: '博客',
        slug: '蛞蝓',
        viewed: '看过',
        image: '图片',
        meta: '元',
        text: '文本',
        optional: '可选的',
        keywords: '关键词',
        welcome: '欢迎',
        console: '安慰',
        author: '作者',
        category: '类别',
        months: '月',
        recommended: '推荐',
        news: '消息',
        ticket: 'Tickets',
        subject: 'Subject',
        resolved: 'Resolved',
        replied: 'Replied',
        spam: 'Spam',
        reply: 'Reply',
        message: 'Message',
        admin_operation_logs: 'Admin Operation Logs',
        restricted: 'Restricted',
        area: 'Area',
        reports: 'Reports',
        registered: 'Registered',
        last_7_days: 'Last 7 days',
        last_30_days: 'Last 30 days',
        last_90_days: 'Last 90 days',
        last_365_days: 'Last 1 year',
        last_2_years_days: 'Last 2 Years',
        last_3_years_days: 'Last 3 Years',
        client: 'Client',
        tutorial: 'Tutorial',
        paying: 'Paying',
        other: 'other',
        yesterday: 'Yesterday',
        custom_date_filter: 'Custom date',
        vpn: 'VPN',
        lattitude: 'lattitude',
        longitude: 'longitude',
        max_network_bandwidth: 'Max network bandwidth',
        network_bandwidth: 'Network bandwidth',
        load: 'load',
        mid: 'MID',
        hourly: 'Hourly',
        udid: 'udid',
        token: 'Token',
        first: 'First',
        time: 'Time',
        bugs: 'Bugs',
        refresh: 'Refresh',
        user_id: 'User ID',
        node_id: 'Node',
        log_detail: 'Log Detail',
        device_detail: 'Device Detail',
        client_id: 'Client_ID',
        unique_no: 'Unique No',
        clients_error: 'Errors',
        renewals: 'Renewals',
        download: 'download',
        last_active_time: 'Last active time',
        temp: 'Temp',
        offer: 'offer',
        push_notification: 'Push Notification',
        send: 'Send',
        device: 'Device',
        whitelist: 'Whitelist',
        points: 'Points',
        total_points: 'Total Points',
        deep_link: 'Deep Link',
        is_offer: 'Is Offer',
        is_disabled: 'Is Disabled',
        gift_code_builder: 'Gift Code Builder',
        batch: 'Batch',
        count: 'Count',
        purpose: 'Purpose',
        plan: 'Plan',
        generated_by: 'Generated By',
        receive_money: 'Receive Money',
        finance_comment: 'Finance comment',
        gift_codes: 'Gift Codes',
        cancel: 'Cancel',
        generate: 'Generate',
        btach_name: 'Batch Name',
        sr_no: 'SR.NO',
        card_code: 'Card-Code',
        redeem_status: 'Redeem Status',
        used_time: 'Used Time',
        demo: 'Preview',
        batch_name: 'Batch Name',
        serial_number: 'Serial Number',
        download_excel: 'Download Excel',
        use_by: 'Used By',
        ad_source:'Ad Source',
        ad_source_code: 'Ad Source Code',
        ads: 'Ads',
        ad_source_id: 'Ad Source ID',
        ad_code: 'Ad code',
        country_name: 'Country Name',
        client_name:'Client Name',
        ad_display: 'Ad Display',
        home_screen: 'Home Screen',
        splash_screen: 'Splash Screen',
        primary: 'Primary',
        reconciliation_by: 'reconciliation_by',
        firebase_token: 'Firbase token',
        firebase_updated_token: 'Firebase Updated Token',
        earn_points: "Earn Points",
        redeem_points: "Redeem Points",
        earn_from: "Earn From",
        purchase_1_month: "Purchased 1 month package",
        purchase_6_month: "Purchased 6 month package",
        purchase_12_month: "Purchased 12 month package",
        refer_friend:"Refer Friend",
        verify_email: "Verify Email",
        purcahse_1_1_month_package: "Purchased 1+1 month package",
        generate_pay_link: "Generate Pay Link",
        exclude_test_payments: "Exclude Test Payments",
        include_test_payments: "Include Test Payments",
        test_payments: "Test Payments",
        restricted_countries: "Restricted Country",
        total_free_servers: "Total Free Servers",
        total_premium_servers: "Total Premium Servers",
        copy_pay_link: "Copy Pay Link",
        enter_username_select: "Please Enter Username to Select",
        user_watch_ads: "Users Watch Ads",
        by: "by",
        watch_date: "Watch Date",
        expired_soon: "Expired Soon",
        tomorrow: "Tomorrow",
        seven_days: "7 Days",
        one_month: "One Month",
        click_to_add_buttons: "Click to Add Buttons",
        add_buttons: "Add Buttons",
        show_added_buttons: "Show Added Buttons",
        button_title: "Button Title",
        button_url: "Button Url",
        button_order: "Button Order",
        button_location: "Button Location",
        hide_button_list: "Hide List",
        show_close_button: "Show close button",
        select_language: "Select language",
        button_right: "Right",
        button_left: "Left",
        button_center: "Center",
        add_button: "Add Button",
        button_text: "Button text",
        add_new_port: "Add new port",
        last: "Last",
        country_code: "Country code",
        preffered_language: "Preffered language",
        preffered_theme: "Preffered theme",
        guest_user: "Guest user",
        deleted_user: "Deleted user",
        verification_code: "Verification Code",
        refferal_id: "Refferal ID",
        order_history: "Order history",
        packages_history: "Packages history",
        order_not_find:"No Orders found",
        packages_not_find:"No packages found",
        earn_points_not_found:"No Earn points found",
        redeem_points_not_found: "No Redeem Points found",
        devices_not_found: "Devices not found",
        tickets_not_found: "Tickets not found",
        lookup: "Lookup",
        transaction_id: "Transaction ID",
        check: "Check",
        allowed_countries: "Allowed Countries",
        guest: "Guest",
        redeem: "Redeem",
        account: "Account",
        current: "Current",
        change_password_heading: "Please change your password",
        country: 'Country',
        watch_time_reward_in_minutes: 'Watch time reward',
        ad_display_run: 'Ad display run',
        ad_display_main: 'Ad display main',
        display_guest_mode: 'Display guest mode', 
        platforms: 'Apply for platform',
        ad_display_disconnect: 'Ad display disconnect',
        pro: 'Pro',
        total_pro_servers: 'Total pro servers',


    }
}
