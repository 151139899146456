<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
         <h2>{{locale.manage}} {{locale.clients}} {{locale.config}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.clients+' '+locale.config" :item_one_url="'/clients/clients-config'" :item_two_title="locale.add" :item_two_active="true" />

        <div class="row">

            <div class="col-6">
                <div class="mb-3">
                    <label class="form-label">{{locale.name}}</label>
                    <input v-model="data.name" type="text" class="form-control" placeholder="Config name" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.version}}</label>
                    <input v-model="data.version" type="text" class="form-control" placeholder="1.1.1" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.version_old}}</label>
                    <input v-model="data.version_old" type="text" class="form-control" placeholder="1.1.0" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.info}}</label>
                    <input v-model="data.info" type="text" class="form-control" placeholder="Config info" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.sha256}}</label>
                    <input v-model="data.sha256" type="text" class="form-control" placeholder="" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.pack_size}}</label>
                    <input v-model="data.pack_size" type="text" class="form-control" placeholder="" required>
                </div>
            </div>
            <div class="col-4">
                <div class="mb-3">
                    <label class="form-label">{{locale.force}} {{locale.type}}</label>
                    <select class="form-select" v-model="data.type">
                        <option value="0">{{locale.force_type_zero}}</option>
                        <option value="1">{{locale.force_type_one}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.clients}}</label>
                    <select class="form-select" v-model="data.client_id">
                        <option value="0" selected>All</option>
                        <option  v-for="(item,i) in data.clientsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.channels}}</label>
                    <select class="form-select" v-model="data.channel_id">
                        <option value="0" selected>All</option>
                        <option  v-for="(item,i) in data.channelsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.manage}} {{locale.package}}</label>
                    <select class="form-select" v-model="data.plan_id">
                        <option value="0" selected>All</option>
                        <option  v-for="(item,i) in data.plansList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                    </select>
                </div>


            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/clients/clients-config')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import {callApi, callPostApi, getClientsList, getChannelsList, getSystemPlansList} from "@/api/api"

export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/clients-config/',
            client_id : 0,
            name : '',
            plan_id: 0,
            version: '',
            version_old: '',
            info: '',
            type: 0,
            channel_id: 0,
            sha256: '',
            pack_size: 0,
            channelsList: [],
            clientsList: [],
            plansList: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            
            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)

            let record = resData.data

            data.recordId = record.id
            data.client_id = record.client_type_id 
            data.name = record.name
            data.plan_id = record.pack_name 
            data.version = record.version
            data.version_old = record.version_old
            data.info = record.info
            data.type = record.type
            data.channel_id = record.channel_id
            data.sha256 = record.sha256
            data.pack_size = record.pack_size

            data.clientsList    = await getClientsList()
            data.channelsList   = await getChannelsList()
            data.plansList      = await getSystemPlansList()
        })

        const submit = async() => {

            data.submitBtn = true
            let _body = {
                client_id: data.client_id,
                name : data.name,
                plan_id : data.plan_id,
                version: data.version,
                version_old: data.version_old,
                info: data.info,
                type: data.type,
                channel_id: data.channel_id,
                sha256: data.sha256,
                pack_size: data.pack_size,
                
            }

            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/clients/clients-config')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>