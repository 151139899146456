<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.bugs}}</h2>
       <Breadcrumb :item_one_title="locale.bugs" :item_one_active="true" :loading="data.loading" />
       

      <div class="table-responsive mt-3">
        <table class="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{locale.user}}</th>
              <th scope="col">{{locale.email}}</th>
              <th scope="col">{{locale.message}}</th>
              <th scope="col">{{locale.ip}}</th>
               <th scope="col">{{locale.image}}</th>
               <th scope="col">{{locale.date_created}}</th>
              <th scope="col">{{locale.action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{item.id}}</td>
                <td>{{item.user_id}}</td>
                <td>{{item.email}}</td>
                <td>{{item.message}}</td>
                <td>{{item.user_ip}}</td>
                <td>
                  <!-- <a v-bind:href="`${item.image_url}`" v-show="checkImage(item.image_url)" download="">
                  <img v-bind:src="`${item.image_url}`" width="180" height="120">
                  </a> -->
                   <img v-bind:src="`${item.image_url}`" width="180" v-show="checkImage(item.image_url)" @click="show(item.image_url)" height="120">

                  <!-- <video width="180" height="120" v-show="checkVideo(item.image_url)" controls> 
                     <source v-bind:src="`${item.image_url}`" type="video/mp4">
                     
                      </video> -->
                      <a v-bind:href="`${item.image_url}`" v-show="checkVideo(item.image_url)"> Click to Download Video</a>
                      <a v-bind:href="`${item.image_url}`" v-show="checkText(item.image_url)"> Click to Download File</a>
                
                </td>
                 <td>{{item.created_at}}</td>
                
                <td>
                   
                   <button @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm my-1 mx-1">{{locale.delete}}</button>
                </td>
            </tr>
          </tbody>
        </table>
         <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="20">20</option>
                        <option value="40">40</option>
                        <option value="60">60</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
      </div>
      <div class="modal fade" :class="showImage?'show':''" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" :style="showImage?'display:block':''">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="showImage=false"></button>
      </div>
      <div class="modal-body">
        <div class="justify-content-center align-items-center">
         <img class="img-fluid" v-bind:src="`${image}`" width="770" height="700">
        </div>
      </div>
      
    </div>
  </div>
</div>
    </main>
</template>


<script>

import {onMounted, reactive, computed, ref} from "vue"
import { useStore } from '@/store';
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'


export default{
name:'ManageBugReported',
    components: {
      Breadcrumb,
      VPagination
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)
        let showImage=ref(false)
        let image= ref("")

        const data = reactive({
            frontEndpoint: '/bug-reported',
            endpoint: '/bug-reported',
            page : 1,
            perPage : 20,
            totalPages : 0,
            records: [],
            loading: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_bugs', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()
        })

        const getRecords = async() => {
            data.loading = true
            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage, 'GET')
            data.records = resData.data
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.loading = false
            
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
            }else{
                notify({text: resData.message, type:'error'})
            }
          }
        }
       
        const checkImage=(url)=>{
       return (url.match(/\.(jpg|jpeg|gif|png)$/) != null);

        }
        const checkVideo = (url) =>{
          return (url.match(/\.(mp4|video|WEBM|WMV|FLV|MKV)$/) != null);
        }
            const checkText = (url) =>{
          return (url.match(/\.(txt|pdf|docx|webarchive|rar|zip)$/) != null);
        }
        const show = (url)=>{
        image.value=url;
        showImage.value=true;

        }
        

        return {
            data,
            locale,
            deleteRecord,
            checkVideo,
            checkImage,
            checkText,
            getRecords,
            show,
            showImage,
            image,
            
            
            
        }
    }
}

</script>
<style scoped>
.img-fluid{
  height: auto;
  max-height: 800px;
}
</style>