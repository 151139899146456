<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.ticket}}</h2>
       <Breadcrumb :item_one_title="locale.manage +' '+ locale.ticket" :item_one_active="true" :loading="data.loading"/>
      
       <button @click="showFilters" class="btn btn-outline-secondary mb-3 ms-3 btn-sm" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>
       <button @click="getRecords()" type="button" class="btn btn-success mb-3 ms-3 btn-sm">{{locale.refresh}}</button>

        <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-3">
                    <label class="form-label">{{locale.search}}</label>
                    <input v-model="data.filters.keywords" type="text" class="form-control" placeholder="Ticket number, email, subject, description, ip"  aria-describedby="button-addon2">
                </div>
                
                <div class="col-2">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.filters.status">
                        <option value="0">{{locale.pending}}</option>
                        <option value="1">{{locale.resolved}}</option>
                        <option value="2">{{locale.replied}}</option>
                        <option value="3">{{locale.spam}}</option>
                    </select>
                </div>
            </div>
            
            <button @click="searchRecords" class="btn btn-secondary my-3 " type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-3 mx-2" type="button" id="button-addon2">{{locale.reset}}</button>
            <hr>

        </div>

        <!--Test comment 3-->

      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{locale.email}}</th>
              <th scope="col">{{locale.subject}}</th>
              <th scope="col">{{locale.description}}</th>
              <th scope="col">{{locale.status}}</th>
              <th scope="col">{{locale.ip}}</th>
              <th scope="col">{{locale.date_created}}</th>
              <th scope="col">{{locale.date_update}}</th>
              <th scope="col">{{locale.action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{item.id}}</td>
                <td>{{item.email}}
                    <br>
                    <span title="Exising Customer of SwoshsVPN" v-if="item.existing_user_id" class="badge bg-success rounded-pill"> User ID: {{item.existing_user_id}} </span>
                </td>
                <td>{{item.subject}}</td>
                <td>{{item.description}}</td>
                <td>
                    <span v-if="item.status==0" class="badge bg-warning text-dark rounded-pill">{{locale.pending}}</span>
                    <span v-else-if="item.status==1" class="badge bg-success rounded-pill">{{locale.resolved}}</span>
                    <span v-else-if="item.status==2" class="badge bg-secondary rounded-pill">{{locale.replied}}</span>
                    <span v-else-if="item.status==3" class="badge bg-danger rounded-pill">{{locale.spam}}</span>
                    <span v-else class="badge bg-dark">{{item.status}}</span>
                </td>
                <td>{{item.ip}}</td>
                <td>{{ $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</td>
                <td>{{ $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</td>
                <td>
                  <button @click="showModals(item)"  type="button" class="btn btn-dark btn-sm my-1 mx-1">{{locale.reply}}</button>
                  <button @click="showModalsReply(item)"  type="button" class="btn btn-info btn-sm my-1 mx-1">{{locale.view}} {{locale.reply}}</button>
                  <button @click="$redirect('/ticket/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm">{{locale.edit}}</button>
                  <button @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm ms-1">{{locale.delete}}</button>
                </td>
            </tr>
          </tbody>
        </table>

          <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                <b>{{locale.total}}: {{data.totalRecords}}</b>
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
    
      </div>


       <!-- Reply Modal -->
        <div v-if="data.showModal" style="display: block;" class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                <div class="modal-header bg-primary text-white">
                    <h5 class="modal-title" id="exampleModalLabel">{{locale.reply}}</h5>
                    <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label for="message-text" class="form-label">{{locale.email}}</label>
                            <input v-model="data.email" type="text" class="form-control" placeholder="email@user.com" required>
                        </div>
                        <div class="mb-3">
                            <label for="message-text" class="form-label">{{locale.message}}</label>
                            <textarea class="form-control" v-model="data.reply_message"></textarea>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="submitReply" type="button" class="btn btn-secondary">{{locale.submit}} <span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                    <button @click="closeModal" type="button" class="btn btn-danger" data-bs-dismiss="modal">{{locale.close}}</button>
                </div>
                </div>
            </div>
        </div>


         <div v-if="data.showModalReply" style="display: block;" class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog  modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                <div class="modal-header bg-primary text-white">
                    <h5 class="modal-title" id="exampleModalLabel">{{locale.all}} {{locale.reply}}</h5>
                    <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    
                      <table class="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              
                              <th scope="col">{{locale.reply}} {{locale.username}}</th>
                              <th scope="col">{{locale.message}}</th>
                              <th scope="col">{{locale.reply}} {{locale.date}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item,i) in data.replies" v-bind:key="i">
                                <td>{{item.id}}</td>
                                <td>{{item.username}}</td>
                                <td>{{item.reply_message}}</td>
                                <td>{{ $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</td>
                            </tr>
                          </tbody>
                      </table>

                </div>
                <div class="modal-footer">
                    <button @click="closeModal" type="button" class="btn btn-danger" data-bs-dismiss="modal">{{locale.close}}</button>
                </div>
                </div>
            </div>
        </div>



    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store';
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi,callPostApi} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'ManageTickets',
    components: {
      Breadcrumb,
      VPagination,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/ticket',
            page : 1,
            perPage : 30,
            totalPages : 0,
            totalRecords : 0,
            keywords : '',
            records: [],
            loading: false,

            recordId: '',
            email: '',
            reply_message: '',
            submitBtn: false,
            showModal : false,

            showModalReply : false,
            replies : [],
            pageReplies : 1,
            perPageReplies : 10,
            keywordsReplies : '',
            totalPagesReplies : 0,

            filters:{
                keywords : '',
                status: '',
            },
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)

            let isAllowAccessPage = await checkAccessSinglePage('manage_ticket', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()
        })

        const getRecords = async() => {
            data.loading = true
            let query_filter = new URLSearchParams(data.filters).toString()

            //let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&keywords="+data.keywords, 'GET')
            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')

            console.log("resData",resData)
            data.records = resData.data
            data.totalRecords = resData.totalRecords
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.loading = false
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
            }else{
                notify({text: resData.message, type:'error'})
            }
          }
        }

        const showModals = async(item) => {
            data.recordId = item.id
            data.email = item.email
            data.showModal = true
        }
        const closeModal = () => {
            data.showModal = false
            data.showModalReply = false
        }

        const submitReply = async() => {
            
            if(data.reply_message == ''){
              notify({text: 'Reply message required', type:'error'})
              return false
            }
            if(data.email == ''){
              notify({text: 'Reply email required', type:'error'})
              return false
            }
            

            data.submitBtn = true
            let _body = {
                ticket_id: data.recordId,
                email: data.email,
                reply_message: data.reply_message,
                status: 2
            }
            let resData = await callPostApi(data.endpoint,'POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                data.reply_message = ''
                data.recordId = ''
                data.showModal = false

                notify({text: resData.message, type:'success'})
                //router.push('/ticket')
                getRecords()
                
            }else{
                notify({text: resData.message, type:'error'})
            }
        }


        const showModalsReply = async(item) => {
            data.recordId = item.id
            data.showModalReply = true

            getReplies()
        }
    

        const getReplies = async() => {
            data.loading = true
            let resData = await callApi(data.endpoint+'/replies?id='+data.recordId+'&page='+data.pageReplies+'&perPage='+data.perPageReplies+"&keywords="+data.keywordsReplies, 'GET')
            console.log("getReplies",resData)
            data.replies = resData.data
            data.totalPagesReplies = Math.ceil(resData.totalRecords/data.perPage)
            data.loading = false
        }


        const searchRecords = async() => {
            data.page = 1
            getRecords()
        }
        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }
        const resetFilters = async() => {
            data.filters = {
                keywords : '',
                status: '',
            }
            searchRecords()
        }


        return {
            data,
            locale,
            deleteRecord,
            getRecords,
            showModals,
            closeModal,
            submitReply,

            showModalsReply,

            searchRecords,
            showFilters,
            resetFilters,
        }
    }
}

</script>