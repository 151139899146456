
import { apiHost } from '@/config/config'
import { useStore } from '@/store'
const store = useStore()


export async function callApi(endpoint: string, method: string) {
    return await fetch(apiHost + endpoint, {
        method: method,
        headers: {
            'Authorization': (store.state.authUser as any).accessToken,
            'Content-type': 'application/json; charset=UTF-8',
        },
    }).then(async response => {
        const resData = await response.json()
        resData.isSuccess = true
        resData.status = response.status
        if (!response.ok) {
            //401 == Unauthorized
            if (response.status == 401) {
                resData.notLogin = true
                await store.dispatch("setAuth", false)
                await store.dispatch("setAuthUser", null)
            }
            resData.isSuccess = false
            let error = (resData && resData.message) || response.status
            if (resData.errors) {
                error = ''
                resData.errors.forEach(element => { error += element.msg + "<br>" })
            }
            resData.message = error
        }
        return resData
    }).catch(error => {
        console.log("callApi in api.ts  err")
        console.log(error)
        const resData = {
            status: 500,
            isSuccess: false,
            message: error
        }
        return resData
    })

}

export async function callPostApi(endpoint: string, method: string, _body: any) {

    // console.log("callPostApi",endpoint)
    // let ipDetail = 'start'
    // await fetch('https://api.ipify.org?format=json')
    // .then(x => x.json())
    // .then(({ ip }) => {
    //     ipDetail = ip
    //     console.log("client ip detail in veu.js",ip)
    // }).catch(error => {
    //     console.log("client ip err",error)
    // })
    // console.log("callPostApi 2")

    return await fetch(apiHost + endpoint, {
        method: method,
        headers: {
            'Authorization': (store.state.authUser as any).accessToken,
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: _body
    }).then(async response => {
        const resData = await response.json()
        resData.isSuccess = true
        resData.status = response.status
       // resData.ip = ipDetail
        if (!response.ok) {
            resData.isSuccess = false
            let error = (resData && resData.message) || response.status
            if (resData.errors) {
                error = ''
                resData.errors.forEach(element => { error += element.msg + "<br>" })
            }
            resData.message = error
        }
        return resData
    }).catch(error => {
        console.log("callApi in api.ts  err")
        console.log(error)
        const resData = {
            status: 500,
            isSuccess: false,
            message: error
        }
        return resData
    })
}

export async function uploadFile(_body: any) {
    return await fetch(apiHost + '/upload', {
        method: 'PUT',
        headers: {
            'Authorization': (store.state.authUser as any).accessToken,
        },
        body: _body
    }).then(async response => {
        const resData = await response.json()
        resData.isSuccess = true
        resData.status = response.status
        if (!response.ok) {
            resData.isSuccess = false
            let error = (resData && resData.message) || response.status
            if (resData.errors) {
                error = ''
                resData.errors.forEach(element => { error += element.msg + "<br>" })
            }
            resData.message = error
        }
        return resData
    }).catch(error => {
        console.log("callApi in api.ts  err")
        console.log(error)
        const resData = {
            status: 500,
            isSuccess: false,
            message: error
        }
        return resData
    })
}

export async function getLanguageList() {
    const resData = await callApi("/language", 'GET')
    return resData.data
}

export async function getPaymentMethodsList() {
    const resData = await callApi('/paymentmethods', 'GET')
    return resData.data
}

export async function getCurrencyList() {
    const resData = await callApi("/currency", 'GET')
    return resData.data
}

export async function getSystemPlansList() {
    const resData = await callApi("/systemplan", 'GET')
    return resData.data
}

export async function getBatchList() {
    const resData = await callApi("/batch", 'GET')
    return resData.data
}

export async function getGroupsList() {
    const resData = await callApi("/groups", 'GET')
    return resData.data
}

export async function getChannelsList() {
    const resData = await callApi("/channels", 'GET')
    return resData.data
}



export async function getServersAreasList() {
    const resData = await callApi("/servers-areas", 'GET')
    return resData.data
}

export async function getServersTypesList() {
    const resData = await callApi("/servers-types", 'GET')
    return resData.data
}

export async function getClientsList() {
    const resData = await callApi("/clients", 'GET')
    return resData.data
}

export async function getRoles() {
    const resData = await callApi("/roles", 'GET')
    return resData.data
}

export async function getCategories(lang_code = 'EN', perPage = 500) {
    const resData = await callApi("/category?lang=" + lang_code + "&perPage=" + perPage, 'GET')
    return resData.data
}
export async function getCountriesList() {
    const resData = await callApi("/countries", 'GET')
    return resData.data
}
export async function getAdsourceList() {
    const resData = await callApi("/ad-source", 'GET')
    return resData.data
}
export async function getAdsourceCodeList() {
    const resData = await callApi("/ad-source-code", 'GET')
    return resData.data
}
export async function getAdminUserList() {
    const resData = await callApi("/admin-users", 'GET')
    return resData.data
}
export async function getUserList() {
    const resData = await callApi("/user-list", 'GET')
    return resData.data
}

export async function getPointTypeSettings(lang_code = 'EN', perPage = 500) {
    const resData = await callApi("/points?lang=" + lang_code + "&perPage=" + perPage,'GET')
    return resData.data
}