import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from "@/store"
import Notifications from '@kyvg/vue3-notification'
import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueExcelXlsx from "vue-excel-xlsx"


const icon_info = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16"><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/></svg>'
const icon_diamond = '<svg  width="16" height="16"  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M1413,4155.8c-74.6-59.3-1313-2538-1313-2628c0-59.3,202.9-294.8,2298.8-2666.3C3664-2568.3,4743.5-3787.6,4797.1-3846.9c153.1-168.4,250.7-172.3,396.2-9.6c49.8,55.5,1129.3,1274.8,2398.3,2710.3C9685.6,1221.5,9900,1470.3,9900,1527.8c0,90-1238.4,2568.7-1313,2628l-51.7,40.2H5000H1464.7L1413,4155.8z M4578.9,3780.6c0-28.7-1404.9-1881.5-1416.4-1868.1c-24.9,26.8-1244.1,1862.4-1244.1,1871.9c0,5.7,599.1,9.6,1330.3,9.6S4578.9,3788.3,4578.9,3780.6z M8081.6,3784.4c0-9.6-1219.3-1845.2-1244.1-1871.9c-11.5-13.4-1416.4,1839.4-1416.4,1868.1c0,7.7,599.1,13.4,1330.3,13.4S8081.6,3790.2,8081.6,3784.4z M5713.9,2697.2l704.4-941.7l-708.2-5.7c-390.5-1.9-1029.8-1.9-1420.2,0l-708.2,5.7l704.4,941.7C4672.7,3216,4994.3,3639,5000,3639S5327.3,3216,5713.9,2697.2z M2172.9,2655.1l606.8-909.2H1707.8H635.9l453.6,909.2c250.7,499.6,461.3,909.2,467,909.2C1562.3,3564.3,1839.9,3154.7,2172.9,2655.1z M8910.4,2655.1l453.6-909.2H8292.2H7220.3l606.8,909.2c333,499.6,610.6,909.2,616.3,909.2C8449.1,3564.3,8659.7,3154.7,8910.4,2655.1z M3738.6-751.9c398.1-1146.5,721.6-2086.3,719.7-2088.2c-5.7-5.8-3678.8,4147.8-3684.6,4166.9c-3.8,9.6,453.6,15.3,1117.8,13.4l1125.5-5.7L3738.6-751.9z M6544.6,1321c-3.8-13.4-352.2-1018.3-771.4-2229.9c-419.2-1213.5-767.6-2206.9-773.3-2206.9s-354.1,993.4-773.3,2206.9C3807.5,302.8,3459.2,1307.6,3453.4,1321c-5.7,17.2,312,23,1546.6,23C6236.5,1344,6552.3,1338.3,6544.6,1321z M9226.2,1326.8c-7.6-19.1-3678.8-4172.7-3684.6-4166.9c-3.8,1.9,1383.9,4015.7,1427.9,4132.5l21,51.7h1121.6C8743.9,1344,9230.1,1336.3,9226.2,1326.8z"/></g></svg>'
const icon_trophy = '<svg width="16" height="16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><path d="M747.1,552.8C841.6,533.1,990,425.1,990,334.9c0-86-95.5-105-173.6-109.2c2-30.4,3.5-61.1,3.5-92.7c17,0,30.8-20.6,30.8-46.2c0-25.5-13.7-46.1-30.8-46.1H180.1c-17,0-30.8,20.6-30.8,46.1c0,25.5,13.7,46.2,30.8,46.2c0,31.5,1.4,62.3,3.5,92.7C105.5,229.9,10,248.9,10,334.9c0,90.2,148.3,198.2,242.9,217.9c49.5,124.4,120.7,210.4,201.5,234.3c-85.6,27.7-262.1,94.3-262.1,172.2h615.4c0-77.9-176.6-144.5-262.1-172.2C626.4,763.2,697.6,677.1,747.1,552.8z M928.5,334.9c0,45.1-87.5,116.4-156.8,145.9c17.7-59.1,31-124.1,39.1-193.7C865.3,289.8,928.5,299.4,928.5,334.9z M71.5,334.9c0-35.5,63.3-45.1,117.7-47.8c8.1,69.5,21.4,134.6,39.1,193.6C159,451.2,71.5,379.9,71.5,334.9z M391.6,540.2c-7,8.1-17,12-27,11.5c-7.1-0.4-14.1-3.1-20-8.1c-86-74.5-88.4-298.7-88.5-324c0-18.4,14.9-33.3,33.3-33.3c19.7-0.6,33.3,14.9,33.3,33.3c0.1,84,17.8,232.4,65.5,273.7C402.1,505.3,403.7,526.3,391.6,540.2z"/></svg>'


const app = createApp(App)

app.config.globalProperties.$moment = moment
app.config.globalProperties.$redirect = (page) => {router.push(page)}
app.config.globalProperties.$formatDate = (date) => { return date.replace('Z', '').replace('T', ' ').split('.')[0]}

app.config.globalProperties.icon_info = icon_info
app.config.globalProperties.icon_diamond = icon_diamond
app.config.globalProperties.icon_trophy = icon_trophy

app.use(Notifications).use(store).use(router).use( CKEditor ).use(VueExcelXlsx).mount('#app')
