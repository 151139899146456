<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.announcements}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.announcements" :item_one_url="'/clients/announcements'" :item_two_title="locale.add" :item_two_active="true" />

        <div class="row">
         
            <div class="col-6">
                <h3>{{locale.add}} {{locale.title}} & {{locale.description}} & {{locale.button_text}} {{locale.for}} {{locale.all}} {{locale.languages}}</h3>
                <ol class="list-group list-group-numbered">
                    <li class="list-group-item" v-for="(item,i) in data.languagesList" v-bind:key="i">
                        <div class="mb-3">
                            <label class="form-label">{{locale.title}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                            <input v-model="data.translationArr[item.lang_code]" type="text" class="form-control" required>
                            
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{locale.description}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                            <textarea v-model="data.translationDescriptionArr[item.lang_code]" class="form-control" placeholder=""></textarea>
                        </div>
                          <div class="mb-3">
                    <label class="form-label">{{locale.button_text}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                    <input  v-model="data.button_text[item.lang_code]" type="text" class="form-control" placeholder="" required>
                </div>
                        <button class="btn btn-success btn-sm ms-2" @click="showForm=true">{{locale.add_buttons}}</button>
                        <button class="btn btn-warning btn-sm ms-2" v-show="(data.buttonsFormLang[item.lang_code] || []).length!=0 && !showButtonList" @click="showButtonList=true">{{locale.show_added_buttons}}</button>
                        <div class="mt-3">
                     <div  v-for="(input, k) in data.buttonsFormLang[item.lang_code]"
                  :key="k" v-show="showButtonList">
                  <div>
                    <p>{{data.list_number + k}}.</p>
                    <div class="row mt-2">
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for=""  class="form-label">{{locale.button_title}} <span class="badge bg-light text-dark">{{item.name}}</span></label>

                      <input
                       
                        type="text"
                       v-model="input.title"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for="" class="form-label">{{locale.button_url}} <span class="badge bg-light text-dark">{{item.name}}</span></label>

                      <input
                        required
                        type="text"
                        v-model="input.url"
                       
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for="" class="form-label">{{locale.button_order}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                      <select class="form-select" v-model="input.button_order">
                        <option value="0">1</option>
                        <option value="1">2</option>
                        <option value="2">3</option>
                        
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for="" class="form-label">{{locale.button_location}}<span class="badge bg-light text-dark">{{item.name}}</span></label>
                      <select class="form-select" v-model="input.location">
                        <option value="right">{{locale.button_right}}</option>
                        <option value="center">{{locale.button_center}}</option>
                        <option value="left">{{locale.button_left}}</option>
                        
                        
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2" style="margin-top:30px;">
                    <span>
                     <button class="btn btn-danger " @click="removeButton(k,input.lang_code)" v-show="k || (!k && data.buttonsFormLang[item.lang_code].length )">-</button>
                      <button class="btn btn-success ms-2" v-show="k == data.buttonsFormLang[input.lang_code].length - 1" @click="showForm=true">+</button>
                    </span>
                  </div>
                </div>
                  </div>
                  <button class="btn btn-warning btn-sm ms-2 mt-1 mb-3" v-show="showButtonList && k == data.buttonsFormLang[input.lang_code].length -1" @click="showButtonList=false">{{locale.hide_button_list}}</button>
                  </div>
                   
                  </div>
                   <!-- <button class="btn btn-warning btn-sm ms-2 mt-1" v-show="showButtonList && data.buttonsArray.length!=0" @click="showButtonList=false">{{locale.hide_button_list}}</button> -->
                </li>
              
                    
                </ol>
              
                <!-- <h3 class="mt-3">{{locale.click_to_add_buttons}}</h3> -->
<!--                  
                       <div class="form-group mt-3 mb-2">
                  <div class="mb-3 mt-2">
                   <button class="btn btn-success btn-sm ms-2" @click="showForm=true">{{locale.add_buttons}}</button>
                   <button class="btn btn-warning btn-sm ms-2" v-show="data.buttonsArray.length > 0 && !showButtonList" @click="showButtonList=true">{{locale.show_added_buttons}}</button>
                   </div>
                </div> -->
                      <!-- <ol class="list-group list-group-numbered mt-2" v-for="(lang,index) in data.languagesList" :key="index"  v-show="showButtonList"  >
                          
                          <li class="list-group-item" v-for="(input, k) in data.buttonsFormLang[lang.lang_code]"
                  :key="k">
                  <div>
                    <div class="row mt-2">
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for=""  class="form-label">{{locale.button_title}} <span class="badge bg-light text-dark">{{lang.name}}</span></label>

                      <input
                       
                        type="text"
                       v-model="input.title"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for="" class="form-label">{{locale.button_url}} <span class="badge bg-light text-dark">{{lang.name}}</span></label>

                      <input
                        required
                        type="text"
                        v-model="input.url"
                       
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for="" class="form-label">{{locale.button_order}} <span class="badge bg-light text-dark">{{lang.name}}</span></label>
                      <select class="form-select" v-model="input.button_order">
                        <option value="0">1</option>
                        <option value="1">2</option>
                        <option value="2">3</option>
                        
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4 mb-3">
                    <div class="form-group">
                      <label for="" class="form-label">{{locale.button_location}}<span class="badge bg-light text-dark">{{lang.name}}</span></label>
                      <select class="form-select" v-model="input.location">
                        <option value="right">{{locale.button_right}}</option>
                        <option value="center">{{locale.button_center}}</option>
                        <option value="left">{{locale.button_left}}</option>
                        
                        
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-2" style="margin-top:30px;">
                    <span>
                     <button class="btn btn-danger " @click="removeButton(k,input.lang_code)" v-show="k || (!k && data.buttonsFormLang[lang.lang_code].length )">-</button>
                      <button class="btn btn-success ms-2" v-show="k == data.buttonsFormLang[input.lang_code].length - 1" @click="showForm=true">+</button>
                    </span>
                  </div>
                </div>
                  </div>
                </li>
                
                </ol>
            <button class="btn btn-warning btn-sm ms-2 mt-1" v-show="showButtonList && data.buttonsArray.length!=0" @click="showButtonList=false">{{locale.hide_button_list}}</button> -->
                

                 <!-- <button @click="showForm=true">show btn</button> -->
                
            </div>
 
            <div class="col-4">
              
                <div class="mb-3">
                    <label class="form-label">{{locale.name}}</label>
                    <input v-model="data.name" type="text" class="form-control" placeholder="Announcements name" required>
                </div>
                
                <div class="mb-3">
                    <label class="form-label">{{locale.url}}</label>
                    <input  v-model="data.url" type="text" class="form-control" placeholder="www.example.com">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.deep_link}}</label>
                    <input  v-model="data.deep_link" type="text" class="form-control" placeholder="Purchase_screen">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="2">{{locale.in_active}}</option>
                    </select>
                </div>
                
                <div class="mb-3">
                    <label class="form-label">{{locale.primary}}</label>
                    <select class="form-select" v-model="data.primary_notice">
                        <option value="0">{{locale.no}}</option>
                        <option value="1">{{locale.yes}}</option>
                    </select>
                </div>
                     <div class="mb-3">
                    <label class="form-label">{{locale.show_close_button}}</label>
                    <select class="form-select" v-model="data.show_close">
                        <option value="0">{{locale.no}}</option>
                        <option value="1">{{locale.yes}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.expired}} {{locale.at}}</label>
                    <input v-model="data.expired_at" type="date" class="form-control" placeholder="month/date/year">
                </div>
                <label class="form-label">{{locale.assign}} {{locale.clients}} {{locale.to}} {{locale.announcements}}</label>
                <div class="form-check" v-for="(item,i) in data.clientsList" v-bind:key="i">
                    <input v-model="data.clientsCheckedArr" class="form-check-input" type="checkbox" :value="item.id" :id="item.id">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{item.name}}
                    </label>
                </div>
            </div>

            <div class="col-12">
                <h3 class="mt-3">{{locale.assign}} {{locale.channels}} {{locale.to}} {{locale.announcements}}</h3>
                <div class="form-check" v-for="(item,i) in data.channelsList" v-bind:key="i">
                    <input v-model="data.checkedArr" class="form-check-input" type="checkbox" :value="item.id" :id="item.id">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{item.name}}
                    </label>
                </div>
            </div>
              
            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/clients/announcements')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
         <!-- Show pay link modal -->
 <div
        class="modal fade"
        :class="showForm ? 'show' : ''"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :style="showForm ? 'display:block' : ''"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-primary text-white">
              <h5>{{locale.add_button}}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="hideButtonPop()"
              ></button>
            </div>
            <div class="modal-body">
              <h4 class="text-center">
                
              </h4>
              <div
              >
                <div class="row">
                  <div class="col-12">
              
                    
                   <div class="mb-3">
                    <label class="form-label">{{locale.select_language}}</label>
                    <select class="form-select" v-model="data.language_code">
                        <option  v-for="(item,i) in data.languagesList" v-bind:key="i"  :value="item.lang_code" >{{item.name}}</option>
                    </select>
                </div>
                  <div class="mb-3">
                     <label class="form-label">{{locale.button_title}}</label>
                    <input v-model="data.button_title" type="text" class="form-control" placeholder="">
                   
                </div>
                <div class="mb-3">
                     <label class="form-label">{{locale.button_url}}</label>
                    <input v-model="data.button_url" type="text" class="form-control" placeholder="www.example.com">
                   
                </div>
                 <div class="mb-3">
                    <label class="form-label">{{locale.button_order}}</label>
                    <select class="form-select" v-model="data.button_order">
                        <option value="1" >1</option>
                        <option value="2"   >2</option>
                        <option value="3" >3</option>
                    </select>
                </div>
                 <div class="mb-3">
                    <label class="form-label">{{locale.button_location}}</label>
                    <select class="form-select" v-model="data.button_location">
                        <option value="right" >{{locale.button_right}}</option>
                        <option value="left"   >{{locale.button_left}}</option>
                        <option value="center" >{{locale.button_center}}</option>
                    </select>
                </div>
                 </div>
                 <div
                    class="
                      mb-3
                      mt-5
                      mx-auto
                      text-center
                      justify-content-center
                      align-items-center
                    "
                  >
                    <button
                      @click="AddButtonPop()"
                      
                      type="button"
                      class="btn btn-success btn-sm"
                    >
                      {{locale.add_button}}
                    </button>
                  </div>
                   
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
    </main>
</template>

<script>

import {onMounted, reactive, computed, ref} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callPostApi, getLanguageList, getChannelsList, getClientsList} from "@/api/api"
//import {validURL} from '@/utils/utils'

export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)
        let showForm = ref (false);
        let showButtonList = ref(false);

        const data = reactive({
            list_number:1,
            show_close:0,
            language_code: '',
            translationArr : [],
            buttonsArray: [],
            tempButtonstranslationArr: [],
            button_title:'',
            button_url:'',
            button_location: '',
            button_order:0,
            button_text:[],
            translationDescriptionArr : [],
            name: '',
            url: '',
            deep_link: '',
            status: 1,
            primary_notice: 0,
            expired_at: '',
            languagesList: [],
            channelsList: [],
            checkedArr: [],
            clientsList: [],
            clientsCheckedArr: [],
            submitBtn: false,
            buttonsFormLang:[]
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            
            data.languagesList  = await getLanguageList()
            data.channelsList  = await getChannelsList()
            data.clientsList  = await getClientsList()
            for (const iterator of data.languagesList) {
                data.buttonsFormLang[iterator.lang_code] = []
            }

        })

        const submit = async() => {
            // if(data.url && !validURL(data.url)){
            //     notify({text: "Valid URL required",type:'error'})
            //     return false
            // }
              
            let tempTranslationArr = []
            for(const property in data.translationArr){
                if(property!=null){
                    let tmpObj = {
                        language_code : property,
                        title : data.translationArr[property],
                        description : data.translationDescriptionArr[property] || '',
                        button_text : data.button_text[property] || ''
                    }
                    tempTranslationArr.push(tmpObj)
                }
            }
      
            if(tempTranslationArr.length == 0){
                notify({text: "Please add title and Button text",type:'error'})
                return false
            }
            data.submitBtn = true
            let _body = {
                tempTranslationObj: tempTranslationArr,
                buttons: data.buttonsArray,
                show_close: data.show_close,
                name: data.name,
                url: data.url,
                deep_link: data.deep_link,
                status: data.status,
                primary_notice: data.primary_notice,
                expired_at: data.expired_at,
                channelIds: data.checkedArr.toString(),
                clientIds: data.clientsCheckedArr.toString(),
            }

            let resData = await callPostApi('/announcement','POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/clients/announcements')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

    //     const addButtons = () => {
    //         for (const iterator of data.languagesList) {
    //             // data.language_name.push({name:iterator.name});
    //             // data.buttonsArray.push(iterator.lang_code)
    //             data.buttonsArray.push({language_code:iterator.lang_code, text:'', url:'', location:'', lang_name: iterator.name})
               
                
    //             // console.log(data.language_name);
    //         }
            

    //     //     let tempTranslationArr = [];
    //     //     let tempObject = {};
    //     //     data.bNum= ++data.bNum;
    //     //   for(const property in data.tempButtonstranslationArr){
    //     //         if(property!=null){
    //     //             let tmpObj = {
    //     //                 language_code : property.substring(0,2),
    //     //                 text : data.tempButtonstranslationArr[property],
    //     //                 url : data.button_url[property] || '',
    //     //                 location: data.button_location[property],
    //     //                 order: data.button_order[property],
    //     //             }
                   
                 
    //     //           tempObject= Object.assign(tmpObj);
                   
                    
    //     //         }
    //     //     } 
                   
    //     //          data.buttonsArray.push(tempObject)
               
    //             //  alert(data.buttonsArray.length);
            
    // //    if(data.buttonsArray.length>0){
    // //        console.log('btn_array', data.buttonsArray);
    // //    }
    //     }
        const removeButton = (index,lang_code) =>{
            data.buttonsFormLang[lang_code].splice(index, 1)
            data.buttonsArray.splice(index, 1)
            notify({text:'Button removed successfully', type:'success'})
            
        }
        const AddButtonPop = () => {
           
           if(data.language_code.length==0){
             notify({text: 'Please select language', type:'error'})
           } 
           else if(data.button_title.length==0){
              notify({text: 'Please enter button title', type:'error'})
           } 
           else if(data.button_order==0){
              notify({text: 'Please select button order', type:'error'})
           }
            else if(data.button_location.length==0){
              notify({text: 'Please select button location', type:'error'})
           }
           
            // data.buttonsArray.push({language_code: data.language_code.toString().split(/[ ,]+/)[0], text: data.button_title, url: data.button_url, location: data.button_location, order: data.button_order, language_name: data.language_code.toString().split(/[ ,]+/)[1]});
            else{
              data.buttonsFormLang[data.language_code].push({lang_code: data.language_code, title: data.button_title, url: data.button_url, location: data.button_location, button_order: data.button_order, action :''});
            // console.log('ddd',data.buttonsFormLang)
            // data.buttonsArray = data.buttonsFormLang.filter((item)=>{console.log(item)})
                data.buttonsArray=[]
            for (const iterator of data.languagesList) {
                for (const item of data.buttonsFormLang[iterator.lang_code]) {
                    data.buttonsArray.push(item)
                }
            }
            notify({text:'Button added successfully', type:'success'})
            data.language_code = '';
            data.button_title = '';
            data.button_url = '';
            data.button_location = '';
            data.button_order = '';
            showForm.value = false;
            }

            
        }
        const hideButtonPop = () => {
            data.language_code = '';
            data.button_title = '';
            data.button_url = '';
            data.button_location = '';
            data.button_order = '';
            showForm.value = false;
        }
        // const setName = (val) => {
        //     data.language_name = val.split(/[ ,]+/)[1];
        //    console.log(val.split(/[ ,]+/)[1]);
        // }
        // const showButtonListfunc = () => {
        //   data.showButtonList= true;
        // }
        // const hideButtonList = () => {
        //     data.showButtonList = false;
        //     alert(data.showButtonList)
        // }
        return {
            data,
            locale,
            submit,
            //addButtons,
            removeButton,
            AddButtonPop,
            showForm,
            hideButtonPop,
            showButtonList,
            // showButtonListfunc,
            // hideButtonList
     
            // setName
        }
    }
}

</script>