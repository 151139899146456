<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage_package}} </h2>
        <Breadcrumb :item_one_title="locale.manage_package" :item_one_url="'/package'" :item_two_title="locale.add" :item_two_active="true" />

        <div class="row">

            <div class="col-6">
                <h3>{{locale.add}} {{locale.title}} & {{locale.description}} {{locale.for}} {{locale.all}} {{locale.languages}}</h3>
                <ol class="list-group list-group-numbered">
                    <li class="list-group-item" v-for="(item,i) in data.languagesList" v-bind:key="i">
                        <div class="mb-3">
                            <label class="form-label">{{locale.title}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                            <input v-model="data.translationArr[item.lang_code]" type="text" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{locale.description}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                            <input v-model="data.translationDescriptionArr[item.lang_code]" type="text" class="form-control" required>
                        </div>
                    </li>
                </ol>

                <h3 class="mt-5">{{locale.add}} {{locale.price}} {{locale.for}} {{locale.all}}  {{locale.currencies}}</h3>
                <ol class="list-group list-group-numbered">
                    <li class="list-group-item" v-for="(item,i) in data.currencyList" v-bind:key="i">
                        <div class="mb-3">
                            <label class="form-label">{{locale.price}} {{item.code}} ({{item.symbol}})  <span class="badge bg-light text-dark">{{item.name}}</span> </label>
                            <input @input="num = $event.target.value.replace(/^\d+\.\d\d\d$/g,'');$event.target.value = num" v-model="data.priceArr[item.id]" type="number" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{locale.discount}} {{locale.price}} {{item.code}} ({{item.symbol}})  <span class="badge bg-light text-dark">{{item.name}}</span> </label>
                            <input @input="num = $event.target.value.replace(/^\d+\.\d\d\d$/g,'');$event.target.value = num" v-model="data.discountPriceArr[item.id]" type="number" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{locale.additonal}} {{locale.price}} {{item.code}} ({{item.symbol}})  <span class="badge bg-light text-dark">{{item.name}}</span> </label>
                            <input @input="num = $event.target.value.replace(/^\d+\.\d\d\d$/g,'');$event.target.value = num" v-model="data.additionalPriceArr[item.id]" type="number" class="form-control" required>
                        </div>
                    </li>
                </ol>

            </div>

            <div class="col-4">
                <div class="mb-3">
                    <label class="form-label">{{locale.sorting_order}}</label>
                    <input @input="num = $event.target.value.replace(/[^\d]/g,'');$event.target.value = num" v-model="data.sorting_order" type="number" class="form-control" placeholder="1" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.speed_limit}}</label>
                    <input v-model="data.speed_limit" type="number" class="form-control" placeholder="10000" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.plan_time}} ( {{locale.minutes}} )</label>
                    <input v-model="data.plan_time" type="number" class="form-control" placeholder="60 minutes" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.default}} {{locale.allowed}} {{locale.devices}}</label>
                    <input v-model="data.allowed_device_number" type="number" class="form-control" placeholder="2">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="2">{{locale.in_active}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.billed}}</label>
                    <select class="form-select" v-model="data.billed">
                        <option value="1">{{locale.monthly}}</option>
                        <option value="2">{{locale.yearly}}</option>
                        <option value="3">{{locale.manually}}</option>
                        <option value="4">{{locale.no}} - {{locale.trial}} {{locale.package}}</option>
                        <option value="5">{{locale.no}} - {{locale.reward}} {{locale.package}}</option>
                        <option value="6">{{locale.no}} - {{locale.offer}} {{locale.package}}</option>
                        <option value="10">{{locale.no}} - {{locale.guest}} {{locale.package}}</option>
                        <option value="11">{{locale.no}} - {{locale.redeem}} {{locale.package}}</option>
                        <option value="12">{{locale.no}} - {{locale.redeem}} {{locale.gift_codes}}  {{locale.package}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.plan_time}} ( {{locale.months}} ) </label>
                    <input v-model="data.total_month" type="number" class="form-control" placeholder="1">
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.recommended}}</label>
                    <select class="form-select" v-model="data.is_recommended">
                        <option value="0">{{locale.no}}</option>
                        <option value="1">{{locale.yes}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.is_offer}}</label>
                    <select class="form-select" v-model="data.is_offer">
                        <option value="0">{{locale.no}}</option>
                        <option value="1">{{locale.yes}}</option>
                    </select>
                </div>
                 <div class="mb-3">
                    <label class="form-label">{{locale.is_disabled}}</label>
                    <select class="form-select" v-model="data.is_disabled">
                        <option value="0">{{locale.no}}</option>
                        <option value="1">{{locale.yes}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.package}} ( {{locale.key}} ) </label>
                    <input v-model="data.product_key" type="text" class="form-control" placeholder="Monthly_plan">
                </div>
                <div class="mb-3">
                    <label class="form-label">Apple {{locale.package}} {{locale.price}} </label>
                    <input v-model="data.price_apple" type="text" class="form-control" placeholder="159.99">
                </div>
                <div class="mb-3">
                    <label class="form-label">Apple {{locale.package}}  {{locale.discount}} {{locale.price}} </label>
                    <input v-model="data.price_apple_discount" type="text" class="form-control" placeholder="59.99">
                </div>

                <div class="mb-3">
                    <div class="form-group">
                        <label class="form-label">{{ locale.expired }} {{ locale.at }}</label>
                        <input type="datetime" name="expired_at" class="form-control" v-model="data.expired_at"/>
                    </div>
                </div>

            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/package')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed, watchEffect} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import {callPostApi, getLanguageList, getCurrencyList} from "@/api/api"

export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            translationArr : [],
            translationDescriptionArr : [],
            priceArr : [],
            discountPriceArr : [],
            additionalPriceArr : [],
            sorting_order: '',
            speed_limit: '',
            plan_time: '',
            status: 1,
            billed: 3,
            allowed_device_number: 2,
            total_month: 0,
            is_recommended: 0,
            is_offer: 0,
            is_disabled: 0, 
            product_key: null,
            price_apple: 0,
            price_apple_discount: 0,
            expired_at:'',

            languagesList: [],
            currencyList: [],
            submitBtn: false,
        })

        watchEffect(() =>{
            if(data.sorting_order !=null){
                data.sorting_order =data.sorting_order.toString().replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
            }
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            
            data.languagesList  = await getLanguageList()
            data.currencyList  = await getCurrencyList()
        })

        const submit = async() => {

            let tempTranslationArr = []
            for(const property in data.translationArr){
                if(property!=null){
                    let tmpObj = {
                        language_code : property,
                        title : data.translationArr[property],
                        description : data.translationDescriptionArr[property] || ''
                    }
                    tempTranslationArr.push(tmpObj)
                }
            }

            let tempPriceArr = []
            for(const property in data.priceArr){
                if(property!=null){
                    let tmpObj = {
                        currency_id : property,
                        price : data.priceArr[property],
                        discount_price : data.discountPriceArr[property] || 0,
                        additional_device_price : data.additionalPriceArr[property] || 0,
                    }
                    tempPriceArr.push(tmpObj)
                }
            }

            if(tempPriceArr.length == 0 || tempTranslationArr.length == 0){
                notify({text: "Please add title and price",type:'error'})
                return false
            }
            if(!data.plan_time){
                notify({text: "Plan time required",type:'error'})
                return false
            }
            data.submitBtn = true
            let _body = {
                tempTranslationObj: tempTranslationArr,
                tempPriceObj: tempPriceArr,
                sorting_order: data.sorting_order || 0,
                plan_time: data.plan_time,
                speed_limit: data.speed_limit,
                status: data.status,
                billed: data.billed,
                allowed_device_number: data.allowed_device_number,
                total_month: data.total_month,
                is_recommended: data.is_recommended,
                is_offer: data.is_offer,
                is_disabled: data.is_disabled,
                product_key: data.product_key,
                price_apple: data.price_apple,
                price_apple_discount: data.price_apple_discount,
                expired_at: data.expired_at,
            }

            let resData = await callPostApi('/systemplan','POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/package')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>