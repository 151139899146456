<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.orders}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.orders" :item_one_url="'/orders'" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="row">

            <div class="col-6">
                
                <div class="mb-3">
                    <label class="form-label">{{locale.manage}} {{locale.package}}</label>
                    <select class="form-select" v-model="data.plan_id">
                        <option  v-for="(item,i) in data.plansList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.amount}}</label>
                    <input v-model="data.amount" type="number" class="form-control" placeholder="4.99">
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.manage_currency}}</label>
                    <select class="form-select" v-model="data.currency_id">
                        <option  v-for="(item,i) in data.currencyList" v-bind:key="i"  :value="item.id">{{item.name}} ({{item.symbol}})</option>
                    </select>
                </div>
    
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="0">{{locale.un_paid}}</option>
                        <option value="1">{{locale.paid}}</option>
                        <option value="2">{{locale.pending}}</option>
                        <option value="3">{{locale.cancelled}}</option>
                        <option value="4">{{locale.rejected}}</option>
                        <option value="5">{{locale.refund}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.clients}}</label>
                    <select class="form-select" v-model="data.client_id">
                        <option  v-for="(item,i) in data.clientsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.channels}}</label>
                    <select class="form-select" v-model="data.channel_id">
                        <option  v-for="(item,i) in data.channelsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>
               
            </div>

            <div class="col-6">

                <div class="mb-3">
                    <label class="form-label">{{locale.order}} {{locale.number}}</label>
                    <input v-model="data.order_number" type="text" class="form-control" placeholder="162808568371456" disabled>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.users}}</label>
                    <input v-model="data.username" type="text" class="form-control" placeholder="xyz" disabled>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.payment}} {{locale.methods}}</label>
                    <select class="form-select" v-model="data.payment_method_id" disabled>
                        <option  v-for="(item,i) in data.paymentMethodsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.data}}</label>
                   <textarea v-model="data.logs" class="form-control" placeholder=""></textarea>
                </div>
               <div class="mb-3"><label class="form-label">{{locale.test_payments}}</label><select class="form-select" v-model="data.test_payment"><option value="0">No</option><option value="1">Yes</option></select></div>
            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/orders')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getChannelsList, getCurrencyList, getPaymentMethodsList, getSystemPlansList, getClientsList} from "@/api/api"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/orders/',
            recordId: '',
            order_number: '',
            user_id: '',
            payment_method_id: '',
            plan_id: '',
            amount: '',
            currency_id: '',
            client_id: '',
            channel_id: '',
            status: '',
            logs: '',
            test_payment: 1,

            username: '',
            email: '',
            phone: '',

            clientsList: [],
            channelsList: [],
            paymentMethodsList: [],
            currencyList: [],
            plansList: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)

            let record = resData.data

            data.recordId = record.id
            data.order_number = record.order_number
            data.user_id = record.user_id
            data.payment_method_id = record.payment_method_id
            data.plan_id = record.plan_id
            data.amount = record.amount
            data.currency_id = record.currency_id
            data.client_id = record.client_id
            data.channel_id = record.channel_id
            data.status = record.status
            data.logs = record.logs

            data.username = record.username
            data.email = record.email
            data.phone = record.phone
            data.test_payment = record.is_test

            data.channelsList  = await getChannelsList()
            data.currencyList = await getCurrencyList()
            data.paymentMethodsList = await getPaymentMethodsList()
            data.plansList  = await getSystemPlansList()
            data.clientsList  = await getClientsList()

        })

        const submit = async() => {
           
            data.submitBtn = true
            let _body = {
                plan_id: data.plan_id,
                amount:  data.amount,
                currency_id:  data.currency_id,
                client_id: data.client_id,
                channel_id: data.channel_id,
                status:  data.status,
                logs: data.logs,
                is_test: data.test_payment
            }
            
            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/orders')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>