<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.assign_permissions}}</h2>
       <Breadcrumb :item_one_title="locale.assign_permissions" :item_one_active="true" :loading="data.loading" />
       <button @click="$redirect('/settings/assign-permissions/add')" type="button" class="btn btn-secondary btn-sm">{{locale.add}}</button>

      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{locale.role}} {{locale.name}}</th>
              <th scope="col">{{locale.permission}} {{locale.name}}</th>
              <th scope="col">{{locale.action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{i}}</td>
                <td>{{item[0].role_name}}</td>
                <td v-if="item[0].role_id!=1">
                  <span v-for="(childItem,k) in item"  v-bind:key="k" class="badge bg-dark ms-1">
                  {{childItem.permission_name}}</span>
                </td>
                <td v-else>{{locale.all_permissions}}</td>
                <td v-if="item[0].role_id!=1">
                   <button @click="$redirect('/settings/assign-permissions/add/?id='+i)" type="button" class="btn btn-warning btn-sm">{{locale.edit}}</button>
                  
                </td>
                <td v-else></td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi} from "@/api/api"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'AssignPermissions',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/permissions-assign',
            records: [],
            loading: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)

            let isAllowAccessPage = await checkAccessSinglePage('manage_settings', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')
                
            getRecords()
        })

        const getRecords = async() => {
          data.loading = true
          let resData = await callApi(data.endpoint, 'GET')
          data.records = resData.data
          data.loading = false
        }

        return {
            data,
            locale,
        }
    }
}

</script>