<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.users}} {{locale.devices}}</h2>
       <Breadcrumb :item_one_title="locale.manage+' '+locale.users+' '+locale.devices" :item_one_active="true" :loading="data.loading" />

        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{locale.email}}</th>
                        <th scope="col">{{locale.name}}</th>
                        <th scope="col">{{locale.udid}}</th>
                        <th scope="col">{{locale.type}}</th>
                        <th scope="col">{{locale.ip}}</th>
                        <th scope="col">{{locale.date_created}}</th>
                        <th scope="col">{{locale.firebase_token}}</th>
                        <th scope="col">{{locale.firebase_updated_token}}</th>
                        <th scope="col">{{locale.token}}</th>
                        
                        <th scope="col">{{locale.action}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,i) in data.records" v-bind:key="i" :class="item.is_deleted ? 'bg-danger': ''">
                        <td>{{item.id}}</td>
                        <td>{{item.email}} ({{item.user_id}})</td>
                        <td>{{item.device_name}}</td>
                        <td>{{item.device_udid}}</td>
                        <td>{{item.device_type}}</td>
                        <td>{{item.ip}}</td>
                        <td>{{ $moment(item.login_date).format("YYYY-MM-DD HH:mm:ss") }}</td>
                         <td>{{item.firebase_token}}</td>
                        <td>{{item.firebase_token_updated}}</td>
                        <td>{{item.auth_token}}</td>
                       
                        <td>
                            <button  @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm my-1 mx-1">{{locale.delete}}</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
        </div>


    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getSystemPlansList} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'Users',
    components: {
      Breadcrumb,
      VPagination,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/users/devices',
            recordId: '',
            page : 1,
            perPage : 10,
            totalPages : 0,
            records: [],
            loading: false,

            submitBtn: false,
           
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)

            let isAllowAccessPage = await checkAccessSinglePage('manage_users', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')


            data.recordId = route.query.id
            getRecords()

            data.plansList  = await getSystemPlansList()
        })

        const getRecords = async() => {
            data.loading = true
            if(typeof(data.recordId)=='undefined'){
                data.recordId = ''
            }
            let query_filter = new URLSearchParams(data.filters).toString()
            let resData = await callApi(data.endpoint+'?user_id='+data.recordId+'&page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
            console.log(resData)
            data.records = resData.data
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.loading = false
        }
        const deleteRecord = async(id)=>{
            if(confirm(locale.value.confirm_delete)){
                data.loading = true
                let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
                data.loading = false
                if(resData.isSuccess){
                    notify({text: resData.message, type:'success'});
                    getRecords()
                }else{
                    notify({text: resData.message, type:'error'})
                }
            }
        }

        return {
            data,
            locale,
            deleteRecord,
            getRecords,
        }
    }
}

</script>