
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
    name: "Nav",
    setup(){

        const store = useStore()
        const auth = computed( () => store.state.authenticated)
        const isAdmin = computed( () => store.state.isAdmin)

        const logout = async () => {
            
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            await store.dispatch("setAuth", false);
            await store.dispatch("setAdmin", false);

            localStorage.removeItem('vpnConnected');
        }

       return {
           auth,
           isAdmin,
           logout,
       }
    }
}
