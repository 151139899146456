<template>
    <div class="d-flex justify-content-center">
        <main class="form-signin">
                <form @submit.prevent="submit" v-if="!data.show_change_pass_form">                
                    <div class="form-floating mt-3">
                        <input v-model="data.username" type="text" class="form-control"  placeholder="Username">
                        <label>{{locale.login_username}}</label>
                    </div>
                    <div class="form-floating mt-3">
                        <input v-model="data.password" type="password" class="form-control" placeholder="Password">
                        <label>{{locale.login_password}}</label>
                    </div>
                    <button class="w-100 btn btn-lg btn-primary mt-3" type="submit">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                </form>

                <form autocomplete="off" v-if="data.show_change_pass_form">

                    <h1>{{locale.change_password_heading}}</h1>
                
                    <label class="form-label">{{locale.current}} {{locale.password}}</label>
                    <div class="input-group mb-3">
                        <input v-model="data.user_update_pass.current_password" type="password" id="current_password" class="form-control"  autocomplete="off" required="required">
                        <span class="input-group-text cursor-pointer " @click="switchVisibility('current_password')">
                            <div class="eye"></div>
                        </span>
                    </div>
                    <label class="form-label">{{locale.new}} {{locale.password}}</label>
                    <div class="input-group mb-3">
                        <input v-model="data.user_update_pass.new_password" type="password" id="new_password" class="form-control"  autocomplete="off">
                        <span class="input-group-text cursor-pointer " @click="switchVisibility('new_password')">
                            <div class="eye"></div>
                        </span>
                    </div>
                    <div class="mb-3">
                        <button @click="submitAccount" class="w-100 btn btn-lg btn-primary mt-3" type="button">{{locale.submit}}<span v-if="data.submitAccountBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                    </div>  
                </form>

        </main>
    </div>
</template>

<script>
import {onMounted, reactive, computed} from 'vue'
import {useRouter} from 'vue-router'
import { useStore } from '@/store';
import { notify } from "@kyvg/vue3-notification";
import {apiHost} from '@/config/config'
import { sha256 } from 'js-sha256'

export default{
    name:'Login',
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const locale = computed(() => store.state.translation.locale);
        const data = reactive({
            endpoint_account: '/account',
            username:'',
            password:'',
            client_ip:'',
            submitBtn: false,
            user_detail: {},
            submitAccountBtn: false,
            show_change_pass_form:false,
            user_update_pass:{
                current_password: '',
                new_password: '',
                email: '',
                phone: '',
            }
        })
        onMounted( async() =>{
            if(auth.value)
                router.push(`/`)

            let lang = 'en'
            await store.dispatch(`translation/getLangs`,{userLang:lang})

            await fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(({ ip }) => {
                data.client_ip = ip
                //console.log("client ip detail in veu.js",ip)
            }).catch(error => {
                console.log("client ip err",error)
            })
        })

        const submit = async() => {
            data.submitBtn = true
            let _body = {
                username: data.username,
                password: sha256(sha256(data.password+sha256(data.password+data.password))),
            }
            await fetch(apiHost+"/login?client_ip="+data.client_ip,{
                method:'POST',
                headers:{"Content-Type":"application/json"},
                body:JSON.stringify(_body)
            }).then(async response => {
                    data.submitBtn = false
                    const resData = await response.json()
                    //console.log('resData',resData)
                    if (!response.ok) {
                        let error = (resData && resData.message) || response.status
                        if(resData.errors){
                            error = ''
                            resData.errors.forEach(element => { error += element.msg +"<br>"})
                        }
                        notify({text: error, type:'error'})
                    }else{
                        let user = resData.data
                        if(user.user_needs_to_change_pass){
                            data.show_change_pass_form = true
                            data.user_detail = user
                            data.user_update_pass.email = data.user_detail.email
                            data.user_update_pass.phone = data.user_detail.phone
                            //await store.dispatch("setAuthUser", user)
                        }else{
                            await store.dispatch("setAuth", true)
                            await store.dispatch("setAuthUser", user)
                            notify({
                                text: resData.message,
                                type:'success'
                            });
                            await router.push('/')
                        }
                    }
            }).catch(error => {
                data.submitBtn = false
                notify({title: "Error",text: error,type:'error'}) 
            })
        }

        const submitAccount = async() => {
            if(!data.user_update_pass.current_password){
                notify({text: 'Current password required to make any change in your account.', type:'error'})
                return
            }
            if(!data.user_update_pass.new_password){
                notify({text: 'New password required.', type:'error'})
                return
            }
            if(data.user_update_pass.current_password ===data.user_update_pass.new_password){
                notify({text: 'New password should not be same as current password.', type:'error'})
                return
            }
            //This one is test

            data.submitAccountBtn = true
            let _body = {
                current_password:  sha256(sha256(data.user_update_pass.current_password+sha256(data.user_update_pass.current_password+data.user_update_pass.current_password))) ,
                new_password: sha256(sha256(data.user_update_pass.new_password+sha256(data.user_update_pass.new_password+data.user_update_pass.new_password)))
            }
            await fetch(apiHost+"/account?client_ip="+data.client_ip,{
                method:'POST',
                headers: {
                    'Authorization': data.user_detail.accessToken,
                    'Content-type': 'application/json; charset=UTF-8',
                },
                body:JSON.stringify(_body)
            }).then(async response => {
                    data.submitBtn = false
                    const resData = await response.json()
                    console.log('resData',resData)
                    if (!response.ok) {
                        let error = (resData && resData.message) || response.status
                        if(resData.errors){
                            error = ''
                            resData.errors.forEach(element => { error += element.msg +"<br>"})
                        }
                        notify({text: error, type:'error'})
                    }else{
                        await store.dispatch("setAuth", true)
                        await store.dispatch("setAuthUser", data.user_detail)
                        notify({text: resData.message,type:'success'})
                        data.user_update_pass = {}
                        await router.push('/')
                    }
            }).catch(error => {
                data.submitAccountBtn = false
                notify({title: "Error",text: error,type:'error'}) 
            })
        }

        const switchVisibility = (field_id) => {
            const passwordField = document.querySelector('#'+field_id)
            passwordField.getAttribute('type')==='password' ? passwordField.setAttribute('type','text') : passwordField.setAttribute('type','password')
        }

        return {
            locale,
            data,
            submit,
            submitAccount,
            switchVisibility
        }
    }
}
</script>

<style scoped>

</style>
