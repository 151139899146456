<template>
    <main class="col-md-9 ms-sm-auto col-lg-9 px-md-4">
        <h2>{{locale.change_locale}}</h2>
        <Breadcrumb :item_one_title="locale.change_locale" :item_one_active="true" :loading="data.loading"/>

        <div class="selecttext mt-5 w-25 ">
            <select class="form-select mt-5" aria-label="Default select example" v-model="data.user_lang" @change="changeLanguage($event)">
                <option></option>
                <option v-for="(item,i) in data.languagesList" v-bind:key="i"  :value="item.lang_code">{{item.name}}</option>
            </select>
        </div>
    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callPostApi, getLanguageList} from "@/api/api"

export default{
    name:'ChangeLocale',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)
        const data = reactive({
            endpoint: '/me/update',
            user_lang: '',
            selected_lang: null,
            languagesList: [],
            loading: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            data.loading = true
            data.languagesList  = await getLanguageList("/language",'GET')
            data.loading = false
            data.user_lang = (authUser.value.preferred_language) ? authUser.value.preferred_language.toUpperCase() : 'EN'
        })

        const changeLanguage = async (event) => {
            data.selected_lang = event.target.value
            data.user_lang = event.target.value
            //await store.dispatch(`translation/getLangs`,{userLang:data.user_lang});
            let _body = {
                update_key:'preferred_language',
                update_val:data.selected_lang.toLowerCase()
            }
            data.loading = true
            let resData = await callPostApi(data.endpoint,'POST',JSON.stringify(_body))
            data.loading = false
            if(resData.isSuccess){
                let fullLangName = ''
                let findLang = data.languagesList.filter((item)=>{ if(item.lang_code==data.selected_lang){fullLangName = item.name} })
                let tmpAuth = Object.assign({}, authUser.value)
                tmpAuth.preferred_language_name = fullLangName
                tmpAuth.preferred_language = data.selected_lang
                data.user_lang =  data.selected_lang
                await store.dispatch("setAuthUser", tmpAuth)
                await store.dispatch(`translation/getLangs`,{userLang:data.user_lang})
               // notify({  text: resData.message,  type:'success' })
                router.push(`/`)
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            changeLanguage,
        }
    }
}

</script>