<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.reports}} {{locale.hourly}}</h2>
       <Breadcrumb :item_one_title="locale.manage+' '+locale.reports" :item_one_active="true" :loading="data.loading" />
        
        <div class="table-responsive">
            
                <VueApexCharts width="1000" type="bar" :options="data.options" :series="data.series" />
            
        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi } from "@/api/api"
import {checkAccessSinglePage} from '@/utils/utils'

import VueApexCharts from "vue3-apexcharts";


export default{
    name:'OnlineVpn',
    components: {
      Breadcrumb,
      VueApexCharts
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/reports/hourly',
            records: [],
            loading: false,
            submitBtn: false,

            options: {
                chart: {
                    id: 'vuechart-example'
                },
                xaxis: {}
            },
            series: []
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_reports', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()
        })

        const getRecords = async() => {
            data.loading = true
           
            let resData = await callApi(data.endpoint, 'GET')
            console.log(resData)

            data.xaxis = resData.data.xaxis
            data.series = resData.data.series
            data.loading = false
        }

        return {
            data,
            locale,
            getRecords,
        }
    }
}

</script>