<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.tutorial}}</h2>
       <Breadcrumb :item_one_title="locale.tutorial" :item_one_active="true" :loading="data.loading" />
       
       <button @click="$redirect(data.frontEndpoint+'/add')" type="button" class="btn btn-secondary btn-sm">{{locale.add}}</button>

      <div class="table-responsive mt-3">
        <table class="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{locale.title}}</th>
              <th scope="col">{{locale.slug}}</th>
              <th scope="col">{{locale.language}}</th>
              <th scope="col">{{locale.client}}</th>
              <th scope="col">{{locale.status}}</th>
              <th scope="col">{{locale.date_created}}</th>
              <th scope="col">{{locale.action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{item.id}}</td>
                <td>{{item.title}}</td>
                <td>{{item.slug}}</td>
                <td>{{item.lang_code}}</td>
                <td>{{item.client_code}}</td>
                <td> <span class="badge bg-light text-dark  rounded-pill">{{item.status == 1 ? locale.active : locale.in_active}}</span></td>
               <td>{{ $moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}</td>
                <td>
                   <button @click="$redirect(data.frontEndpoint+'/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm my-1 mx-1">{{locale.edit}}</button>
                   <button @click="$redirect(data.frontEndpoint+'/detail/?id='+item.id)" type="button" class="btn btn-dark btn-sm my-1 mx-1">{{locale.detail}}</button>
                   <button @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm my-1 mx-1">{{locale.delete}}</button>
                </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="40">40</option>
                        <option value="60">60</option>
                        <option value="80">80</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
      </div>
    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store';
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'Blog',
    components: {
      Breadcrumb,
      VPagination
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            frontEndpoint: '/tutorial',
            endpoint: '/tutorial',
            page : 1,
            perPage : 30,
            totalPages : 0,
            records: [],
            planPricesArr: [],
            loading: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_blog', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()
        })

        const getRecords = async() => {
            data.loading = true
            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage, 'GET')
            console.log('resData',resData)
            data.records = resData.data
            data.planPricesArr = resData.planPricesArr
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.loading = false
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
            }else{
                notify({text: resData.message, type:'error'})
            }
          }
        }

        return {
            data,
            locale,
            deleteRecord,
            getRecords
        }
    }
}

</script>