<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.servers}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.servers" :item_one_url="'/servers/list'" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="row">

            <div class="col-6">
                <h3>{{locale.edit}} {{locale.title}} {{locale.for}} {{locale.all}} {{locale.languages}}</h3>
                <ol class="list-group list-group-numbered">
                    <li class="list-group-item" v-for="(item,i) in data.languagesList" v-bind:key="i">
                        <div class="mb-3">
                            <label class="form-label">{{locale.title}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                            <input v-model="data.translationArr[item.lang_code]" type="text" class="form-control" required>
                        </div>
                    </li>
                </ol>
            </div>

           <div class="col-4">
                <div class="mb-3">
                    <label class="form-label">{{locale.servers}} {{locale.areas}}</label>
                    <select class="form-select" v-model="data.server_area_id">
                        <option v-for="(item,i) in data.serversAreasList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.ip}}</label>
                    <input v-model="data.ip" type="text" class="form-control" placeholder="192.168.1.1">
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.port}}</label>
                    <select class="form-select" v-model="data.port">
                        <option v-for="(item,i) in data.ports" v-bind:key="i"  :value="item">{{item}}</option>
                    </select>
                    
                </div>
                     <div><label class="form-label">{{locale.add_new_port}}</label></div>
                <div class="input-group mb-3">
                    
                    <input v-model="data.new_port" type="text" class="form-control" placeholder="">
                     <button class="btn btn-success" type="button" id="button-addon2" @click="Add_port()">{{locale.add}}</button>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.mid}}</label>
                    <input v-model="data.mid" type="text" class="form-control" placeholder="ec26d6d8-dda1-a4dc-0ad8-1a2a613046aa">
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="2">{{locale.in_active}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.allowed}} {{locale.for}} {{locale.free}} {{locale.users}}</label>
                    <select class="form-select" v-model="data.allowed_for_free">
                        <option value="0">{{locale.no}}</option>
                        <option value="1">{{locale.yes}}</option>
                        <option value="2">{{locale.pro}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.allowed}} {{locale.for}} {{locale.pro}} {{locale.users}}</label>
                    <select class="form-select" v-model="data.allowed_for_pro">
                        <option value="0">{{locale.no}}</option>
                        <option value="1">{{locale.yes}}</option>
                        
                    </select>
                </div>
                
                <div class="mb-3">
                    <label class="form-label">{{locale.sorting_order}}</label>
                    <input v-model="data.sorting_order" type="text" class="form-control" placeholder="1" required>
                </div>
                
                <div class="mb-3">
                    <label class="form-label">{{locale.max_network_bandwidth}}</label>
                    <input v-model="data.max_network_bandwidth" type="text" class="form-control" placeholder="10000 bytes" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.info}}</label>
                   <textarea v-model="data.info" class="form-control" placeholder=""></textarea>
                </div>
                <label class="form-label">{{locale.assign}} {{locale.types}} {{locale.to}} {{locale.servers}}</label>
                <div class="form-check" v-for="(item,i) in data.serversTypesList" v-bind:key="i">
                    <input v-model="data.checkedArr" class="form-check-input" type="checkbox" :value="item.id" :id="item.id">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{item.title}}
                    </label>
                </div>
                <div class="mb-3 mt-2">
                    <label class="form-label">{{locale.restricted}} {{locale.country_name}}</label>
                    <input type="text" class="form-control" v-model="data.restricted_countries" placeholder="China, Iran, Iraq, Liberia, North Korea, Syria, and Zimbabwe">
                </div>
                <div class="mb-3 mt-2">
                    <label class="form-label">{{locale.allowed_countries}}</label>
                    <input type="text" class="form-control" v-model="data.allowed_countries" placeholder="United States of America, United Arab Emirates">
                </div>
            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/servers/list')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getLanguageList, getServersAreasList, getServersTypesList} from "@/api/api"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/servers/',
            recordId: '',
            translationArr : [],
            translationDescriptionArr : [],
            server_area_id: 1,
            ip: '',
            port: '',
            new_port: '',
            mid: '',
            status: 1,
            allowed_for_free: 0,
            allowed_for_pro: 0,
            sorting_order: '',
            max_network_bandwidth: '0',
            info: '',
            checkedArr: [],
            languagesList: [],
            serversAreasList: [],
            serversTypesList: [],
            restricted_countries:'',
            allowed_countries:'',
            submitBtn: false,

        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)

            let record = resData.data
            data.recordId = record.id
            data.server_area_id = record.server_area_id
            data.ip = record.ip
            data.port = record.port
            
            try{
                let oldPorts = record.ports
                data.ports = record.ports.split(",")
                if(!oldPorts.includes('443'))
                    data.ports.splice(0,0,'443')
            }catch(err){
               data.ports = [443] 
            }
        
            data.mid = record.mid
            data.info = record.info
            data.status = record.status
            data.allowed_for_free = record.allowed_for_free
            data.allowed_for_pro = record.allowed_for_pro
            data.sorting_order = record.sorting_order
            data.max_network_bandwidth = record.max_network_bandwidth
            data.restricted_countries = record.restricted_countries
            data.allowed_countries = record.allowed_countries
            resData.translationArr.forEach(item => {
              data.translationArr[item.language] = item.txt
              data.translationDescriptionArr[item.language] = item.extra_txt
            })

            //Show selected servers types
            try{
                data.checkedArr = resData.serverTypeIdsArr.split(",")
            }catch(err){console.log("error")}

            data.languagesList      = await getLanguageList()
            data.serversAreasList   = await getServersAreasList()
            data.serversTypesList   = await getServersTypesList()
        })

        const submit = async() => {

            let tempTranslationArr = []
            for(const property in data.translationArr){
                if(property!=null){
                    let tmpObj = {
                        language_code : property,
                        title : data.translationArr[property],
                        description : ''
                    }
                    tempTranslationArr.push(tmpObj)
                }
            }

            if(tempTranslationArr.length == 0){
                notify({
                    text: "Please add title",
                    type:'error'
                });
                return false
            }

            data.submitBtn = true
            let _body = {
                tempTranslationObj: tempTranslationArr,
                server_area_id: data.server_area_id,
                ip: data.ip,
                port: data.port,
                ports: data.ports,
                mid: data.mid,
                status: data.status,
                allowed_for_free: data.allowed_for_free,
                allowed_for_pro: data.allowed_for_pro,
                sorting_order: data.sorting_order || 0,
                max_network_bandwidth: data.max_network_bandwidth || 0,
                info: data.info,
                serverTypesIds: data.checkedArr.toString(),
                restricted_countries: data.restricted_countries,
                allowed_countries: data.allowed_countries
            }
            
            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/servers/list')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }
        
        const Add_port = () => {
            data.new_port.toString();
            console.log(data.new_port.split(','));
            data.ports.push(...data.new_port.split(','))
            data.new_port =''
        }

        return {
            data,
            locale,
            submit,
            Add_port
        }
    }
}

</script>