<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.ticket}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.ticket" :item_one_url="'/ticket'" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="row">
            <div class="col-4">
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="0">{{locale.pending}}</option>
                        <option value="1">{{locale.resolved}}</option>
                        <option value="2">{{locale.replied}}</option>
                        <option value="3">{{locale.spam}}</option>
                    </select>
                </div>
            </div>
            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/ticket')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>
        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi} from "@/api/api"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {

        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/ticket/',
            recordId: '',
            status: 0,
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)
            let record = resData.data
            console.log("record",record)
            data.recordId = record.id
            data.status = record.status
        })

        const submit = async() => {
            data.submitBtn = true
            let _body = {
                status: data.status
            }
            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/ticket')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>