<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.clients}} {{locale.url_config}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.clients +' '+ locale.url_config" :item_one_url="'/clients/url-config'" :item_two_title="locale.add" :item_two_active="true" />

        <div class="w-50">

            <div class="mb-3">
                     <label class="form-label">{{locale.country}} </label>
                    <select class="form-select" v-model="data.country_id">
                        <option  v-for="item in data.countries"
                          :key="item"
                          :value="item.id">{{item.country_name}}</option>
                    </select>
            </div>

            
            <div class="mb-3">
                <label class="form-label">{{locale.watch_time_reward_in_minutes}}</label>
                <input v-model="data.watch_time_reward_in_minutes" type="text" class="form-control" placeholder="60" required>
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.ad_display}}</label>
                <select class="form-select" v-model="data.ad_display">
                    <option value="1">{{locale.yes}}</option>
                    <option value="0">{{locale.no}}</option>
                </select>
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.ad_display_disconnect }}</label>
                <select class="form-select" v-model="data.ad_display_disconnect">
                    <option value="1">{{locale.yes}}</option>
                    <option value="0">{{locale.no}}</option>
                </select>
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.ad_display_run}}</label>
                <select class="form-select" v-model="data.ad_display_run">
                    <option value="1">{{locale.yes}}</option>
                    <option value="0">{{locale.no}}</option>
                </select>
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.ad_display_main}}</label>
                <select class="form-select" v-model="data.ad_display_main">
                    <option value="1">{{locale.yes}}</option>
                    <option value="0">{{locale.no}}</option>
                </select>
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.display_guest_mode}}</label>
                <select class="form-select" v-model="data.display_guest_mode">
                    <option value="1">{{locale.yes}}</option>
                    <option value="0">{{locale.no}}</option>
                </select>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.platforms}}</label>
                <select class="form-select" v-model="data.ad_platforms ">
                    <option value="0">{{locale.all}}</option>
                    <option value="1">{{locale.android}}</option>
                    <option value="2">{{locale.ios}}</option>
                </select>
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.status}}</label>
                <select class="form-select" v-model="data.status">
                    <option value="1">{{locale.yes}}</option>
                    <option value="0">{{locale.no}}</option>
                </select>
            </div>


            <div class="mb-3">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/clients/url-config-country')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>
        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callPostApi, getCountriesList} from "@/api/api"

export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/url-config-country',
            country_id:'',
            watch_time_reward_in_minutes: '60',
            ad_display: 1,
            ad_display_disconnect: 1,
            ad_display_run: 1,
            ad_display_main: 1,
            display_guest_mode: 1,
            ad_platforms : 0,
            status: 1,
            submitBtn: false,

            countries: [],
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)

            data.countries  = await getCountriesList()
        })

        const submit = async() => {
            data.submitBtn = true
            let _body = {
                country_id: data.country_id,
                watch_time_reward_in_minutes: data.watch_time_reward_in_minutes,
                ad_display: data.ad_display,
                ad_display_disconnect: data.ad_display_disconnect,
                ad_display_run: data.ad_display_run,
                ad_display_main: data.ad_display_main,
                display_guest_mode: data.display_guest_mode,
                ad_platforms : data.ad_platforms ,
                status: data.status,
            }

            let resData = await callPostApi(data.endpoint,'POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/clients/url-config-country')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>