<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.blog}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.blog" :item_one_url="data.frontEndpoint" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="row">

            
            <div class="col-6">

                <div class="mb-3">
                    <label class="form-label">{{locale.language}}</label>
                    <select class="form-select" v-model="data.lang_code" v-on:change="loadCategories()">
                        <option v-for="(item,i) in data.languagesList" v-bind:key="i"  :value="item.lang_code">{{item.name}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.category}}</label>
                    <select class="form-select" v-model="data.catId">
                        <option v-for="(item,i) in data.categoryList" v-bind:key="i"  :value="item.category_id">{{item.title}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.title}}</label>
                    <input v-model="data.title" type="text" class="form-control" placeholder="What is VPN?" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.slug}}</label>
                    <input v-model="data.slug" type="text" class="form-control" placeholder="what-is-vpn" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.image}} {{locale.text}} <small class="text-muted">({{locale.optional}})</small></label>
                    <input v-model="data.image_explanation_txt" type="text" class="form-control" placeholder="what-is-vpn" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.image}}</label>
                    <input accept="image/*" ref="file"  v-on:change="handleFileUpload()"  type="file" class="form-control" placeholder="what-is-vpn" required>
                </div>
                <div class="mb-3" v-if="data.image_url">
                    <img  v-bind:src="`${data.image_url}`" :alt="data.image_explanation_txt" class="img-fluid">
                </div>

            </div>

            <div class="col-4">
               
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="0">{{locale.in_active}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.blog}} {{locale.type}}</label>
                    <select class="form-select" v-model="data.post_type">
                        <option value="1">{{locale.blog}}</option>
                        <option value="2">{{locale.news}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.meta}} {{locale.title}}</label>
                    <input v-model="data.meta_title" type="text" class="form-control" placeholder="what is vpn" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.meta}} {{locale.keywords}}</label>
                    <input v-model="data.meta_keywords" type="text" class="form-control" placeholder="vpn, nordvpn, free vpn" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.meta}} {{locale.description}}</label>
                    <textarea v-model="data.meta_description" class="form-control" placeholder="A virtual router can also be used to extend the range of a weak wifi signal."></textarea>
                </div>
                
                <div class="mb-3">
                    <label class="form-label">{{locale.author}}</label>
                    <input v-model="data.author" type="text" class="form-control" placeholder="John Smith">
                </div>
                
            </div>

            <div class="col-10">
                <div class="mb-3">
                    <label class="form-label">{{locale.description}}</label>
                    <ckeditor :editor="editor" :config="editorConfig" v-model="data.description" ></ckeditor>
                </div>
            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect(data.frontEndpoint)" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed, ref, watchEffect} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {apiUploadUrl} from '@/config/config'
import {callApi, callPostApi, getLanguageList, uploadFile, getCategories} from "@/api/api"
import { generateSlug } from "@/utils/utils"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        const file = ref(null)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            frontEndpoint: '/blog',
            endpoint: '/blog/',
            recordId: '',
            blogId: '',
            catId: '',
            status: 1,
            post_type: 1,
            lang_code : 'EN',
            title : '',
            slug: '',
            description: '',
            meta_title: '',
            meta_keywords: '',
            meta_description: '',
            author: '',
            image_url: '',
            image_explanation_txt: '',

            languagesList: [],
            categoryList: [],
            submitBtn: false,
        })

        watchEffect(() =>{
            if(data.title !=null){
                data.slug = generateSlug(data.title)
            }

        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)
            
            console.log("resData.data",resData.data)

            let record = resData.data
            data.recordId = record.id
            data.blogId = record.blog_id
            data.catId = record.cat_id
            data.status = record.status
            data.post_type = record.post_type
            data.lang_code = record.lang_code
            data.title = record.title
            data.slug = record.slug
            data.description = record.description
            data.meta_title = record.meta_title
            data.meta_keywords = record.meta_keywords
            data.meta_description = record.meta_description
            data.image_url = record.image_url
            data.image_explanation_txt = record.image_explanation_txt
            data.author = record.author,
            data.languagesList  = await getLanguageList()
            data.categoryList  = await getCategories(data.lang_code)

        })

        const submit = async() => {

            if(data.lang_code == ''){
                notify({text: 'Please select language', type:'error'})
                return false
            }
            if(data.cat_id == ''){
                notify({text: 'Please select category', type:'error'})
                return false
            }
            if(data.title == ''){
                notify({text: 'Please enter title', type:'error'})
                return false
            }
            if(data.description == ''){
                notify({text: 'Please enter description', type:'error'})
                return false
            }

            data.submitBtn = true
            let _body = {
                blog_id: data.blogId,
                cat_id: data.catId,
                status: data.status,
                post_type: data.post_type,
                lang_code: data.lang_code,
                title: data.title,
                slug: data.slug,
                description: data.description,
                meta_title: data.meta_title,
                meta_keywords: data.meta_keywords,
                meta_description: data.meta_description,
                image_url: data.image_url,
                image_explanation_txt: data.image_explanation_txt,
                author: data.author,
            }

            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push(data.frontEndpoint)
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        const handleFileUpload = async() => {
            //debugger
            data.submitBtn = true
            try{
                console.log("selected file",file.value.files[0])
                const file_upload = file.value.files[0]
                const pattern = /image-*/
                if (file_upload && !file_upload.type.match(pattern)) {
                    notify({text: "Invalid image", type:'error'})
                    data.submitBtn = false
                    return
                }else{
                    const formData = new FormData()
                    formData.append('folder_name', 'blog')
                    formData.append('upload', file_upload)
                    let resData = await uploadFile(formData)
                    console.log('upload image response',resData)
                    console.log(resData.file_url)
                    data.image_url = resData.file_url
                    data.submitBtn = false
                }
            }catch(err){
                data.submitBtn = false
                console.log("image upload error",err)
            }
        }

        const loadCategories = async() => {
            data.categoryList  = await getCategories(data.lang_code)
        }

        return {
            editor: ClassicEditor,
            editorData: '<p>Content of the blog.</p>',
            editorConfig: {
                ckfinder: {
                    uploadUrl: apiUploadUrl
                },
                // toolbar: {
                //     items: [
                //         'heading',
                //         'bold',
                //         'italic',
                //         'numberedList',
                //         'bulletedList',
                //         'link',
                //         'undo',
                //         'redo',
                //     ]
                // }
            },
            data,
            locale,
            submit,
            handleFileUpload,
            file,
            loadCategories
        }
    }
}

</script>