<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.orders}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.orders" :item_one_url="data.frontEndpoint" :item_two_title="locale.detail" :item_two_active="true" />
        <div class="row">

            
            <div class="col-8">

                <div class="mb-3">
                    <label class="form-label">{{locale.email}}</label>
                    <div class="form-control"> {{ data.item.email }} ({{data.item.user_id}}) </div>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.amount}}</label>
                   <td>{{data.item.amount}} {{data.item.currency_symbol}} ({{data.item.currency_code}})</td>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.package}}</label>
                   <td>{{data.item.plan_name}}</td>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.payment}} {{locale.methods}}</label>
                   <td>{{data.item.payment_method_name}}</td>
                </div>


                <div class="mb-3">
                    <label class="form-label">{{locale.clients}}</label>
                   <td>{{data.item.client_name}}</td>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.channels}}</label>
                   <td>{{data.item.channel_name}}</td>
                </div>


            </div>

            <div class="col-4">

               
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <td>
                        <span v-if="data.item.status==0" class="badge bg-primary rounded-pill">{{locale.un_paid}}</span>
                        <span v-else-if="data.item.status==1" class="badge bg-success rounded-pill">{{locale.paid}}</span>
                        <span v-else-if="data.item.status==2" class="badge bg-secondary rounded-pill">{{locale.pending}}</span>
                        <span v-else-if="data.item.status==3" class="badge bg-warning rounded-pill">{{locale.cancelled}}</span>
                        <span v-else-if="data.item.status==4" class="badge bg-danger rounded-pill">{{locale.rejected}}</span>
                        <span v-else-if="data.item.status==5" class="badge bg-info rounded-pill">{{locale.refund}}</span>
                        <span v-else class="badge bg-dark">{{data.item.status}}</span>
                    </td>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.date_created}}</label>
                    {{ $moment(data.item.created_at).format("YYYY-MM-DD HH:mm:ss") }}
                </div>
                 <div class="mb-3">
                    <label class="form-label">{{locale.date_update}}</label>
                    {{ $moment(data.item.updated_at).format("YYYY-MM-DD HH:mm:ss") }}
                </div>
                
            </div>

            

            <div class="mb-3 mt-5">
                <button @click="$redirect(data.frontEndpoint+'/edit/?id='+data.item.id)" type="button" class="btn btn-warning btn-sm my-1 mx-1">{{locale.edit}}</button>
                <button @click="$redirect(data.frontEndpoint)" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'

import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import {callApi} from "@/api/api"


export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const locale = computed(() => store.state.translation.locale)
        let item = {}
        const data = reactive({
            frontEndpoint: '/orders',
            endpoint: '/orders/',
            item: {
                id: '',
                order_number: '',
                user_id: '',
                payment_method_id: '',
                plan_id: '',
                amount: '',
                currency_id: '',
                client_id: '',
                channel_id: '',
                status: '',
                logs: '',

                username: '',
                email: '',
                phone: '',
                created_at: '',
                updated_at: '',
            }
        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.item.id = route.query.id
            let resData = await callApi(data.endpoint+data.item.id, 'GET')
            if(resData.notLogin)
                router.push(`/login`)
            
            console.log("resData.data",resData.data)

            data.item = resData.data

        })


        return {
          
            data,
            locale,
            item:data.item

           
        }
    }
}

</script>