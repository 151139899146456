<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.clients}} {{locale.url_config}} {{locale.country}}</h2>
       <Breadcrumb :item_one_title="locale.manage +' '+ locale.clients +' '+ locale.url_config +' '+ locale.country" :item_one_active="true" :loading="data.loading" />
       <button @click="$redirect('/clients/url-config-country/add')" type="button" class="btn btn-secondary btn-sm">{{locale.add}}</button>
       <div class="col-6 mb-3 mt-3">
                <label class="form-label">{{locale.search}} {{locale.country}} </label>
                <select class="form-select" v-model="data.country_id" @change="getCountryConfig($event.target.value)">
                    <option  v-for="item in data.countries"
                      :key="item"
                      :value="item.id">{{item.country_name}}</option>
                </select>
        </div>


      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{locale.name}}</th>
              <th scope="col">{{locale.watch_time_reward_in_minutes}} <i title="Watch ads time in minutes i.e. 60 minutes" v-html="icon_info"></i> </th>
              <th scope="col">{{locale.ad_display}} <i title="Ad display or not in app" v-html="icon_info"></i></th>
              <th scope="col">{{locale.ad_display_disconnect}} <i title="When user wants to disconnect (short ad - 5 seconds)" v-html="icon_info"></i></th>
              <th scope="col">{{locale.ad_display_run}} <i title="Ad display when the app opens" v-html="icon_info"></i></th>
              <th scope="col">{{locale.ad_display_main}} <i title="Banner Ad display in app" v-html="icon_info"></i></th>
              <th scope="col">{{locale.display_guest_mode}} <i title="Display Guest mode or hide guest mode" v-html="icon_info"></i></th>
              <th scope="col">{{locale.platforms}} <i title="Apply for all platforms or IOS or Android" v-html="icon_info"></i></th>
              <th scope="col">{{locale.status}} <i title="Enable or Disable any config for country" v-html="icon_info"></i></th>
              <th scope="col">{{locale.action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{item.id}}</td>
                <td>{{item.country_name}}</td>
                <td>{{item.watch_time_reward_in_minutes}}</td>
                <td>{{item.ad_display == 1 ? locale.yes : locale.no}}</td>
                <td>{{item.ad_display_disconnect == 1 ? locale.yes : locale.no}}</td>
                <td>{{item.ad_display_run == 1 ? locale.yes : locale.no}}</td>
                <td>{{item.ad_display_main == 1 ? locale.yes : locale.no}}</td>
                <td>{{item.display_guest_mode == 1 ? locale.yes : locale.no}}</td>
                <td>{{item.ad_platforms  == 2 ? locale.ios :item.ad_platforms  == 1 ? locale.android:locale.all}}</td>
                <td>{{item.status == 1 ? locale.active : locale.inactive}}</td>
                <td>
                   <button @click="$redirect('/clients/url-config-country/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm">{{locale.edit}}</button>
                   <button @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm ms-1">{{locale.delete}}</button>
                </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex mb-5">
            <div class="col-10">
                <v-pagination
                v-model="data.page"
                :pages="data.totalPages"
                :range-size="2"
                active-color="#d2f4ea"
                @update:modelValue="getRecords"
            />
            </div>
            <div class="col-2">
                <select @change="getRecords()" class="form-select" v-model="data.perPage">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                </select>
            </div>
        </div>

      </div>
    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {callApi,callPostApi,getCountriesList} from "@/api/api"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'ManageClientsUrlConfigCountry',
    components: {
      Breadcrumb,
      VPagination
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/url-config-country',
            page : 1,
            perPage : 10,
            totalPages : 0,
            records: [],
            loading: false,

            filters:{
                country_id: '',
            },
            countries: [],

           // icon_info:'<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16"><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/></svg>'
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_clients', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()
            data.countries  = await getCountriesList()
        })

        const getRecords = async() => {
            let query_filter = new URLSearchParams(data.filters).toString()

            data.loading = true
            let resData = await callApi(data.endpoint+"?"+query_filter, 'GET')
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.records = resData.data
            data.loading = false
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
            }else{
                notify({text: resData.message, type:'error'})
            }
          }
        }

        const getCountryConfig = async(country_id)=>{
            data.filters.country_id = country_id
            getRecords()
        }

        return {
            data,
            locale,
            deleteRecord,
            getCountryConfig
        }
    }
}

</script>