<template>
  <div class="flex-shrink-0 p-3" style="height: 100vh" v-if="auth">
    <!-- <a href="/" class="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
      <svg class="bi me-2" width="30" height="24"><use xlink:href="#bootstrap"></use></svg>
      <span class="fs-5 fw-semibold">Collapsible</span>
    </a> -->
    <ul class="list-unstyled ps-0 left-sidebar">
      <li
        @click="redirect('/dashboard')"
        class="mb-1"
        v-if="checkAccess('dashboard')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded">
          {{ locale.dashboard }}</a
        >
      </li>

      <li class="mb-1" v-if="checkAccess('manage_reports')">
        <button
          @click="setShow('reports')"
          class="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          aria-expanded="false"
        >
          {{ locale.manage }} {{ locale.reports }}
        </button>
        <div
          :class="data.selectedMenu == 'reports' ? 'show' : ''"
          class="collapse"
        >
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li @click="redirect('/reports/hourly')">
              <a href="#" class="link-dark rounded">{{ locale.hourly }}</a>
            </li>
            <li @click="redirect('/reports/monthly')">
              <a href="#" class="link-dark rounded">{{ locale.monthly }}</a>
            </li>
            <li @click="redirect('/reports/users/registered')">
              <a href="#" class="link-dark rounded"
                >{{ locale.registered }} {{ locale.users }}</a
              >
            </li>
            <li @click="redirect('/reports/users/paying')">
              <a href="#" class="link-dark rounded"
                >{{ locale.paying }} {{ locale.users }}</a
              >
            </li>
            <li @click="redirect('/reports/users/paying/first/time')">
              <a href="#" class="link-dark rounded"
                >{{ locale.paying }} {{ locale.users }} {{ locale.first }}
                {{ locale.time }}</a
              >
            </li>
            <li @click="redirect('/reports/payments/renewals')">
              <a href="#" class="link-dark rounded"
                >{{ locale.payment }} {{ locale.renewals }}</a
              >
            </li>
            <li @click="redirect('/reports/downloads/stats')">
              <a href="#" class="link-dark rounded"
                >{{ locale.users }} {{ locale.download }}</a
              >
            </li>
            <li @click="redirect('/reports/users/onlin/vpn')">
              <a href="#" class="link-dark rounded"
                >{{ locale.users }} {{ locale.online }} {{ locale.vpn }}</a
              >
            </li>
            <li @click="redirect('/reports/users/watchAds')">
              <a href="#" class="link-dark rounded"
                >{{ locale.user_watch_ads }}</a
              >
            </li>
             <li @click="redirect('/reports/users/expired-soon')">
              <a href="#" class="link-dark rounded"
                >{{ locale.users}} {{locale.expired_soon}}</a
              >
            </li>
          </ul>
        </div>
      </li>

      <li class="mb-1" v-if="checkAccess('manage_users')">
        <button
          @click="setShow('users')"
          class="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          aria-expanded="false"
        >
          {{ locale.manage }} {{ locale.users }}
        </button>
        <div
          :class="data.selectedMenu == 'users' ? 'show' : ''"
          class="collapse"
        >
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li @click="redirect('/users/list')">
              <a href="#" class="link-dark rounded"
                >{{ locale.view }} {{ locale.users }} {{ locale.list }}</a
              >
            </li>
           
            <li @click="redirect('/users/packages')">
              <a href="#" class="link-dark rounded"
                >{{ locale.users }} {{ locale.packages }}</a
              >
            </li>
            <li @click="redirect('/users/devices')">
              <a href="#" class="link-dark rounded"
                >{{ locale.users }} {{ locale.devices }}</a
              >
            </li>
             <li @click="redirect('/users/earn-points')">
              <a href="#" class="link-dark rounded"
                > {{ locale.user }} {{ locale.earn_points }}</a
              >
            </li>
            <li @click="redirect('/users/redeem-points')">
              <a href="#" class="link-dark rounded"
                > {{ locale.user }} {{ locale.redeem_points }}</a
              >
            </li>
          </ul>
        </div>
      </li>

      <li
        class="mb-1"
        @click="redirect('/orders')"
        v-if="checkAccess('manage_orders')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.orders }}</a
        >
      </li>

      <li class="mb-1" v-if="checkAccess('manage_servers')">
        <button
          @click="setShow('servers')"
          class="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          aria-expanded="false"
        >
          {{ locale.manage }} {{ locale.servers }}
        </button>
        <div
          :class="data.selectedMenu == 'servers' ? 'show' : ''"
          class="collapse"
        >
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li @click="redirect('/servers/list')">
              <a href="#" class="link-dark rounded"
                >{{ locale.servers }} {{ locale.list }}</a
              >
            </li>
            <li @click="redirect('/servers/areas')">
              <a href="#" class="link-dark rounded"
                >{{ locale.servers }} {{ locale.areas }}</a
              >
            </li>
            <li @click="redirect('/servers/types')">
              <a href="#" class="link-dark rounded"
                >{{ locale.servers }} {{ locale.types }}</a
              >
            </li>
          </ul>
        </div>
      </li>

      <li
        class="mb-1"
        @click="redirect('/package')"
        v-if="checkAccess('manage_package')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded">{{
          locale.manage_package
        }}</a>
      </li>
      <li class="mb-1" v-if="checkAccess('manage_gift_code_builder')">
        <button
          @click="setShow('gift-code-builder')"
          class="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          aria-expanded="false"
        >
          {{ locale.gift_code_builder }}
        </button>
        <div
          :class="data.selectedMenu == 'gift-code-builder' ? 'show' : ''"
          class="collapse"
        >
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li @click="redirect('/gift-code-builder/batch')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.batch }}</a
              >
            </li>
            <li @click="redirect('/gift-code-builder/gift-code')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.gift_codes }}</a
              >
            </li>
          </ul>
        </div>
      </li>

      <li class="mb-1" v-if="checkAccess('manage_ads')">
        <button
          @click="setShow('ads')"
          class="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          aria-expanded="false"
        >
          {{locale.manage}} {{ locale.ads }}
        </button>
        <div
          :class="data.selectedMenu == 'ads' ? 'show' : ''"
          class="collapse"
        >
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li @click="redirect('/ads/ad-source')">
              <a href="#" class="link-dark rounded">{{ locale.manage }} {{ locale.ad_source }}</a>
            </li>
             <li @click="redirect('/ads/ad-source-code')">
              <a href="#" class="link-dark rounded">{{ locale.manage }} {{ locale.ad_source_code }}</a>
            </li>
          </ul>
        </div>
      </li>

      <li
        class="mb-1"
        @click="redirect('/payment-methods')"
        v-if="checkAccess('manage_payment_methods')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.payment }} {{ locale.methods }}</a
        >
      </li>

      <li class="mb-1" v-if="checkAccess('manage_clients')">
        <button
          @click="setShow('clients')"
          class="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          aria-expanded="false"
        >
          {{ locale.manage }} {{ locale.clients }}
        </button>
        <div
          :class="data.selectedMenu == 'clients' ? 'show' : ''"
          class="collapse"
        >
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li @click="redirect('/clients/list')">
              <a href="#" class="link-dark rounded"
                >{{ locale.clients }} {{ locale.list }}</a
              >
            </li>
            <li @click="redirect('/clients/clients-config')">
              <a href="#" class="link-dark rounded"
                >{{ locale.clients }} {{ locale.config }}</a
              >
            </li>
            <li @click="redirect('/clients/announcements')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.announcements }}</a
              >
            </li>
            <li @click="redirect('/clients/push-notification')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.push_notification }}</a
              >
            </li>
            <li @click="redirect('/clients/url-config')">
              <a href="#" class="link-dark rounded"
                >{{ locale.clients }} {{ locale.url_config }}</a
              >
            </li>
            <li @click="redirect('/clients/url-config-country')">
              <a href="#" class="link-dark rounded"
                >{{ locale.clients }} {{ locale.url_config }} {{ locale.country }}</a
              >
            </li>
          </ul>
        </div>
      </li>

      <li class="mb-1" v-if="checkAccess('manage_settings')">
        <button
          @click="setShow('settings')"
          class="btn btn-toggle align-items-center rounded collapsed"
          data-bs-toggle="collapse"
          aria-expanded="false"
        >
          {{ locale.settings }}
        </button>
        <div
          :class="data.selectedMenu == 'settings' ? 'show' : ''"
          class="collapse"
        >
          <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            <li @click="redirect('/settings/currency')">
              <a href="#" class="link-dark rounded">{{
                locale.manage_currency
              }}</a>
            </li>
            <li @click="redirect('/settings/languages')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.languages }}</a
              >
            </li>
            <li @click="redirect('/settings/groups')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.groups }}</a
              >
            </li>
            <li @click="redirect('/settings/channels')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.channels }}</a
              >
            </li>
            <li @click="redirect('/settings/shortcuts')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.shortcuts }}</a
              >
            </li>
            <li @click="redirect('/settings/restricted-area')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.restricted }}
                {{ locale.area }}</a
              >
            </li>
            <li @click="redirect('/settings/whitelist')">
              <a href="#" class="link-dark rounded"
                >{{ locale.manage }} {{ locale.whitelist }}</a
              >
            </li>
            <li @click="redirect('/settings/points')">
              <a href="#" class="link-dark rounded"
                >{{ locale.points }} {{ locale.settings }}</a
              >
            </li>

            <li class="border-top"></li>
            <li @click="redirect('/settings/admin-users')">
              <a href="#" class="link-dark rounded">{{
                locale.manage_admin_users
              }}</a>
            </li>
            <li @click="redirect('/settings/roles')">
              <a href="#" class="link-dark rounded">{{
                locale.manage_roles
              }}</a>
            </li>
            <li @click="redirect('/settings/assign-permissions')">
              <a href="#" class="link-dark rounded">{{
                locale.assign_permissions
              }}</a>
            </li>
            <li @click="redirect('/settings/permissions')">
              <a href="#" class="link-dark rounded">{{
                locale.manage_permissions
              }}</a>
            </li>
            <li @click="redirect('/admin-operation-logs')">
              <a href="#" class="link-dark rounded">{{
                locale.admin_operation_logs
              }}</a>
            </li>
          </ul>
        </div>
      </li>
      <li class="border-top my-3"></li>

      <li
        class="mb-1"
        @click="redirect('/blog')"
        v-if="checkAccess('manage_blog')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.blog }}</a
        >
      </li>
      <li
        class="mb-1"
        @click="redirect('/category')"
        v-if="checkAccess('manage_blog')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.category }}</a
        >
      </li>
      <li
        class="mb-1"
        @click="redirect('/faq')"
        v-if="checkAccess('manage_faq')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.faq }}</a
        >
      </li>

      <li
        class="mb-1"
        @click="redirect('/tutorial')"
        v-if="checkAccess('manage_tutorial')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.tutorial }}</a
        >
      </li>

      <li class="border-top my-3" v-if="checkAccess('manage_ticket')"></li>

      <li
        class="mb-1"
        @click="redirect('/ticket')"
        v-if="checkAccess('manage_ticket')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.ticket }}</a
        >
      </li>
      <li
        class="mb-1"
        @click="redirect('/bug-reported')"
        v-if="checkAccess('manage_ticket')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.bugs }}</a
        >
      </li>
      <li
        class="mb-1"
        @click="redirect('/error-reported')"
        v-if="checkAccess('manage_ticket')"
      >
        <a href="#" class="btn btn-toggle align-items-center rounded"
          >{{ locale.manage }} {{ locale.clients_error }}</a
        >
      </li>

      <li class="border-top my-3"></li>

      <li @click="redirect('/account')" class="mb-1">
        <a href="#" class="btn btn-toggle align-items-center rounded">{{
          locale.account
        }}</a>
      </li>

      <li @click="redirect('/settings/change/locale')" class="mb-1">
        <a href="#" class="btn btn-toggle align-items-center rounded">{{
          locale.change_locale
        }}</a>
      </li>

      <li @click="logout" class="mb-1">
        <a href="#" class="btn btn-toggle align-items-center rounded">
          {{ locale.logout }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import { onMounted, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// import {checkAccess} from '@/utils/utils'

export default {
  name: "SideNav",
  setup() {
    const router = useRouter();
    const store = useStore();
    const auth = computed(() => store.state.authenticated);
    const authUser = computed(() => store.state.authUser);
    const locale = computed(() => store.state.translation.locale);

    const data = reactive({
      selectedMenu: "",
    });

    onMounted(async () => {
      if (!auth.value) {
        await router.push("/login");
      }
      //let lang = 'en'//authUser.value.preferred_language ? authUser.value.preferred_language : "en";
      //store.dispatch(`translation/getLangs`, { userLang: lang });
    });

    const setShow = (menu) => {
      if (data.selectedMenu == menu) data.selectedMenu = "";
      else data.selectedMenu = menu;
    };

    const redirect = (page) => {
      router.push(`${page}`);
    };

    const logout = async () => {
      await store.dispatch("setAuth", false);
      await store.dispatch("setAuthUser", null);
      await router.push("/login");
    };

    const checkAccess = (action) => {
      try {
        if (authUser.value.role_id == 1) {
          return true;
        }
        let findIndex = authUser.value.rolesArr.findIndex(
          (obj) => obj.permission_key === action
        );
        if (findIndex == 0 || (findIndex && findIndex != "-1")) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    };

    return {
      locale,
      auth,
      data,
      setShow,
      redirect,
      logout,
      checkAccess,
    };
  },
};
</script>

<style scoped>
</style>