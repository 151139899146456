import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from "@/components/home/Home.vue"
import Login from "@/components/login/Login.vue"
import ChangeLocale from "@/components/settings/ChangeLocale.vue"

import Dashboard from "@/components/dashboard/Index.vue"


import AdminUsersIndex from "@/components/settings/admin-users/Index.vue"
import AdminUsersAdd from "@/components/settings/admin-users/Add.vue"
import AdminUsersEdit from "@/components/settings/admin-users/Edit.vue"

import ManageRoles from "@/components/settings/roles/Index.vue"
import ManageRolesAdd from "@/components/settings/roles/Add.vue"
import ManageRolesEdit from "@/components/settings/roles/Edit.vue"

import AssignPermissions from "@/components/settings/assign-permissions/Index.vue"
import AssignPermissionsAdd from "@/components/settings/assign-permissions/Add.vue"
//import AssignPermissionsEdit from "@/components/settings/assign-permissions/Edit.vue"


import ManagePermissions from "@/components/settings/permissions/Index.vue"
import ManagePermissionsAdd from "@/components/settings/permissions/Add.vue"
import ManagePermissionsEdit from "@/components/settings/permissions/Edit.vue"

import ManageCurrency from "@/components/settings/currency/Index.vue"
import ManageCurrencyAdd from "@/components/settings/currency/Add.vue"
import ManageCurrencyEdit from "@/components/settings/currency/Edit.vue"


import Package from "@/components/package/Index.vue"
import PackageAdd from "@/components/package/Add.vue"
import PackageEdit from "@/components/package/Edit.vue"

import Managebatch from "@/components/gift-code-builder/batch/Index.vue"
import ManagebatchAdd from "@/components/gift-code-builder/batch/add.vue"
import ManagebatchEdit from "@/components/gift-code-builder/batch/Edit.vue"

import ManageGiftCode from "@/components/gift-code-builder/gift-code/Index.vue"

import ManageAdSource from "@/components/ads/ad-source/Index.vue"
import ManageAdSourceAdd from "@/components/ads/ad-source/Add.vue"
import ManageAdSourceEdit from "@/components/ads/ad-source/Edit.vue"

import ManageAdSourceCode from "@/components/ads/ad-source-code/Index.vue"
import ManageAdSourceCodeAdd from "@/components/ads/ad-source-code/Add.vue"
import ManageAdSourceCodeEdit from "@/components/ads/ad-source-code/Edit.vue"



import PaymentMethods from "@/components/payment-methods/Index.vue"
import PaymentMethodsAdd from "@/components/payment-methods/Add.vue"
import PaymentMethodsEdit from "@/components/payment-methods/Edit.vue"

import ManageLanguage from "@/components/settings/languages/Index.vue"
import ManageLanguageAdd from "@/components/settings/languages/Add.vue"
import ManageLanguageEdit from "@/components/settings/languages/Edit.vue"

import ManageGroups from "@/components/settings/groups/Index.vue"
import ManageGroupsAdd from "@/components/settings/groups/Add.vue"
import ManageGroupsEdit from "@/components/settings/groups/Edit.vue"

import ManageChannels from "@/components/settings/channels/Index.vue"
import ManageChannelsAdd from "@/components/settings/channels/Add.vue"
import ManageChannelsEdit from "@/components/settings/channels/Edit.vue"

import ManageShortcuts from "@/components/settings/shortcuts/Index.vue"
import ManageShortcutsAdd from "@/components/settings/shortcuts/Add.vue"
import ManageShortcutsEdit from "@/components/settings/shortcuts/Edit.vue"

import ManageUsers from "@/components/users/list/Index.vue"
import ManageEarnedPoints from "@/components/users/earn-points/Index.vue"
import ManageRedeemPoints from "@/components/users/redeem-points/Index.vue"
//import ManageUsersAdd from "@/components/users/Add.vue"
import ManageUsersEdit from "@/components/users/list/Edit.vue"

import ManageUserPackages from "@/components/users/packages/Index.vue"
import ManageUserPackagesEdit from "@/components/users/packages/Edit.vue"

import ManageUserDevices from "@/components/users/devices/Index.vue"


import ManageOrders from "@/components/orders/Index.vue"
//import ManageOrdersAdd from "@/components/orders/Add.vue"
import ManageOrdersEdit from "@/components/orders/Edit.vue"
import ManageOrdersDetail from "@/components/orders/Detail.vue"

import ManageServersList from "@/components/servers/list/Index.vue"
import ManageServersAdd from "@/components/servers/list/Add.vue"
import ManageServersEdit from "@/components/servers/list/Edit.vue"

import ManageServersAreas from "@/components/servers/areas/Index.vue"
import ManageServersAreasAdd from "@/components/servers/areas/Add.vue"
import ManageServersAreasEdit from "@/components/servers/areas/Edit.vue"

import ManageServersTypes from "@/components/servers/types/Index.vue"
import ManageServersTypesAdd from "@/components/servers/types/Add.vue"
import ManageServersTypesEdit from "@/components/servers/types/Edit.vue"

import ManageClients from "@/components/clients/list/Index.vue"
import ManageClientsAdd from "@/components/clients/list/Add.vue"
import ManageClientsEdit from "@/components/clients/list/Edit.vue"

import ManageClientsConfig from "@/components/clients/clients-config/Index.vue"
import ManageClientsConfigAdd from "@/components/clients/clients-config/Add.vue"
import ManageClientsConfigEdit from "@/components/clients/clients-config/Edit.vue"


import ManageClientsAnnouncements from "@/components/clients/announcements/Index.vue"
import ManageClientsAnnouncementsAdd from "@/components/clients/announcements/Add.vue"
import ManageClientsAnnouncementsEdit from "@/components/clients/announcements/Edit.vue"


import ManageClientsUrlConfig from "@/components/clients/url-config/Index.vue"
import ManageClientsUrlConfigAdd from "@/components/clients/url-config/Add.vue"
import ManageClientsUrlConfigEdit from "@/components/clients/url-config/Edit.vue"

import ManageClientsUrlConfigCountry from "@/components/clients/url-config-country/Index.vue"
import ManageClientsUrlConfigCountryAdd from "@/components/clients/url-config-country/Add.vue"
import ManageClientsUrlConfigCountryEdit from "@/components/clients/url-config-country/Edit.vue"

import ManageFaq from "@/components/faq/Index.vue"
import ManageFaqAdd from "@/components/faq/Add.vue"
import ManageFaqEdit from "@/components/faq/Edit.vue"

import ManageTutorial from "@/components/tutorial/Index.vue"
import ManageTutorialAdd from "@/components/tutorial/Add.vue"
import ManageTutorialEdit from "@/components/tutorial/Edit.vue"
import ManageTutorialDetail from "@/components/tutorial/Detail.vue"




import ManageBlog from "@/components/blog/Index.vue"
import ManageBlogAdd from "@/components/blog/Add.vue"
import ManageBlogEdit from "@/components/blog/Edit.vue"
import ManageBlogDetail from "@/components/blog/Detail.vue"

import ManageCategory from "@/components/category/Index.vue"
import ManageCategoryAdd from "@/components/category/Add.vue"
import ManageCategoryEdit from "@/components/category/Edit.vue"

import ManageTicket from "@/components/ticket/Index.vue"
import ManageTicketEdit from "@/components/ticket/Edit.vue"


import ManageRestrictedArea from "@/components/settings/restricted-area/Index.vue"
import ManageRestrictedAreaAdd from "@/components/settings/restricted-area/Add.vue"
import ManageRestrictedAreaEdit from "@/components/settings/restricted-area/Edit.vue"


import ManageWhitelist from "@/components/settings/whitelist-area/Index.vue"
import ManageWhitelistAdd from "@/components/settings/whitelist-area/Add.vue"
import ManageWhitelistEdit from "@/components/settings/whitelist-area/Edit.vue"


import ManagePoints from "@/components/settings/point-types/Index.vue"
import ManagePointsAdd from "@/components/settings/point-types/Add.vue"
import ManagePointsEdit from "@/components/settings/point-types/Edit.vue"

import ManageAdminOperationLogs from "@/components/admin-operation-logs/Index.vue"

import ReportsUsersRegistered from "@/components/reports/users/Registered.vue"
import ReportsUsersPaying from "@/components/reports/users/PayingUsers.vue"
import ReportsUsersOnlineVpn from "@/components/reports/users/OnlineVpn.vue"
import ReportsUsersWatchAds from "@/components/reports/users/watchAds.vue"
import ReportsUsersExpiredSoon from "@/components/reports/users/UsersExpiredSoon.vue"
import ReportsHourly from "@/components/reports/Hourly.vue"
import ReportsMonthly from "@/components/reports/Monthly.vue"
import ReportsUsersPayingFirstTime from "@/components/reports/users/PayingUsersFirstTime.vue"
import ReportsPaymentRenewals from "@/components/reports/payments/Renewals.vue"
import ReportsDownloads from "@/components/reports/downloads/Stats.vue"

import ManageBugReported from "@/components/bug-reported/Index.vue"
import ManageErrorReported from "@/components/error-reported/Index.vue"


import ManagePushNotification from "@/components/clients/push-notification/Index.vue"
import ManagePushNotificationSend from "@/components/clients/push-notification/Send.vue"

import Account from "@/components/Account.vue"


const routes: Array<RouteRecordRaw> = [
  { path: '/', component: Home },
  { path: '/dashboard', component: Dashboard },
  { path: '/login', component: Login },
  { path: '/settings/change/locale', component: ChangeLocale },
  { path: '/account', component: Account },


  { path: '/reports/hourly', component: ReportsHourly },
  { path: '/reports/monthly', component: ReportsMonthly },
  { path: '/reports/users/registered', component: ReportsUsersRegistered },
  { path: '/reports/users/paying', component: ReportsUsersPaying },
  { path: '/reports/users/paying/first/time', component: ReportsUsersPayingFirstTime },
  { path: '/reports/payments/renewals', component: ReportsPaymentRenewals },
  { path: '/reports/downloads/stats', component: ReportsDownloads },


  { path: '/reports/users/onlin/vpn', component: ReportsUsersOnlineVpn },
  { path: '/reports/users/watchAds', component: ReportsUsersWatchAds },
  { path: '/reports/users/expired-soon', component: ReportsUsersExpiredSoon },

  { path: '/users/earn-points', component: ManageEarnedPoints },
  { path: '/users/redeem-points', component: ManageRedeemPoints },
  { path: '/users/list', component: ManageUsers },
  //{path: '/users/add', component: ManageUsersAdd},
  { path: '/users/edit', component: ManageUsersEdit },

  { path: '/users/packages', component: ManageUserPackages },
  { path: '/users/packages/edit', component: ManageUserPackagesEdit },

  { path: '/users/devices', component: ManageUserDevices },


  { path: '/orders', component: ManageOrders },
  //{path: '/users/add', component: ManageUsersAdd},
  { path: '/orders/edit', component: ManageOrdersEdit },
  { path: '/orders/detail', component: ManageOrdersDetail },

  { path: '/servers/list', component: ManageServersList },
  { path: '/servers/list/add', component: ManageServersAdd },
  { path: '/servers/list/edit', component: ManageServersEdit },

  { path: '/servers/areas', component: ManageServersAreas },
  { path: '/servers/areas/add', component: ManageServersAreasAdd },
  { path: '/servers/areas/edit', component: ManageServersAreasEdit },

  { path: '/servers/types', component: ManageServersTypes },
  { path: '/servers/types/add', component: ManageServersTypesAdd },
  { path: '/servers/types/edit', component: ManageServersTypesEdit },

  { path: '/clients/list', component: ManageClients },
  { path: '/clients/list/add', component: ManageClientsAdd },
  { path: '/clients/list/edit', component: ManageClientsEdit },

  { path: '/clients/clients-config', component: ManageClientsConfig },
  { path: '/clients/clients-config/add', component: ManageClientsConfigAdd },
  { path: '/clients/clients-config/edit', component: ManageClientsConfigEdit },

  { path: '/clients/announcements', component: ManageClientsAnnouncements },
  { path: '/clients/announcements/add', component: ManageClientsAnnouncementsAdd },
  { path: '/clients/announcements/edit', component: ManageClientsAnnouncementsEdit },

  { path: '/clients/url-config', component: ManageClientsUrlConfig },
  { path: '/clients/url-config/add', component: ManageClientsUrlConfigAdd },
  { path: '/clients/url-config/edit', component: ManageClientsUrlConfigEdit },

  { path: '/clients/url-config-country', component: ManageClientsUrlConfigCountry },
  { path: '/clients/url-config-country/add', component: ManageClientsUrlConfigCountryAdd },
  { path: '/clients/url-config-country/edit', component: ManageClientsUrlConfigCountryEdit },
  

  { path: '/faq', component: ManageFaq },
  { path: '/faq/add', component: ManageFaqAdd },
  { path: '/faq/edit', component: ManageFaqEdit },

  { path: '/tutorial', component: ManageTutorial },
  { path: '/tutorial/add', component: ManageTutorialAdd },
  { path: '/tutorial/edit', component: ManageTutorialEdit },
  { path: '/tutorial/detail', component: ManageTutorialDetail },

  { path: '/package', component: Package },
  { path: '/package/add', component: PackageAdd },
  { path: '/package/edit', component: PackageEdit },

  { path: '/gift-code-builder/batch', component: Managebatch },
  { path: '/gift-code-builder/batch/add', component: ManagebatchAdd },
  { path: '/gift-code-builder/batch/Edit', component: ManagebatchEdit },


  { path: '/gift-code-builder/gift-code', component: ManageGiftCode },

  { path: '/payment-methods', component: PaymentMethods },
  { path: '/payment-methods/add', component: PaymentMethodsAdd },
  { path: '/payment-methods/edit', component: PaymentMethodsEdit },

  { path: '/settings/admin-users', component: AdminUsersIndex },
  { path: '/settings/admin-users/add', component: AdminUsersAdd },
  { path: '/settings/admin-users/edit', component: AdminUsersEdit },

  { path: '/settings/roles', component: ManageRoles },
  { path: '/settings/roles/add', component: ManageRolesAdd },
  { path: '/settings/roles/edit', component: ManageRolesEdit },

  { path: '/settings/assign-permissions', component: AssignPermissions },
  { path: '/settings/assign-permissions/add', component: AssignPermissionsAdd },


  { path: '/settings/permissions', component: ManagePermissions },
  { path: '/settings/permissions/add', component: ManagePermissionsAdd },
  { path: '/settings/permissions/edit', component: ManagePermissionsEdit },


  { path: '/settings/currency', component: ManageCurrency },
  { path: '/settings/currency/add', component: ManageCurrencyAdd },
  { path: '/settings/currency/edit', component: ManageCurrencyEdit },

  { path: '/settings/languages', component: ManageLanguage },
  { path: '/settings/languages/add', component: ManageLanguageAdd },
  { path: '/settings/languages/edit', component: ManageLanguageEdit },

  { path: '/settings/groups', component: ManageGroups },
  { path: '/settings/groups/add', component: ManageGroupsAdd },
  { path: '/settings/groups/edit', component: ManageGroupsEdit },

  { path: '/settings/channels', component: ManageChannels },
  { path: '/settings/channels/add', component: ManageChannelsAdd },
  { path: '/settings/channels/edit', component: ManageChannelsEdit },

  { path: '/settings/shortcuts', component: ManageShortcuts },
  { path: '/settings/shortcuts/add', component: ManageShortcutsAdd },
  { path: '/settings/shortcuts/edit', component: ManageShortcutsEdit },

  { path: '/blog', component: ManageBlog },
  { path: '/blog/add', component: ManageBlogAdd },
  { path: '/blog/edit', component: ManageBlogEdit },
  { path: '/blog/detail', component: ManageBlogDetail },

  { path: '/category', component: ManageCategory },
  { path: '/category/add', component: ManageCategoryAdd },
  { path: '/category/edit', component: ManageCategoryEdit },


  { path: '/ticket', component: ManageTicket },
  { path: '/ticket/edit', component: ManageTicketEdit },

  { path: '/settings/restricted-area', component: ManageRestrictedArea },
  { path: '/settings/restricted-area/add', component: ManageRestrictedAreaAdd },
  { path: '/settings/restricted-area/edit', component: ManageRestrictedAreaEdit },

  { path: '/settings/whitelist', component: ManageWhitelist },
  { path: '/settings/whitelist/add', component: ManageWhitelistAdd },
  { path: '/settings/whitelist/edit', component: ManageWhitelistEdit },


  { path: '/settings/points', component: ManagePoints },
  { path: '/settings/points/add', component: ManagePointsAdd },
  { path: '/settings/points/edit', component: ManagePointsEdit },

  { path: '/admin-operation-logs', component: ManageAdminOperationLogs },

  { path: '/bug-reported', component: ManageBugReported },
  { path: '/error-reported', component: ManageErrorReported },


  { path: '/clients/push-notification', component: ManagePushNotification },
  { path: '/clients/push-notification/send', component: ManagePushNotificationSend },


  { path: '/ads/ad-source', component: ManageAdSource },
  { path: '/ads/ad-source/add', component: ManageAdSourceAdd },
  { path: '/ads/ad-source/Edit', component: ManageAdSourceEdit },

  { path: '/ads/ad-source-code', component: ManageAdSourceCode },
  { path: '/ads/ad-source-code/add', component: ManageAdSourceCodeAdd },
  { path: '/ads/ad-source-code/Edit', component: ManageAdSourceCodeEdit },





]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
