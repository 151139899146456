<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage_admin_users}}</h2>
        <Breadcrumb :item_one_title="locale.account" :item_one_url="'/account'" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="w-50">
            <div class="mt-3 mb-3">
                <label class="form-label">{{locale.username}}:&nbsp;</label>
                <b>{{data.username}}</b>
            </div>
            
            <form autocomplete="off">
                <div class="mb-3">
                    <label class="form-label">{{locale.email}}</label>
                    <input v-model="data.email" type="text" id="email" class="form-control" placeholder="john@example.com" autocomplete="off">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.phone}}</label>
                    <input v-model="data.phone" type="text" id="phone" class="form-control" placeholder="+971 5xxxxxxxx" autocomplete="off">
                </div>
                <label class="form-label">{{locale.current}} {{locale.password}}</label>
                <div class="input-group mb-3">
                    <input v-model="data.current_password" type="password" id="current_password" class="form-control"  autocomplete="off" required="required">
                    <span class="input-group-text cursor-pointer " @click="switchVisibility('current_password')">
                        <div class="eye"></div>
                    </span>
                </div>
                <label class="form-label">{{locale.new}} {{locale.password}}</label>
                <div class="input-group mb-3">
                    <input v-model="data.new_password" type="password" id="new_password" class="form-control"  autocomplete="off">
                    <span class="input-group-text cursor-pointer " @click="switchVisibility('new_password')">
                        <div class="eye"></div>
                    </span>
                </div>
                <div class="mb-3">
                    <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                </div>  
            </form>

        </div>

    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import { sha256 } from 'js-sha256'

import {callApi,callPostApi} from "@/api/api"

export default{
    name:'Profile',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/account',
            recordId: '',
            username: '',
            current_password: '',
            new_password: '',
            email: '',
            phone: '',
            submitBtn: false,
        })

        onMounted( async() =>{
            if (!auth.value) {
                await router.push("/login")
            }
            data.username =  authUser.value.username
            data.email = authUser.value.email
            data.phone = authUser.value.phone
        })

        const submit = async() => {

            if(!data.current_password){
                notify({text: 'Current password required to make any change in your account.', type:'error'})
                return
            }
            data.submitBtn = true
            let _body = {
                current_password:  sha256(sha256(data.current_password+sha256(data.current_password+data.current_password))) ,
            }
            if(data.new_password){
                _body.new_password = sha256(sha256(data.new_password+sha256(data.new_password+data.new_password)))
            }
            if(data.email){
                _body.email = data.email
            }
            if(data.phone){
                _body.phone = data.phone
            }

            let clientIp = ''
            await fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(({ ip }) => {
                clientIp = ip
                //console.log("client ip detail in veu.js",ip)
            }).catch(error => {
                console.log("client ip err",error)
            })
            
            let resData = await callPostApi(data.endpoint+"?client_ip="+clientIp,'POST',JSON.stringify(_body))
            
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        const switchVisibility = (field_id) => {
            const passwordField = document.querySelector('#'+field_id)
            passwordField.getAttribute('type')==='password' ? passwordField.setAttribute('type','text') : passwordField.setAttribute('type','password')
        }

        return {
            data,
            locale,
            switchVisibility,
            submit,
        }
    }
}

</script>