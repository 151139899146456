<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.push_notification}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.push_notification" :item_one_url="'/clients/push-notification'" :item_two_title="locale.add" :item_two_active="true" />

        <div class="row">

            <div class="col-4">
                <div class="mb-3">
                    <label class="form-label">{{locale.title}}</label>
                    <input v-model="data.title" type="text" class="form-control" placeholder="Hello World! 15-30 characters" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.description}}</label>
                    <input  v-model="data.description" type="text" class="form-control" placeholder="SwoshsVPN best vpn |  120-140 characters">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.send}} {{locale.test}}</label>
                    <select class="form-select" v-model="data.send_test">
                        <option value="1">{{locale.yes}}</option>
                        <option value="0">{{locale.no}}</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.device}} {{locale.token}}</label>
                    <input  v-model="data.device_token" type="text" class="form-control" placeholder="740f4707 bebcf74f 9b7c25d4">
                </div>
                <label class="form-label">{{locale.select}} {{locale.clients}} <b>(iOS,Android,Web only)</b></label>
                <div class="form-check" v-for="(item,i) in data.clientsList" v-bind:key="i">
                    <input v-model="data.clientsCheckedArr" class="form-check-input" type="checkbox" :value="item.code" :id="item.id">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{item.name}}
                    </label>
                </div>
            </div>

           

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect(data.frontEndpoint)" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callPostApi, getLanguageList, getClientsList} from "@/api/api"


export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            frontEndpoint: '/clients/push-notification',
            endpoint: '/firebase/push-notification',
            title: '',
            description: '',
            device_group: 1,
            device_token: '',
            send_test: 1,
           
            clientsList: [],
            clientsCheckedArr: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            
            data.languagesList  = await getLanguageList()
            data.clientsList  = await getClientsList()
        })

        const submit = async() => {

            if(data.title.length == 0){
                notify({text: "Please add title",type:'error'})
                return false
            }

            if(data.clientsCheckedArr.length == 0){
                notify({text: "Please select client",type:'error'})
                return false
            }
            if(data.send_test==1 && data.device_token == ''){
                notify({text: "Please enter device token",type:'error'})
                return false
            }

            data.submitBtn = true
            let _body = {
                title: data.title,
                description: data.description,
                device_token: data.device_token,
                send_test: data.send_test,
                device_group: data.clientsCheckedArr.toString(),
            }

            console.log("_body ", _body)

            let resData = await callPostApi(data.endpoint,'POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push(data.frontEndpoint)
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>