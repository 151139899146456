<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage_package}}</h2>
       <Breadcrumb :item_one_title="locale.manage_package" :item_one_active="true" :loading="data.loading" />
       
       <button @click="$redirect('/package/add')" type="button" class="btn btn-secondary btn-sm">{{locale.add}}</button>

      <div class="table-responsive mt-3">
        <table class="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{locale.name}}</th>
              <th scope="col">{{locale.price}} {{locale.detail}}</th>
              <th scope="col">{{locale.plan_time}}</th>
              <th scope="col">{{locale.billed}}</th>
              <th scope="col">{{locale.status}}</th>
              <th scope="col">{{locale.allowed}} {{locale.devices}}</th>
              <th scope="col">{{locale.sorting_order}}</th>
              <th scope="col">{{locale.speed_limit}}</th>
              <th scope="col">{{locale.description}}</th>
              <th scope="col">{{locale.recommended}}</th>
              <th scope="col">{{locale.is_offer}}</th>
              <th scope="col">{{locale.is_disabled}}</th>
              <th scope="col">{{locale.action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{item.id}}</td>
                <td>{{item.title}}</td>
                <td>
                    <ol class="list-group list-group-numbered">
                      <li v-for="(rec,k) in data.planPricesArr[item.id]" v-bind:key="k" class="list-group-item d-flex justify-content-between align-items-start">
                        <div class="ms-2 me-auto">
                          <div class="fw-bold">{{locale.price}}</div>
                          <span v-if="rec.discount_price">({{locale.discount}} {{locale.price}} : {{rec.symbol}} {{rec.discount_price}})   </span>
                          <span v-if="rec.additional_device_price"><br>({{locale.additonal}} {{locale.devices}} {{locale.price}} : {{rec.symbol}} {{rec.additional_device_price}})   </span>
                        </div>
                        <span class="badge bg-secondary rounded-pill">{{rec.symbol}} {{rec.price}}  ({{rec.code}})</span>
                      </li>
                    </ol>
                </td>
                <td>{{item.plan_time}}</td>
                <td>
                  <span  v-if="item.billed == 1" class="badge bg-light text-dark  rounded-pill">{{locale.monthly}}</span>
                  <span  v-if="item.billed == 2" class="badge bg-light text-dark  rounded-pill">{{locale.yearly}}</span>
                  <span  v-if="item.billed == 3" class="badge bg-light text-dark  rounded-pill">{{locale.manually}}</span>
                  <span  v-if="item.billed == 4" class="badge bg-light text-dark  rounded-pill">{{locale.no}} - {{locale.trial}} {{locale.package}} </span>
                  <span  v-if="item.billed == 5" class="badge bg-light text-dark  rounded-pill">{{locale.no}} - {{locale.reward}} {{locale.package}} </span>
                  <span  v-if="item.billed == 6" class="badge bg-light text-dark  rounded-pill">{{locale.no}} - {{locale.offer}} {{locale.package}} </span>
                  <span  v-if="item.billed == 10" class="badge bg-light text-dark  rounded-pill">{{locale.no}} - {{locale.guest}} {{locale.package}} </span>
                  <span  v-if="item.billed == 11" class="badge bg-light text-dark  rounded-pill">{{locale.no}} - {{locale.redeem}} {{locale.package}} </span>
                </td>
                <td> <span class="badge bg-light text-dark  rounded-pill">{{item.status == 1 ? locale.active : locale.in_active}}</span></td>
                <td>{{item.allowed_device_number}}</td>
                <td>{{item.sorting_order}}</td>
                <td><span class="badge bg-light text-dark  rounded-pill">{{item.speed_limit}}</span></td>
                <td>{{item.description}}</td>
                <td> <span class="badge bg-light text-dark  rounded-pill">{{item.is_recommended == 1 ? locale.yes : locale.no}}</span></td>
                <td> <span class="badge bg-success  rounded-pill">{{item.is_offer == 1 ? locale.yes : locale.no}}</span></td>
                <td> <span class="badge bg-success  rounded-pill">{{item.is_disabled == 1 ? locale.yes : locale.no}}</span></td>
                <td>
                   <button @click="$redirect('/package/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm my-1 mx-1">{{locale.edit}}</button>
                   <button @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm my-1 mx-1">{{locale.delete}}</button>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store';
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi} from "@/api/api"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'Package',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/systemplan',
            records: [],
            planPricesArr: [],
            loading: false,
        })

        onMounted( async() =>{
            if(!auth.value)
              router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_package', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')


            getRecords()
        })

        const getRecords = async() => {
            data.loading = true
            let resData = await callApi(data.endpoint, 'GET')
            data.records = resData.data
            data.planPricesArr = resData.planPricesArr
            data.loading = false
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
            }else{
                notify({text: resData.message, type:'error'})
            }
          }
        }

        return {
            data,
            locale,
            deleteRecord,
        }
    }
}

</script>