<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <h2>{{ locale.manage }} {{locale.gift_codes }}</h2>
    <Breadcrumb
      :item_one_title="locale.manage + ' ' + locale.gift_codes"
      :item_one_active="true"
      :loading="data.loading"
    />
    
    <button
      @click="showPopup()"
      type="button"
      class="btn btn-secondary mb-3 ms-3 btn-sm"
    >
      {{ locale.generate }} {{ locale.gift_codes }}
    </button>

    <button
      @click="update()"
      type="button"
      class="btn btn-warning mb-3 ms-3 btn-sm"
    >
      {{ locale.cancel }} {{ locale.gift_codes }}
    </button>
    
 <button @click="showFilters" class="btn btn-outline-secondary mb-3 ms-3 btn-sm" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>
    <button v-if="data.selected.length>0" class=" btn btn-primary-custom ms-3 mb-3 ">
    <vue-excel-xlsx
        :data="data.selected"
        :columns="data.columns"
        :file-name="'gift_codes_list'"
        :file-type="'xlsx'"
        :sheet-name="'Gift card codes list'"
        >
        {{locale.download_excel}}
    </vue-excel-xlsx>
    </button>
    <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-3">
                    <label class="form-label">{{locale.card_code}}</label>
                    <input v-model="data.filters.card_code" type="text" class="form-control" placeholder="by Card code"  aria-describedby="button-addon2">
                </div>
                  <div class="col-3">
                    <label class="form-label">{{locale.serial_number}}</label>
                    <input v-model="data.filters.serial_number" type="text" class="form-control" placeholder="by Card Serial Number"  aria-describedby="button-addon2">
                </div>
                <div class="col-2">
                     <label class="form-label">{{locale.batch_name}} </label>
                    <select class="form-select" v-model="data.filters.batch">
                        <option  v-for="batch in data.batch"
                          :key="batch"
                          :value="batch.id">{{batch.batch_name}}</option>
                    </select>
                </div>
               
               
            </div>
            
            <button @click="searchRecords" class="btn btn-secondary my-3 " type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-3 mx-2" type="button" id="button-addon2">{{locale.reset}}</button>
            <hr>

        </div>
         

    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col"><label class="form-checkbox">
    <input type="checkbox" v-model="data.selectAll"  @change="updateAllrecord()">
    <i class="form-icon"></i>
  </label> </th>
            <th scope="col">#</th>
            <th scope="col">{{ locale.batch_name }}</th>
            <th scope="col">{{ locale.plan }}</th>
            <th scope="col">{{ locale.sr_no }}</th>
            <th scope="col">{{ locale.card_code }}</th>
            <th scope="col">{{ locale.redeem_status }}</th>
            <th scope="col">{{ locale.used_time }}</th>
            <th scope="col">{{ locale.use_by }}</th>
            <th scope="col">{{ locale.expired }}</th>
            

            <th scope="col">{{ locale.action }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in data.records" v-bind:key="i">
            <td><label class="form-checkbox">
    <input type="checkbox" v-model="data.selected" :value="{id:item.id, batch_name:item.batch_name, sr_no:item.card_serial_number, plan: item.plan_name, card_code:item.card_code, redeem_status: item.status==0 ? 'No' : item.status==1 ? 'Yes' : 'Cancel', used_time:item.use_time, use_by:item.email }">
    <i class="form-icon"></i>
  </label></td>
            <td>{{ item.id }}</td>
            <td>{{ item.batch_name }}</td>
            <td>{{ item.plan_name	 }}</td>
            <td>{{ item.card_serial_number}}</td>
            <td>{{ item.card_code }}</td>
            <td v-if="item.status!=2"><span class="badge bg-light text-dark  rounded-pill">{{item.status == 1 ? locale.yes : locale.no}}</span></td>
            <td v-if="item.status == 2 "><span class="badge bg-light text-dark  rounded-pill">{{ locale.cancel}}</span></td>
            
             <td >
               <p v-if="item.use_time!=null">
                  {{ $moment(item.use_time).format("YYYY-MM-DD HH:mm:ss") }}
               </p>
              </td>
              <td>{{ item.email}}</td>
             <td >
                <p v-if="item.expired_at!=null">
                  {{ $moment(item.expired_at).format("YYYY-MM-DD HH:mm:ss") }}
                </p>
             </td>
            
             
           

            

            <td>
              <!-- <button @click="$redirect('/servers/list/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm">{{locale.edit}}</button> -->
              <a
              v-if="item.status == 0"
                @click="updateSinglerecord(item.id)"
                class="btn btn-warning btn-sm"
                >{{ locale.cancel }}</a
              >
           
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex mb-5">
        <div class="col-10">
          <v-pagination
            v-model="data.page"
            :pages="data.totalPages"
            :range-size="2"
            active-color="#d2f4ea"
            @update:modelValue="getRecords"
          />
        </div>
        <div class="col-2">
          <select
            @change="getRecords()"
            class="form-select"
            v-model="data.perPage"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>
      </div>
      

      <!---- showing form popup -->
      <div
        class="modal fade"
        :class="showForm ? 'show' : ''"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :style="showForm ? 'display:block' : ''"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-primary text-white">
              {{ locale.generate }} {{ locale.gift_codes }}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="showForm = false"
              ></button>
            </div>
            <div class="modal-body">
              <h4 class="text-center">
                
              </h4>
              <div
                class="d-flex justify-content-center align-items-center mt-3"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">{{ locale.batch }}</label>
                      <select class="form-select" v-model="data.batchId" @change="setbatchVal($event.target.value)">
                        <option
                          v-for="(batch ,i) in data.batch"
                          v-bind:key="i"
                          :value="batch.id"
                        >
                          {{ batch.batch_name }}
                        </option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">{{ locale.plan }}</label>
                      <select class="form-select" v-model="data.planId" @change="setplanVal($event.target.value)" >
                        <option
                        id="PlanType"
                          v-for="(plans,i) in data.plans"
                          v-bind:key="i"
                          :value="plans.id"
                        >
                          {{ plans.title }}
                        </option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <label class="form-label">{{ locale.count }}</label>
                      <input
                        v-model="data.count"
                        type="number"
                        class="form-control"
                        placeholder=""
                        required
                      />
                    </div>

                    <div class="mb-3">
                      <div class="form-group">
                        <label for="exampleFormControlTextarea1">{{
                          locale.purpose
                        }}</label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          v-model="data.purpose"
                        ></textarea>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="form-group">
                      <label class="form-label"
                        >{{ locale.expired }} {{ locale.at }}</label
                      >
                      <br />
                      <input
                        type="date"
                        name="expired_at"
                        class="form-control"
                        v-model="data.expired_at"
                        width="276"
                      />
                    </div>
                    </div>
                  </div>

                  <div
                    class="
                      mb-3
                      mt-5
                      mx-auto
                      text-center
                      justify-content-center
                      align-items-center
                    "
                  >
                    <button
                      @click="generateCode()"
                      type="button"
                      class="btn btn-warning btn-sm"
                    >
                      {{ locale.demo }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---- showing Preview popup -->
      <div
        class="modal fade"
        :class="showPreview ? 'show' : ''"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        :style="showPreview ? 'display:block' : ''"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-primary text-white">
              {{ locale.generate }} {{ locale.gift_codes }}  {{ locale.demo }} 
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="showPreview = false"
              ></button>
            </div>
            <div class="modal-body">
              <h4 class="text-center">
                
              </h4>
              <div
                class="d-flex justify-content-center align-items-center mt-3"
              >
                <div class="row">
                  <div class="col-12">
                    <ol class="list-group list-group-numbered">
                      <li class="list-group-item list-group-item-success"  v-for="codes in data.generatedCodes" :key="codes">
                        {{ codes }}
                      </li>
                    </ol>
                  </div>

                  <div
                    class="
                      mb-3
                      mt-5
                      mx-auto
                      text-center
                      justify-content-center
                      align-items-center
                    "
                  >
                    <button
                      @click="submit"
                      type="button"
                      class="btn btn-primary btn-sm"
                    >
                      {{ locale.generate }}
                    </button>
                    <button
                      @click="showPreview = false"
                      type="button"
                      class="btn btn-secondary btn-sm ms-1"
                    >
                      {{ locale.go_back }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import { onMounted, reactive, computed, ref } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import {
  callApi,
  callPostApi,
  getServersAreasList,
  getSystemPlansList,
  getBatchList,
} from "@/api/api";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { checkAccessSinglePage } from "@/utils/utils";
//import { json } from 'body-parser';

export default {
  name: "Managebatch",
  components: {
    Breadcrumb,
    VPagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = computed(() => store.state.authenticated);
    const authUser = computed(() => store.state.authUser);
    const locale = computed(() => store.state.translation.locale);
    let showForm = ref(false);
    let showPreview = ref(false);
    const data = reactive({
      endpoint: "/gift-card",
      generatecodeendpoint: "/generate-gift-code",
      UpdateEndpoint: "/gift-card/",
      page: 1,
      perPage: 10,
      totalPages: 0,
      totalRecords: 0,
      keywords: "",
      records: [],
      loading: false,
      purpose: "",
      count: 0,
      expired_at: '',
      serial_number: 0,
      batch: [],
      plans: [],
      batchId: null,
      planId: null,
      generatedCodes: [],
      selected: [],
      selectAll:false,
      SingleSelected:[],
      columns : [
                    {
                        label: "Batch Name",
                        field: "batch_name",
                        
                    },
                    {
                        label: "SR.NO",
                        field: "sr_no",
                    },
                    {
                        label: "Card_code",
                        field: "card_code",
                    },
                   
                    {
                        label: "Plan",
                        field: "plan",
                    },
                    
                     
                   
                    {
                        label: "Redeem Status",
                        field: "redeem_status",
                    },
                     {
                        label: "Used Time",
                        field: "used_time",
                    }
                ],

      showFilter: false,
      filters: {
        card_code: "",
        batch_listing: "",
        serial_number: "",
       
      },

      serversAreasList: [],
    });

    onMounted(async () => {
      if (!auth.value) router.push(`/login`)

      let isAllowAccessPage = await checkAccessSinglePage(
        "manage_gift_code_builder",
        authUser.value
      );
      if (!isAllowAccessPage) await router.push("/");

      getRecords()

      data.plans = await getSystemPlansList();
      data.batch = await getBatchList();
      
    })

    const getRecords = async () => {
      data.loading = true;

      let query_filter = new URLSearchParams(data.filters).toString();
      console.log("query_filter", query_filter);
      let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
      console.log("resData, ", resData);

        if (resData.isSuccess) {
           data.records = resData.data;
              data.totalRecords = resData.totalRecords;
              data.totalPages = Math.ceil(resData.totalRecords / data.perPage);
              
        }else{
             notify({ text: resData.message, type: "error" })
        }
        data.loading = false;
    }

    const submit = async () => {
      data.submitBtn = true;
      let _body = {
        batch: data.batchId,
        plan_id: data.planId,
        card_code: data.generatedCodes,
        purpose: data.purpose,
        count: data.count,
        //serial_number: data.serial_number,
        expired_at: data.expired_at || null,
      };

      let resData = await callPostApi(
        data.endpoint,
        "POST",
        JSON.stringify(_body)
      );
      data.submitBtn = false;
      if (resData.isSuccess) {
        notify({ text: resData.message, type: "success" });
        showPreview.value = false;
        showForm.value = false;
        data.planId= null
        data.batchId= null
        data.count = 0
        data.purpose= ''
        data.expired_at= ''
        // window.location.reload()
        getRecords();
      } else {
        notify({ text: resData.message, type: "error" });
      }
    }

    const showPopup = () => {

      //Do not generate using these plans(trial/OFFER/REWARD) becaue these gift codes will not work in swoshsvpn app.
      let exlucdePlanBilled = [4,5,6]
      data.plans = data.plans.filter(plan=>{
        return !exlucdePlanBilled.includes(plan.billed)
      })
      showForm.value = true
    }

    const deleteRecord = async (id) => {
      if (confirm(locale.value.confirm_delete)) {
        data.loading = true;
        let resData = await callApi(data.endpoint + "/" + id, "DELETE");
        data.loading = false;
        if (resData.isSuccess) {
          notify({ text: resData.message, type: "success" });
          getRecords();
        } else {
          notify({ text: resData.message, type: "error" });
        }
      }
    }

    const searchRecords = async () => {
      data.page = 1;
      getRecords();
    }

    const showFilters = async () => {
      data.showFilter = data.showFilter ? false : true
    }

    const resetFilters = async () => {
      data.filters = {
        card_code: "",
        batch_listing: "",
        serial_number: "",
        
      }
      searchRecords()
    }

    const generateCode = async () => {

      if(data.batchId===null){
          notify({ text: "Please select batch", type: "error" })
      }
      else if(data.planId===null){
          notify({ text: "Please select plan", type: "error" })
      }
      else if(data.count===0){
          notify({ text: "Please enter Count", type: "error" })
      }
      else if(data.count<0){
          notify({ text: "Count should be Positive integer", type: "error" })
      }
      else{
          let _body = {
            count: data.count,
            plan_id: data.planId,
          }
          let resData = await callPostApi(
            data.generatecodeendpoint,
            "POST",
            JSON.stringify(_body)
          )
          
          if(resData.isSuccess){
              data.generatedCodes = resData.codesArr
              showPreview.value = true
          }else{
                notify({text: resData.message, type:'error'})
          }
      }
    }

    const update = async() => {
        if(data.selected==""){
            notify({ text: "Please select data", type: "error" })
        }
        else{
            let idArr=[]
            for (const iterator of data.selected) {
                idArr.push(iterator.id)
            }
            if(confirm(locale.value.confirm_delete)){
              let _body={
                recId: idArr
              }
              data.submitBtn = true
              let resData = await callPostApi(data.endpoint,'PATCH' ,JSON.stringify(_body))
              data.submitBtn = false
              if(resData.isSuccess){
                  notify({text: resData.message, type:'success'})
                  router.push('/gift-code-builder/gift-code')
                  data.selected=[]
                  data.selectAll= false
                  getRecords()
                  
              }else{
                  notify({text: resData.message, type:'error'})
              }
            }
        }
    }

    const updateSinglerecord = async (id) =>{
        if(confirm(locale.value.confirm_delete)){
            data.SingleSelected.push(id)
            let _body={
              recId: data.SingleSelected
            }
            data.submitBtn = true
        

            let resData = await callPostApi(data.endpoint,'PATCH' ,JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/gift-code-builder/gift-code')
                getRecords()
                
            }else{
                notify({text: resData.message, type:'error'})
            }
        }
    }

    const updateAllrecord = () =>{
        let statusArr=[]
        if (data.selectAll) {
              const selected = data.records.map(u => ({ id: u.id, batch_name: u.batch_name, sr_no: u.card_serial_number, plan: u.plan_name, card_code:u.card_code,
              redeem_status: u.status==0 ? 'No' : u.status==1 ? 'Yes' : 'Cancel', 
              used_time:u.use_time, use_by:u.email }));
              data.selected = selected;
        } else {
              data.selected = [];
        }
    }

    const setbatchVal = (id) =>{
      data.batchId= id
      console.log(data.batchId)
    }

    const setplanVal = (id) =>{
      data.planId= id
      console.log(data.planId)
    }

    return {
      data,
      locale,
      deleteRecord,
      getRecords,
      searchRecords,
      showFilters,
      resetFilters,
      showForm,
      showPopup,
      showPreview,
      generateCode,
      submit,
      update,
      updateAllrecord,
      updateSinglerecord,
      setplanVal,
      setbatchVal

      
    };
  },
};
</script>
<style scoped>
.btn-primary-custom button{
  display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;

}
</style>