<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.ad_source_code}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.ad_source_code" :item_one_url="'/ads/ad-source-code'" :item_two_title="locale.add" :item_two_active="true" />

        <div class="row">

            <div class="col-6">
                <div class="mb-3">
                    <label class="form-label">{{locale.ad_source}}</label>
                    <select class="form-select" v-model="data.ad_sourceID" @change="selectSourceID($event.target.value)">
                        <option v-for="data in data.AdsourceList" :key="data" :value="data.id">{{data.name}}</option>
                      
                    </select>
                </div>
                <div class="mb-3">
                   <div class="form-group">
                    <label for="exampleFormControlTextarea1">{{locale.ad_code}} </label>
                        <textarea class="form-control" id="exampleFormControlTextarea1"  v-model="data.ad_source_code" rows="3"></textarea>
                         </div>
                </div>

                 <div class="mb-3">
                    <label class="form-label">{{locale.restricted}} {{locale.country_name}}</label>
                     <input v-model="data.restricted_countries" type="text" class="form-control" placeholder="Burma, Ivory Coast, Cuba, Democratic Republic of the Congo, Iran, Iraq, Liberia, North Korea, Syria, and Zimbabwe" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.sorting_order}}</label>
                    <input v-model="data.sorting_order" type="text" class="form-control" placeholder="1" required>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="2">{{locale.in_active}}</option>
                    </select>
                </div>

                <label class="form-label"> {{locale.clients}}</label>
                <div class="form-check" v-for="(item,i) in data.clientsList" v-bind:key="i">
                    <input v-model="data.clientsCheckedArr" class="form-check-input" type="checkbox" :value="item.id" :id="item.id">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{item.name}}
                    </label>
                </div>
                <!-- <div class="mb-3">
                    <label class="form-label">{{locale.ad_display}}</label>
                    <select class="form-select" v-model="data.ad_display">
                        <option value="0">{{locale.home_screen}}</option>
                        <option value="1">{{locale.splash_screen}}</option>
                    </select>
                </div> -->
            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/ads/ad-source-code')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callPostApi, getClientsList, getCountriesList,getAdsourceList,callApi} from "@/api/api"
//import {validURL} from '@/utils/utils'

export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)
        const route = useRoute()

        const data = reactive({
            endpoint:'/ad-source-code/',
            recordId:'',
           
            ad_source: '',
            ad_sourceID:0,
            restricted_countries:'',
            sorting_order:'',
            ad_source_code: '',
            status: 1,
            ad_display: 0,
            countries: [],
            clientsList: [],
            clientsCheckedArr: [],
            AdsourceList:[],
            AdsourceCodeList:[],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
                  data.recordId=route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
           // data.countries  = await getCountriesList()
            //data.channelsList  = await getChannelsList()
            data.clientsList  = await getClientsList()
            data.AdsourceList= await getAdsourceList()
            //data.AdsourceCodeList= await getAdsourceCodeList()
           
            data.AdsourceCodeList = resData.data
            data.countryID= resData.data.country_id
            data.ad_source_code= resData.data.ad_code
            data.ad_sourceID= resData.data.ad_source_id

            data.clientsCheckedArr = resData.data.client_id.split(",")

            // for (const iterator of resData.data.client_id.split(",")) {
            //     if(iterator!=''){
            //         data.clientsCheckedArr.push(iterator)
            //       }    
            // }
            data.status= resData.data.status
            data.ad_display= resData.data.ad_display

            data.restricted_countries = resData.data.restricted_countries
            data.sorting_order = resData.data.sorting_order

        })

        const submit = async() => {
            if(data.ad_sourceID==0){
                notify({text:"Please Select Ad source", type:'error'})
            }
            else if(data.clientsCheckedArr.length<=0){
                notify({text:"Please Select client", type:'error'})
            }
           else{

                data.submitBtn = true
                let _body = {
                    id: data.recordId,
                    ad_source_id: data.ad_sourceID,
                    ad_source_code: data.ad_source_code,
                    status: data.status,
                    country_id: data.countryID,
                    clientIds: data.clientsCheckedArr,
                    restricted_countries: data.restricted_countries,
                    sorting_order: data.sorting_order,
                }

                let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
                data.submitBtn = false
                if(resData.isSuccess){
                    notify({text: resData.message, type:'success'})
                    router.push('/ads/ad-source-code')
                }else{
                    notify({text: resData.message, type:'error'})
                }
            }
        }
       const selectSourceID =(id)=>{
           data.ad_sourceID=id
       }
        const selectCountryID =(id)=>{
           data.countryID=id
       }
        return {
            data,
            locale,
            submit,
            selectSourceID,
            selectCountryID

        }
    }
}

</script>