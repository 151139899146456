<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.whitelist}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.whitelist" :item_one_url="data.frontEndpoint" :item_two_title="locale.add" :item_two_active="true" />
        
        <form>

        <div class="row">

            <div class="col-6">


                <div class="mb-3">
                    <label class="form-label">{{locale.ip}}</label>
                    <input v-model="data.ip" type="text" class="form-control" placeholder="" required>
                </div>
               
            </div>
              <div class="col-6">


                <div class="mb-3">
                    <label class="form-label">{{locale.email}}</label>
                    <input v-model="data.email" type="text" class="form-control" placeholder="example@mail.com" required>
                </div>
               
            </div>


            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect(data.frontEndpoint)" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>

        </form>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import { callPostApi } from "@/api/api"


export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {

        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const locale = computed(() => store.state.translation.locale)
    
        const data = reactive({
            frontEndpoint: '/settings/whitelist',
            endpoint: '/whitelist',
           
            ip : '',
            email:'',
           
            submitBtn: false,
        })

        
        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
        })

        const submit = async() => {

           
            if(data.ip == ''){
                notify({text: 'Please enter IP Address', type:'error'})
                return false
            }
             if(data.email == ''){
                notify({text: 'Please enter Email Address', type:'error'})
                return false
            }
           
            data.submitBtn = true
            let _body = {
                ip: data.ip,
                email: data.email
            }
           
            let resData = await callPostApi(data.endpoint,'POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push(data.frontEndpoint)
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit
        }
    }
}

</script>