<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.tutorial}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.tutorial" :item_one_url="data.frontEndpoint" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="row">

            
            <div class="col-6">

                <div class="mb-3">
                    <label class="form-label">{{locale.language}}</label>
                    <select class="form-select" v-model="data.lang_code">
                        <option v-for="(item,i) in data.languagesList" v-bind:key="i"  :value="item.lang_code">{{item.name}}</option>
                    </select>
                </div>

               <div class="mb-3">
                    <label class="form-label">{{locale.client}}</label>
                    <select class="form-select" v-model="data.client_id">
                        <option v-for="(item,i) in data.clientsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.title}}</label>
                    <input v-model="data.title" type="text" class="form-control" placeholder="What is VPN?" required>
                </div>
            </div>

            <div class="col-4">
               
               <div class="mb-3">
                    <label class="form-label">{{locale.sorting_order}}</label>
                    <input v-model="data.sorting_order" type="number" class="form-control" placeholder="1" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="0">{{locale.in_active}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.meta}} {{locale.title}}</label>
                    <input v-model="data.meta_title" type="text" class="form-control" placeholder="How to install SwoshsVPN?" required>
                </div>

                 <div class="mb-3">
                    <label class="form-label">{{locale.meta}} {{locale.description}}</label>
                    <textarea v-model="data.meta_description" class="form-control" placeholder="A virtual router can also be used to extend the range of a weak wifi signal."></textarea>
                </div>
                
            </div>

            <div class="col-10">
                <div class="mb-3">
                    <label class="form-label">{{locale.description}}</label>
                    <ckeditor :editor="editor" :config="editorConfig" v-model="data.description" ></ckeditor>
                </div>
            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect(data.frontEndpoint)" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed, ref} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {apiUploadUrl} from '@/config/config'
import {callApi, callPostApi, getLanguageList, uploadFile, getClientsList} from "@/api/api"
import { generateSlug } from "@/utils/utils"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        const file = ref(null)
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            frontEndpoint: '/tutorial',
            endpoint: '/tutorial/',
            recordId: '',
            client_id: '',
            lang_code : 'EN',
            status: 1,
            sorting_order: 1,
            title : '',
            description: '',
            meta_title: '',
            meta_description: '',

            languagesList: [],
            clientsList: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.recordId = route.query.id
            console.log("recordId", data.recordId)
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)
            
            console.log("resData.data",resData.data)

            let record = resData.data
            data.recordId = record.id
            data.client_id = record.client_id
            data.lang_code = record.lang_code
            data.status = record.status
            data.sorting_order = record.sorting_order
            data.lang_code = record.lang_code
            data.title = record.title
            data.description = record.description
            data.meta_title = record.meta_title
            data.meta_description = record.meta_description

            data.languagesList  = await getLanguageList()
            data.clientsList  = await getClientsList()

        })

        const submit = async() => {

            if(data.lang_code == ''){
                notify({text: 'Please select language', type:'error'})
                return false
            }
            if(data.client_id == ''){
                notify({text: 'Please select client', type:'error'})
                return false
            }
            if(data.title == ''){
                notify({text: 'Please enter title', type:'error'})
                return false
            }
            if(data.description == ''){
                notify({text: 'Please enter description', type:'error'})
                return false
            }

            data.submitBtn = true
            let _body = {
                recordId: data.recordId,
                client_id: data.client_id,
                lang_code: data.lang_code,
                status: data.status,
                sorting_order: data.sorting_order,
                title: data.title,
                description: data.description,
                meta_title: data.meta_title,
                meta_description: data.meta_description,
            }

            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push(data.frontEndpoint)
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        const handleFileUpload = async() => {
            //debugger
            data.submitBtn = true
            try{
                console.log("selected file",file.value.files[0])
                const file_upload = file.value.files[0]
                const pattern = /image-*/
                if (file_upload && !file_upload.type.match(pattern)) {
                    notify({text: "Invalid image", type:'error'})
                    data.submitBtn = false
                    return
                }else{
                    const formData = new FormData()
                    formData.append('folder_name', 'blog')
                    formData.append('upload', file_upload)
                    let resData = await uploadFile(formData)
                    console.log('upload image response',resData)
                    console.log(resData.file_url)
                    data.image_url = resData.file_url
                    data.submitBtn = false
                }
            }catch(err){
                data.submitBtn = false
                console.log("image upload error",err)
            }
        }


        return {
            editor: ClassicEditor,
            editorData: '<p>Content of the blog.</p>',
            editorConfig: {
                ckfinder: {
                    uploadUrl: apiUploadUrl
                },
                // toolbar: {
                //     items: [
                //         'heading',
                //         'bold',
                //         'italic',
                //         'numberedList',
                //         'bulletedList',
                //         'link',
                //         'undo',
                //         'redo',
                //     ]
                // }
            },
            data,
            locale,
            submit,
            handleFileUpload,
            file
        }
    }
}

</script>