

import {onMounted, reactive, computed} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi } from "@/api/api"


export default{
    name:'Home',
    components: {
      Breadcrumb,
    },
    setup() {

        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        //const langs = computed(() => store.state.translation.langs);
        const locale = computed(() => store.state.translation.locale)


        const data = reactive({
            endpoint: '/dashboard',
            records: [],
            loading: false,
        })

        onMounted( async() =>{
            if(!auth.value){
                await router.push('/login')
            }
            data.loading = true
            //let lang = authUser.value.preferred_language ? authUser.value.preferred_language : "en";
            //store.dispatch(`translation/getLangs`, { userLang: lang });
            let resData = await callApi(data.endpoint, 'GET')
            data.records = resData.data
            data.loading = false
        })

        return {
            auth,
            data,
            locale,
            authUser
        }
    }
}
