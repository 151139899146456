<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.batch}}</h2>
       <Breadcrumb :item_one_title="locale.manage +' '+ locale.batch" :item_one_active="true" :loading="data.loading" />
       <button @click="$redirect('/gift-code-builder/batch/add')" type="button" class="btn btn-secondary mb-3 ms-3 btn-sm">{{locale.add}}</button>

        
       <button @click="getRecords()" type="button" class="btn btn-success mb-3 ms-3 btn-sm">{{locale.refresh}}</button>


      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{locale.name}}</th>
              <th scope="col">{{locale.count}}</th>
               <th scope="col">{{locale.plan}}</th>
              <!-- <th scope="col">{{locale.purpose}}</th> -->
              <th scope="col">{{locale.date_created }}</th>
             
              <th scope="col">{{locale.generated_by}}</th>
              
              <th scope="col">{{locale.action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{item.id}}</td>
                <td>{{item.batch_name}}</td>
                <td>{{item.codes_count}}</td>
                <td>{{item.plan_name}}</td>
                <!-- <td>{{item.purpose}}</td> -->
             
                <td>{{item.created_at}}</td>
                
                <td>{{item.username}}</td>
                
                <td>
                   <!-- <button @click="$redirect('/servers/list/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm">{{locale.edit}}</button> -->
                   <a :href="'/gift-code-builder/batch/Edit/?id='+item.id" @click="$redirect('/gift-code-builder/batch/Edit/?id='+item.id)" class="btn btn-warning btn-sm">{{locale.edit}}</a>
                   <!-- <button @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm ms-1">{{locale.delete}}</button> -->
                </td>
            </tr>
          </tbody>
        </table>

          <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
               
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
          </div>

      </div>
    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store';
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi,callPostApi, getServersAreasList ,getSystemPlansList} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'Managebatch',
    components: {
      Breadcrumb,
      VPagination,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/batch',
            page : 1,
            perPage : 10,
            totalPages : 0,
            totalRecords : 0,
            keywords : '',
            records: [],
            loading: false,

            showFilter: false,
            filters:{
                keywords : '',
                server_area_id: '',
                allowed_for_free: '',
                status: '',
            },



            serversAreasList: [],

        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            let isAllowAccessPage = await checkAccessSinglePage('manage_gift_code_builder', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()

            data.serversAreasList   = await getServersAreasList()

        })

        const getRecords = async() => {
            data.loading = true
            let query_filter = new URLSearchParams(data.filters).toString()
            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
           if(resData.isSuccess){
                data.records = resData.data
                data.totalRecords = resData.totalRecords
                data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
                data.loading = false
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
            data.loading = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
            }else{
                notify({text: resData.message, type:'error'})
            }
          }
        }


        const searchRecords = async() => {
            data.page = 1
            getRecords()
        }
        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }
        const resetFilters = async() => {
            data.filters = {
                keywords : '',
                server_area_id: '',
                allowed_for_free: '',
                status: '',
            }
            searchRecords()
        }

        return {
            data,
            locale,
            deleteRecord,
            getRecords,
            searchRecords,
            showFilters,
            resetFilters,
        }
    }
}

</script>