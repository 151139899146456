<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.reports}} {{locale.registered}} {{locale.users}}</h2>
       <Breadcrumb :item_one_title="locale.manage+' '+locale.reports" :item_one_active="true" :loading="data.loading" />
       
        <div class="row mt-3 mb-3">
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-primary text-white h5">
                       {{data.date_title}}  {{locale.registered}} {{locale.users}} 
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.totalRecords}}</h5>
                    </div>
                </div>
            </div>
        </div>

        <button @click="showFilters" class="btn btn-outline-secondary mb-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>

        <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-2">
                    <label class="form-label">{{locale.date}}</label>
                    <select @change="changeDateTitle()" class="form-select" v-model="data.filters.date">
                        <option value="1">{{locale.today}}</option>
                        <option value="8">{{locale.yesterday}}</option>
                        <option value="2">{{locale.last_7_days}}</option>
                        <option value="3">{{locale.last_30_days}}</option>
                        <option value="4">{{locale.last_90_days}}</option>
                        <option value="5">{{locale.last_365_days}}</option>
                        <option value="6">{{locale.last_2_years_days}}</option>
                        <option value="7">{{locale.last_3_years_days}}</option>
                        <option value="9">{{locale.custom_date_filter}}</option>
                    </select>
                </div>

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.start}} {{locale.date}}</label>
                    <input v-model="data.filters.start_date" type="date" class="form-control" placeholder="12/18/2020"  aria-describedby="button-addon2">
                </div>

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.end}} {{locale.date}}</label>
                    <input v-model="data.filters.end_date" type="date" class="form-control" placeholder="12/18/2021"  aria-describedby="button-addon2">
                </div>
                

                <div class="col-3">
                    <label class="form-label">{{locale.search}}</label>
                    <input v-model="data.filters.keywords" type="text" class="form-control" placeholder="by id, username, email"  aria-describedby="button-addon2">
                </div>
                <div class="col-2">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.filters.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="0">{{locale.in_active}}</option>
                    </select>
                </div>
                 <div class="col-2">
                    <label class="form-label">{{locale.clients}}</label>
                    <select class="form-select" v-model="data.filters.client_id">
                        <option  v-for="(item,i) in data.clientsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-2">
                    <label class="form-label">{{locale.channels}}</label>
                    <select class="form-select" v-model="data.filters.channel_id">
                        <option  v-for="(item,i) in data.channelsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>
            </div>
            
            <button @click="searchRecords" class="btn btn-secondary my-3 " type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-3 mx-2" type="button" id="button-addon2">{{locale.reset}}</button>
            <hr>

        </div>
        

        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{locale.email}}</th>
                    <th scope="col">{{locale.status}}</th>
                    <th scope="col">{{locale.date_created}}</th>
                    <th scope="col">{{locale.extra}}</th>
                    <th scope="col">{{locale.action}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,i) in data.records" v-bind:key="i" :class="item.is_deleted ? 'bg-danger': ''">
                    <td >{{item.id}}</td>
                    <td>{{item.email || item.username}}</td>
                    <td>{{item.status == 1 ? locale.active : locale.in_active }}</td>
                    <td>{{ $formatDate(item.createdAt) }}</td>
                    <td>
                        {{locale.package}}: {{item.user_plan_name}}<br>
                        {{locale.email}} {{locale.confirmed}}: {{item.is_email_verified == 1 ? locale.yes : locale.no }} <br>
                        {{locale.cerification}} {{locale.code}}: {{item.verification_code}}<br>
                        {{locale.login}} {{locale.code}}: {{item.login_code}}<br>
                        {{locale.user}} {{locale.level}}: {{item.user_level}}<br>
                        {{locale.clients}}: {{item.client_name}}<br>
                        {{locale.channels}}: {{item.channel_name}}<br>
                        {{locale.language}}: {{item.preferred_language}} <br>
                        {{locale.invite}} {{locale.code}}:  {{item.invite_code}}<br>
                        {{locale.referrer}} {{locale.id}}:  {{item.referral_id}}<br>
                    </td>
                    <td>
                        <button v-if="item.id !==1 && item.is_deleted==0" @click="$redirect('/users/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm my-1 mx-1">{{locale.edit}}</button>
                        <button v-if="item.id !==1 && item.is_deleted==0" @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm my-1 mx-1">{{locale.delete}}</button>
                        <button v-if="item.id !==1 && item.is_deleted==0" @click="showModals(item.id, item.username, item.user_plan_id)" type="button" class="btn btn-info btn-sm my-1 mx-1">{{locale.add}} {{locale.package}}</button>
                        <button v-if="item.id !==1 && item.is_deleted==0" @click="$redirect('/users/packages/?id='+item.id)"  type="button" class="btn btn-dark btn-sm my-1 mx-1">{{locale.manage_package}}</button>
                        <button v-if="item.id !==1 && item.is_deleted==0" @click="$redirect('/users/devices/?id='+item.id)"  type="button" class="btn btn-primary btn-sm my-1 mx-1">{{locale.manage}} {{locale.devices}}</button>
                    </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div v-if="data.showModal" style="display: block;" class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                <div class="modal-header bg-primary text-white">
                    <h5 class="modal-title" id="exampleModalLabel">{{locale.add}} {{locale.package}}</h5>
                    <button @click="data.showModal=false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <label class="form-label">{{locale.username}} *</label>
                            <input type="text" class="form-control" v-model="data.add_package.username" disabled>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{locale.package}} *</label>
                            <select class="form-select" v-model="data.add_package.plan_id">
                                <option  v-for="(item,i) in data.plansList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="message-text" class="form-label">{{locale.remark}}</label>
                            <textarea class="form-control" v-model="data.add_package.remark"></textarea>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{locale.order}} {{locale.number}}</label>
                            <input type="text" class="form-control" v-model="data.add_package.order_number">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{locale.allowed}} {{locale.devices}} *</label>
                            <select class="form-select" v-model="data.add_package.total_allowed_devices">
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
									<option value="7">7</option>
									<option value="8">8</option>
									<option value="9">9</option>
									<option value="10">10</option>
									<option value="11">11</option>
									<option value="12">12</option>
									<option value="13">13</option>
									<option value="14">14</option>
									<option value="15">15</option>
									<option value="16">16</option>
									<option value="17">17</option>
									<option value="18">18</option>
									<option value="19">19</option>
									<option value="20">20</option>
									<option value="21">21</option>
									<option value="22">22</option>
									<option value="23">23</option>
									<option value="24">24</option>
									<option value="25">25</option>
									<option value="26">26</option>
									<option value="27">27</option>
									<option value="28">28</option>
									<option value="29">29</option>
									<option value="30">30</option>
									<option value="31">31</option>
									<option value="32">32</option>
									<option value="33">33</option>
									<option value="34">34</option>
									<option value="35">35</option>
									<option value="36">36</option>
									<option value="37">37</option>
									<option value="38">38</option>
									<option value="39">39</option>
									<option value="40">40</option>
									<option value="41">41</option>
									<option value="42">42</option>
									<option value="43">43</option>
									<option value="44">44</option>
									<option value="45">45</option>
									<option value="46">46</option>
									<option value="47">47</option>
									<option value="48">48</option>
									<option value="49">49</option>
									<option value="50">50</option>
									<option value="51">51</option>
									<option value="52">52</option>
									<option value="53">53</option>
									<option value="54">54</option>
									<option value="55">55</option>
									<option value="56">56</option>
									<option value="57">57</option>
									<option value="58">58</option>
									<option value="59">59</option>
									<option value="60">60</option>
									<option value="61">61</option>
									<option value="62">62</option>
									<option value="63">63</option>
									<option value="64">64</option>
									<option value="65">65</option>
									<option value="66">66</option>
									<option value="67">67</option>
									<option value="68">68</option>
									<option value="69">69</option>
									<option value="70">70</option>
									<option value="71">71</option>
									<option value="72">72</option>
									<option value="73">73</option>
									<option value="74">74</option>
									<option value="75">75</option>
									<option value="76">76</option>
									<option value="77">77</option>
									<option value="78">78</option>
									<option value="79">79</option>
									<option value="80">80</option>
									<option value="81">81</option>
									<option value="82">82</option>
									<option value="83">83</option>
									<option value="84">84</option>
									<option value="85">85</option>
									<option value="86">86</option>
									<option value="87">87</option>
									<option value="88">88</option>
									<option value="89">89</option>
									<option value="90">90</option>
									<option value="91">91</option>
									<option value="92">92</option>
									<option value="93">93</option>
									<option value="94">94</option>
									<option value="95">95</option>
									<option value="96">96</option>
									<option value="97">97</option>
									<option value="98">98</option>
									<option value="99">99</option>
									<option value="100">100</option>
                            </select>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button @click="submitAddPackage" type="button" class="btn btn-secondary">{{locale.submit}} <span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                    <button @click="data.showModal=false" type="button" class="btn btn-danger" data-bs-dismiss="modal">{{locale.close}}</button>
                </div>
                </div>
            </div>
        </div>


    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getChannelsList, getClientsList, getSystemPlansList} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'TodayRegistered',
    components: {
      Breadcrumb,
      VPagination,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/reports/users/registered',
            page : 1,
            perPage : 10,
            totalPages : 0,
            totalRecords : 0,
            records: [],
            loading: false,

            showFilter: false,
            filters:{
                date: 1,
                start_date: '',
                end_date: '',
                keywords : '',
                client_id: '',
                channel_id: '',
                status: '',
            },

            clientsList: [],
            channelsList: [],

            showModal : false,
            plansList: [],

            add_package: {
               user_id : '',
               username : '',
               plan_id : '',
               order_number : '',
               remark : '',
               total_allowed_devices : 1,
            },

            submitBtn: false,

            date_title : ''

        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_reports', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()

            data.channelsList  = await getChannelsList()
            data.clientsList  = await getClientsList()

            data.plansList  = await getSystemPlansList()

            data.date_title = locale.value.today
        })

        const getRecords = async() => {
            data.loading = true
            let query_filter = new URLSearchParams(data.filters).toString()

            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
            console.log(resData)
            if(!resData.isSuccess){
                notify({text: resData.message, type:'error'})
            }
            data.records = resData.data
            data.totalRecords = resData.totalRecords
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.loading = false
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
                data.loading = true
                let resData = await callApi('/users/'+id, 'DELETE')
                data.loading = false
                if(resData.isSuccess){
                    notify({text: resData.message, type:'success'});
                    getRecords()
                }else{
                    notify({text: resData.message, type:'error'})
                }
          }
        }

        const showModals = async(id,username,user_plan_id) => {
            data.showModal = true
            data.add_package.user_id = id
            data.add_package.username = username
            data.add_package.plan_id = user_plan_id
        }

        const searchRecords = async() => {
            data.page = 1

            if(data.filters.date==9){
                if(data.filters.start_date == '' || data.filters.start_date == ''){
                    notify({text: "Please select start date and end date",type:'error'})
                    return false
                }
            }
            getRecords()
        }

        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }

        const resetFilters = async() => {
            data.filters = {
                start_date: '',
                end_date: '',
                keywords : '',
                client_id: '',
                channel_id: '',
                status: '',
            }
            searchRecords()

            data.date_title = locale.value.today
        }

        const submitAddPackage = async() => {
            if(data.add_package.plan_id == ''){
                notify({text: "Please select a package",type:'error'})
                return false
            }
            data.submitBtn = true
            let _body = data.add_package
            console.log("add package",_body)
            let resData = await callPostApi('/users/packages','POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
                data.showModal = false
                data.add_package = {
                    user_id : '',
                    username : '',
                    plan_id : '',
                    order_number : '',
                    remark : '',
                    total_allowed_devices : 1,
                }
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        const changeDateTitle = async() => {
            if(data.filters.date==1)
                data.date_title = locale.value.today
            else if(data.filters.date==2)
                data.date_title = locale.value.last_7_days
            else if(data.filters.date==3)
                data.date_title = locale.value.last_30_days
            else if(data.filters.date==4)
                data.date_title = locale.value.last_90_days
            else if(data.filters.date==5)
                data.date_title = locale.value.last_365_days
            else if(data.filters.date==6)
                data.date_title = locale.value.last_2_years_days
            else if(data.filters.date==7)
                data.date_title = locale.value.last_3_years_days
            else if(data.filters.date==8)
                data.date_title = locale.value.yesterday
            else if(data.filters.date==9)
                data.date_title = locale.value.custom_date_filter
                

        }

        return {
            data,
            locale,
            deleteRecord,
            getRecords,
            searchRecords,
            showFilters,
            resetFilters,
            showModals,
            submitAddPackage,
            changeDateTitle,
        }
    }
}

</script>