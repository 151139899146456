<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.points}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.points" :item_one_url="data.frontEndpoint" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="row">

            
            <div class="col-6">

                
               <div class="mb-3">
                    <label class="form-label">{{locale.slug}}</label>
                    <input v-model="data.slug" type="text" class="form-control" placeholder="" required>
                </div>
              

            </div>
   <div class="col-6">

                
               <div class="mb-3">
                    <label class="form-label">{{locale.total_points}}</label>
                    <input v-model="data.total_points" type="text" class="form-control" placeholder="" required>
                </div>
              

            </div>
           
            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect(data.frontEndpoint)" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getLanguageList} from "@/api/api"


export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            frontEndpoint: '/settings/points',
            endpoint: '/points/',
            recordId: '',
           
            slug : '',
            total_points:'',
        
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)
            
            
            let record = resData.data
            data.recordId = record.id
           
            data.slug = record.slug
            data.total_points = record.total_point
            
        })

        const submit = async() => {

            if(data.slug == ''){
                notify({text: 'Please enter Slug', type:'error'})
                return false
            }
            if(data.total_points == ''){
                notify({text: 'Please enter total Points', type:'error'})
                return false
            }

            data.submitBtn = true
            let _body = {
                slug: data.slug,
                total_point: data.total_points
            }
            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push(data.frontEndpoint)
            }else{
                notify({text: resData.message, type:'error'})
            }
        }


        return {
            data,
            locale,
            submit,
        }
    }
}

</script>