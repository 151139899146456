<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.shortcuts}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.shortcuts" :item_one_url="'/settings/shortcuts'" :item_two_title="locale.add" :item_two_active="true" />

        <div class="row">

            <div class="col-6">
                 <div class="mb-3">
                    <label class="form-label">{{locale.name}}</label>
                    <input v-model="data.name" type="text" class="form-control" placeholder="Google" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.icon}}</label>
                    <input accept="image/*" ref="file"  v-on:change="handleFileUpload()"  type="file" class="form-control">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.link}}</label>
                    <input v-model="data.link" type="text" class="form-control" placeholder="www.example.com" required>
                </div>
                 <div class="mb-3">
                    <label class="form-label">{{locale.sorting_order}}</label>
                    <input v-model="data.sorting_order" type="text" class="form-control" placeholder="1" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="2">{{locale.in_active}}</option>
                    </select>
                </div>
            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/settings/shortcuts')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed, ref} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import {callPostApi, uploadFile} from "@/api/api"

export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const file = ref(null)
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            name: '',
            sorting_order: '',
            icon: 'default.png',
            link: '',
            status: 1,
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)
        })

        const submit = async() => {

            if(!data.name || !data.icon){
                notify({
                    text: "Please add name and icon",
                    type:'error'
                });
                return false
            }
            data.submitBtn = true
            let _body = {
                name: data.name,
                sorting_order: data.sorting_order || 0,
                icon: data.icon,
                link: data.link,
                status: data.status,
            }

            let resData = await callPostApi('/shortcuts','POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/settings/shortcuts')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        const handleFileUpload = async() => {
            //debugger
            data.submitBtn = true
            try{
                const file_upload = file.value.files[0]
                const pattern = /image-*/
                if (file_upload && !file_upload.type.match(pattern)) {
                    notify({text: "Invalid image", type:'error'})
                    data.submitBtn = false
                    return
                }else{
                    const formData = new FormData()
                    formData.append('folder_name', 'client/shortcuts')
                    formData.append('upload', file_upload)
                    let resData = await uploadFile(formData)
                    data.icon = resData.file_url
                    data.submitBtn = false
                }
            }catch(err){
                data.submitBtn = false
                console.log("image upload error",err)
            }
        }


        return {
            data,
            locale,
            submit,
            handleFileUpload,
            file
        }
    }
}

</script>