
import { loadLang } from '@/config/translation'

export default {
    namespaced: true,
    state: {
        selectedLang: '',
        locale: {}
    },
    mutations: {
        updateLang(state, data) {
            state.locale = data
        }
    },
    actions: {
        async getLangs({ commit, state }, payload) {
            let langSelected = 'en'
            if(payload.userLang){
                langSelected = payload.userLang.toLowerCase()
            }
            state.selectedLang = langSelected
            commit('updateLang', loadLang(langSelected)) 
        }
    }
}
