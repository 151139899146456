<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.reports}} {{locale.payment}} {{locale.renewals}}</h2>
       <Breadcrumb :item_one_title="locale.manage+' '+locale.reports" :item_one_active="true" :loading="data.loading" />
       
        <div class="row mt-3 mb-3">
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-primary text-white h5">
                       {{data.date_title}}  {{locale.renewals}} {{locale.users}} 
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.totalRecords}}</h5>
                    </div>
                </div>
            </div>

            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-primary text-white h5">
                       {{data.date_title}}  {{locale.amount}}
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">${{data.totalSum}}</h5>
                    </div>
                </div>
            </div>

        </div>


        <button @click="showFilters" class="btn btn-outline-secondary mb-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>

        <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-3">
                    <label class="form-label">{{locale.date}}</label>
                    <select @change="changeDateTitle()" class="form-select" v-model="data.filters.date">
                        <option value="1">{{locale.today}}</option>
                        <option value="8">{{locale.yesterday}}</option>
                        <option value="2">{{locale.last_7_days}}</option>
                        <option value="3">{{locale.last_30_days}}</option>
                        <option value="4">{{locale.last_90_days}}</option>
                        <option value="5">{{locale.last_365_days}}</option>
                        <option value="6">{{locale.last_2_years_days}}</option>
                        <option value="7">{{locale.last_3_years_days}}</option>
                        <option value="9">{{locale.custom_date_filter}}</option>
                    </select>
                </div>
               

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.start}} {{locale.date}}</label>
                    <input v-model="data.filters.start_date" type="date" class="form-control" placeholder="12/18/2020"  aria-describedby="button-addon2">
                </div>

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.end}} {{locale.date}}</label>
                    <input v-model="data.filters.end_date" type="date" class="form-control" placeholder="12/18/2021"  aria-describedby="button-addon2">
                </div>

                <!--
                <div class="col-3">
                    <label class="form-label">{{locale.order}} {{locale.status}}</label>
                    
                    <select class="form-select" v-model="data.filters.status">
                        <option value="0">{{locale.un_paid}}</option>
                        <option value="1">{{locale.paid}}</option>
                        <option value="2">{{locale.pending}}</option>
                        <option value="3">{{locale.cancelled}}</option>
                        <option value="4">{{locale.rejected}}</option>
                        <option value="5">{{locale.refund}}</option>
                    </select>

                </div>
                 <div class="col-3">
                    <label class="form-label">{{locale.clients}}</label>
                    <select class="form-select" v-model="data.filters.client_id">
                        <option  v-for="(item,i) in data.clientsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div> -->

            </div>
           <!--
            <div class="row mt-3">

                <div class="col-3">
                    <label class="form-label">{{locale.payment}} {{locale.methods}}</label>
                    <select class="form-select" v-model="data.filters.payment_method_id">
                        <option  v-for="(item,i) in data.paymentMethodsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>

                 <div class="col-3">
                    <label class="form-label">{{locale.currency}}</label>
                    <select class="form-select" v-model="data.filters.currency_id">
                        <option  v-for="(item,i) in data.currencyList" v-bind:key="i"  :value="item.id">{{item.name}} ({{item.symbol}})</option>
                    </select>
                </div>

                <div class="col-3">
                    <label class="form-label">{{locale.package}}</label>
                    <select class="form-select" v-model="data.filters.plan_id">
                        <option  v-for="(item,i) in data.plansList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                    </select>
                </div>
               
            </div> -->
            
            <button @click="searchRecords" class="btn btn-secondary my-3 " type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-3 mx-2" type="button" id="button-addon2">{{locale.reset}}</button>
            <hr>
           

        </div>
    <!--    

        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{locale.email}}</th>
                    <th scope="col">{{locale.amount}}</th>
                    <th scope="col">{{locale.order}} {{locale.status}}</th>
                    <th scope="col">{{locale.order}} {{locale.date_created}}</th>
                    <th scope="col">{{locale.extra}}</th>
                
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,i) in data.records" v-bind:key="i" :class="item.is_deleted ? 'bg-danger': ''">
                    <td >{{item.id}}</td>
                    <td>
                        {{item.email}}
                        <br>{{locale.status}}: {{item.user_status == 1 ? locale.active : locale.in_active }}
                        <br>{{locale.date_created}}: {{ $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
                    </td>
                    <td >{{item.currency_symbol}}{{item.amount}} </td>
                    <td>
                        <span v-if="item.status==0" class="badge bg-primary rounded-pill">{{locale.un_paid}}</span>
                        <span v-else-if="item.status==1" class="badge bg-success rounded-pill">{{locale.paid}}</span>
                        <span v-else-if="item.status==2" class="badge bg-secondary rounded-pill">{{locale.pending}}</span>
                        <span v-else-if="item.status==3" class="badge bg-warning rounded-pill">{{locale.cancelled}}</span>
                        <span v-else-if="item.status==4" class="badge bg-danger rounded-pill">{{locale.rejected}}</span>
                        <span v-else-if="item.status==5" class="badge bg-info rounded-pill">{{locale.refund}}</span>
                        <span v-else class="badge bg-dark">{{item.status}}</span>
                    </td>
                    <td>{{ $moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}</td>
                    <td>
                        {{locale.package}}: {{item.user_plan_name}}<br>
                        {{locale.payment}} {{locale.methods}}: {{item.payment_method_name}}<br>
                        {{locale.clients}}: {{item.client_name}}<br>
                    </td>
                    
                    </tr>
                </tbody>
            </table>

            <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
        </div>

        -->

    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
//import {callApi, callPostApi, getCurrencyList, getPaymentMethodsList, getClientsList, getSystemPlansList} from "@/api/api"
import {callApi} from "@/api/api"
//import VPagination from "@hennge/vue3-pagination"
//import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'PayingUsers',
    components: {
      Breadcrumb,
     // VPagination,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/reports/payments/renewals',
            page : 1,
            perPage : 10,
            totalPages : 0,
            totalRecords : 0,
            totalSum : 0,
            records: [],
            loading: false,

            showFilter: false,
            filters:{
                date: 1,
                start_date: '',
                end_date: '',
                payment_method_id: '',
                currency_id: '',
                plan_id: '',
                keywords : '',
                client_id: '',
                channel_id: '',
                status: 1,
            },

            clientsList: [],
            channelsList: [],
            paymentMethodsList: [],
            currencyList: [],
            plansList: [],

            submitBtn: false,

            date_title : ''

        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_reports', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()


            //test
            //data.channelsList  = await getChannelsList()
            
            //data.clientsList  = await getClientsList()

            //data.plansList  = await getSystemPlansList()
           // data.paymentMethodsList = await getPaymentMethodsList()
           // data.currencyList = await getCurrencyList()
           // data.date_title = locale.value.today
        })

        const getRecords = async() => {
            data.loading = true
            let query_filter = new URLSearchParams(data.filters).toString()

            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
            
            console.log(resData)

            data.records = resData.data
            data.totalRecords = resData.totalRecords
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            
            if(resData.totalSum>0)
                data.totalSum = resData.totalSum.toFixed(2)
            else
                data.totalSum = 0
            

            data.loading = false
        }

        const searchRecords = async() => {
            data.page = 1
            if(data.filters.date==9){
                if(data.filters.start_date == '' || data.filters.start_date == ''){
                    notify({text: "Please select start date and end date",type:'error'})
                    return false
                }
            }
            getRecords()
        }
        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }
        const resetFilters = async() => {
            data.filters = {
                date: 1,
                client_id: '',
                status: '',
                payment_method_id: '',
                currency_id: '',
                plan_id: '',
            }
            searchRecords()
            data.date_title = locale.value.today
        }

        const changeDateTitle = async() => {
            if(data.filters.date==1)
                data.date_title = locale.value.today
            else if(data.filters.date==2)
                data.date_title = locale.value.last_7_days
            else if(data.filters.date==3)
                data.date_title = locale.value.last_30_days
            else if(data.filters.date==4)
                data.date_title = locale.value.last_90_days
            else if(data.filters.date==5)
                data.date_title = locale.value.last_365_days
            else if(data.filters.date==6)
                data.date_title = locale.value.last_2_years_days
            else if(data.filters.date==7)
                data.date_title = locale.value.last_3_years_days
            else if(data.filters.date==8)
                data.date_title = locale.value.yesterday
            else if(data.filters.date==9)
                data.date_title = locale.value.custom_date_filter

        }

        return {
            data,
            locale,
            getRecords,
            searchRecords,
            showFilters,
            resetFilters,
            changeDateTitle,
        }
    }
}

</script>