<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.clients}} {{locale.list}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.clients+' '+ locale.list" :item_one_url="'/clients/list'" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="w-50">
            <div class="mb-3">
                <label class="form-label">{{locale.name}}</label>
                <input v-model="data.name" type="text" class="form-control" placeholder="pc client" required>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.code}}:</label>
                {{data.code}}
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.other}} {{locale.icon}}</label>
                <input v-model="data.other_icon" type="text" class="form-control" placeholder="f3-f3-awesome" required>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.icon}}</label>
                <input v-model="data.icon" type="text" class="form-control" placeholder="https://swoshsvpn.com/theme/assets/images/windows/windows.png">
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.link}}</label>
                <input v-model="data.external_link" type="text" class="form-control" placeholder="https://play.google.com/store/apps/details?id=com.swoshsvpn.vpn">
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.sorting_order}}</label>
                <input v-model="data.sorting_order" type="text" class="form-control" placeholder="1" required>
            </div>

            <div class="mb-3">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/clients/list')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>
        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi,callPostApi} from "@/api/api"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/clients',
            recordId: '',
            name: '',
            code: '',
            other_icon: '',
            icon: '',
            external_link: '',
            sorting_order: '',
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+'/'+data.recordId, 'GET')
            let record = resData.data
            data.recordId = record.id
            data.name =  record.name
            data.code = record.code
            data.other_icon = record.other_icon
            data.icon = record.icon
            data.external_link = record.external_link
            data.sorting_order = record.sorting_order
        })

        const submit = async() => {
            if(data.name == ''){
                notify({
                    text: "Please enter name",
                    type:'error'
                });
                return false
            }
            data.submitBtn = true
            let _body = {
                name: data.name,
                code: data.code,
                other_icon: data.other_icon,
                icon: data.icon,
                external_link: data.external_link,
                sorting_order: data.sorting_order,
            }

            let resData = await callPostApi(data.endpoint+'/'+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/clients/list')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>