<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.ad_source}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.ad_source" :item_one_url="'/ads/ad-source'" :item_two_title="locale.edit" :item_two_active="true" />

        <div class="row">
            <div class="col-6">
                    <div class="mb-3">
                        <label class="form-label">{{locale.name}}</label>
                        <input v-model="data.name" type="text" class="form-control" required>
                    </div>
            </div>
            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/ads/ad-source')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import {callPostApi, callApi, getSystemPlansList} from "@/api/api"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)
        const route = useRoute()

        const data = reactive({
            endpoint: '/ad-source/',
           recordId: '',
            name: '',
            //purpose: '',
            // received_money: 0,
            // plans: [],
            // planId: null,
            // finance_comment: '',
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)

                  data.recordId = route.query.id
                  console.log(data.recordId)
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)
            
            
            let record = resData.data
            console.log(record)

            data.recordId = record.id
           
            data.name = record.name
            // data.purpose = record.purpose
            // data.received_money= record.recv_money
            // data.finance_comment = record.finance_comment
            // data.planId = record.plan_id
            // data.plans= await getSystemPlansList()
           
        })

        const submit = async() => {

           // let tempTranslationArr = []
            
                
            data.submitBtn = true
            let _body = {
               name: data.name,
               //purpose: data.purpose,
            //    recv_money: data.received_money,
            //    finance_comment: data.finance_comment,
            //    plan: data.planId

            }

            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/ads/ad-source')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }
//  const setplanVal = (id) =>{
//           data.planId= id
//           console.log(data.planId)
//         }
        return {
            data,
            locale,
            submit,
            route
        }
    }
}

</script>