<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <h2>{{ locale.manage }} {{ locale.users }} {{ locale.packages }}</h2>
    <Breadcrumb
      :item_one_title="
        locale.manage + ' ' + locale.users + ' ' + locale.packages
      "
      :item_one_active="true"
      :loading="data.loading"
    />

    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>

            <th scope="col">{{ locale.username }}</th>
            <th scope="col">{{ locale.user }} {{ locale.id }}</th>
            <th scope="col">{{ locale.package }} {{ locale.name }}</th>
            <th scope="col">{{ locale.start }} {{ locale.date }}</th>
            <th scope="col">{{ locale.end }} {{ locale.date }}</th>
            <th scope="col">{{ locale.allowed }} {{ locale.devices }}</th>
            <th scope="col">{{ locale.free }}</th>
            <th scope="col">{{ locale.order }} {{ locale.number }}</th>
            <th scope="col">{{ locale.date_created }}</th>
            <th scope="col">{{ locale.date_update }}</th>
            <th scope="col">{{ locale.action }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in data.records"
            v-bind:key="i"
            :class="item.is_deleted ? 'bg-danger' : ''"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.username }}</td>
            <td>{{ item.user_id }}</td>
            <td>{{ item.plan_name }}</td>
            <td>
              {{ $formatDate(item.start_date)  }}
            </td>
            <td>{{ $formatDate(item.end_date)  }}</td>
            <td>
              <span class="badge bg-secondary rounded-pill">{{
                item.total_allowed_devices
              }}</span>
            </td>
            <td>{{ item.is_free == 1 ? locale.yes : locale.no }}</td>
            <td>{{ item.order_number }}</td>
            <td>
              {{ $formatDate(item.created_at)  }}
            </td>
            <td>
              {{ $formatDate(item.updated_at) }}
            </td>
            <td>
              <button
                v-if="item.is_deleted == 0"
                type="button"
                class="btn btn-info btn-sm my-1 mx-1"
              >
                {{ locale.refund }}
              </button>
              <button
                v-if="item.is_deleted == 0"
                @click="$redirect('/users/packages/edit/?id=' + item.id)"
                type="button"
                class="btn btn-warning btn-sm my-1 mx-1"
              >
                {{ locale.edit }}
              </button>
              <button
                v-if="item.is_deleted == 0"
                @click="deleteRecord(item.id)"
                type="button"
                class="btn btn-danger btn-sm my-1 mx-1"
              >
                {{ locale.delete }}
              </button>
              <button
                v-if="item.is_deleted == 0"
                @click="showModals(item)"
                type="button"
                class="btn btn-dark btn-sm my-1 mx-1"
              >
                {{ locale.transfer }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex mb-5">
        <div class="col-10">
          <v-pagination
            v-model="data.page"
            :pages="data.totalPages"
            :range-size="2"
            active-color="#d2f4ea"
            @update:modelValue="getRecords"
          />
        </div>
        <div class="col-2">
          <select
            @change="getRecords()"
            class="form-select"
            v-model="data.perPage"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      v-if="data.showModal"
      style="display: block"
      class="modal fade show"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ locale.transfer }} {{ locale.package }}
            </h5>
            <button
              @click="closeModal"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label class="form-label">{{ locale.package }} *</label>
                <select
                  class="form-select"
                  v-model="data.transfer_package.system_plan_id"
                  disabled
                >
                  <option
                    v-for="(item, i) in data.plansList"
                    v-bind:key="i"
                    :value="item.id"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">{{ locale.username }} *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.transfer_package.username"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label"
                  >{{ locale.order }} {{ locale.number }} *</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="data.transfer_package.order_number"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label"
                  >{{ locale.order }} {{ locale.status }}</label
                >
                <select
                  class="form-select"
                  v-model="data.transfer_package.order_status"
                  disabled
                >
                  <option value="0">{{ locale.un_paid }}</option>
                  <option value="1">{{ locale.paid }}</option>
                  <option value="2">{{ locale.pending }}</option>
                  <option value="3">{{ locale.cancelled }}</option>
                  <option value="4">{{ locale.rejected }}</option>
                  <option value="5">{{ locale.refund }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label class="form-label"
                  >{{ locale.start }} {{ locale.date }}</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="data.transfer_package.start_date"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label"
                  >{{ locale.end }} {{ locale.date }}</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="data.transfer_package.end_date"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label"
                  >{{ locale.transfer }} {{ locale.to }}
                  {{ locale.username }} *</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="data.transfer_package.transfer_to_username"
                />
              </div>

              <div class="mb-3">
                <label for="message-text" class="form-label">{{
                  locale.remark
                }}</label>
                <textarea
                  class="form-control"
                  v-model="data.transfer_package.remark"
                ></textarea>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              @click="submitTransferPackage"
              type="button"
              class="btn btn-secondary"
            >
              {{ locale.submit }}
              <span
                v-if="data.submitBtn"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button
              @click="closeModal"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              {{ locale.close }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, reactive, computed } from "vue";
import { useStore } from "@/store";
import { useRouter, useRoute } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import { callApi, callPostApi, getSystemPlansList } from "@/api/api";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { checkAccessSinglePage } from "@/utils/utils";

export default {
  name: "Users",
  components: {
    Breadcrumb,
    VPagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const auth = computed(() => store.state.authenticated);
    const authUser = computed(() => store.state.authUser);
    const locale = computed(() => store.state.translation.locale);

    const data = reactive({
      endpoint: "/users/packages",
      recordId: "",
      page: 1,
      perPage: 10,
      totalPages: 0,
      records: [],
      loading: false,

      showModal: false,
      submitBtn: false,
      transfer_package: {
        user_plan_id: "",
        user_id: "",
        username: "",
        transfer_to_username: "",
        system_plan_id: "",
        order_number: "",
        order_status: "",
        remark: "",
        start_date: "",
        end_date: "",
      },
    });

    onMounted(async () => {
      if (!auth.value) router.push(`/login`);

      let isAllowAccessPage = await checkAccessSinglePage(
        "manage_users",
        authUser.value
      );
      if (!isAllowAccessPage) await router.push("/");

      data.recordId = route.query.id;
      getRecords();

      data.plansList = await getSystemPlansList();
    });

    const getRecords = async () => {
      data.loading = true;
      if (typeof data.recordId == "undefined") {
        data.recordId = "";
      }
      let query_filter = new URLSearchParams(data.filters).toString();
      let resData = await callApi(
        data.endpoint +
          "?id=" +
          data.recordId +
          "&page=" +
          data.page +
          "&perPage=" +
          data.perPage +
          "&" +
          query_filter,
        "GET"
      );
      console.log(resData);
      data.records = resData.data;
      data.totalPages = Math.ceil(resData.totalRecords / data.perPage);
      data.loading = false;
    };
    const deleteRecord = async (id) => {
      if (confirm(locale.value.confirm_delete)) {
        data.loading = true;
        let resData = await callApi(data.endpoint + "/" + id, "DELETE");
        data.loading = false;
        if (resData.isSuccess) {
          notify({ text: resData.message, type: "success" });
          getRecords();
        } else {
          notify({ text: resData.message, type: "error" });
        }
      }
    };

    const showModals = async (item) => {
      console.log("showModals item", item);
      data.transfer_package.user_plan_id = item.id;
      data.transfer_package.user_id = item.user_id;
      data.transfer_package.username = item.username;
      data.transfer_package.system_plan_id = item.system_plan_id;
      data.transfer_package.order_number = item.order_number;
      data.transfer_package.order_status = item.order_status;
      data.transfer_package.remark = item.remark;
      data.transfer_package.start_date = item.start_date;
      data.transfer_package.end_date = item.end_date;
      data.showModal = true;
    };

    const submitTransferPackage = async () => {
      if (data.transfer_package.system_plan_id == "") {
        notify({ text: "Package required", type: "error" });
        return false;
      }
      if (data.transfer_package.transfer_to_username == "") {
        notify({ text: "Transfer to username required", type: "error" });
        return false;
      }

      if (confirm(locale.value.confirm_delete)) {
        data.submitBtn = true;
        let _body = data.transfer_package;
        let resData = await callPostApi(
          "/users/packages/transfer",
          "POST",
          JSON.stringify(_body)
        );
        data.submitBtn = false;
        if (resData.isSuccess) {
          notify({ text: resData.message, type: "success" });
          getRecords();
          closeModal();
        } else {
          notify({ text: resData.message, type: "error" });
        }
      }
    };

    const closeModal = () => {
      data.showModal = false;
      data.transfer_package = {
        user_plan_id: "",
        user_id: "",
        username: "",
        transfer_to_username: "",
        system_plan_id: "",
        order_number: "",
        order_status: "",
        remark: "",
        start_date: "",
        end_date: "",
      };
    };

    return {
      data,
      locale,
      deleteRecord,
      getRecords,
      showModals,
      submitTransferPackage,
      closeModal,
    };
  },
};
</script>
