<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.users}} {{locale.packages}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.users+' '+locale.packages" :item_one_url="data.frontEndpoint" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="row">

            <div class="col-6">
                
                <div class="mb-3">
                    <label class="form-label">{{locale.manage}} {{locale.package}}</label>
                    <select class="form-select" v-model="data.system_plan_id">
                        <option  v-for="(item,i) in data.plansList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.start}} {{locale.date}}</label>
                    <input v-model="data.start_date" type="datetime" class="form-control">
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.end}} {{locale.date}}</label>
                    <input v-model="data.end_date" type="datetime" class="form-control">
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.allowed}} {{locale.devices}}</label>
                    <input @input="num = $event.target.value.replace(/[^\d]/g,'');$event.target.value = num" v-model="data.total_allowed_devices" type="number" class="form-control" placeholder="100">
                </div>

                <div class="mb-3">
                    <label for="message-text" class="form-label">{{locale.remark}}</label>
                    <textarea class="form-control" v-model="data.remark"></textarea>
                </div>

            </div>

            <div class="col-6">
                <div class="mb-3">
                    <label class="form-label">{{locale.username}}</label>
                    <input v-model="data.username" type="text" class="form-control" placeholder="xyz" disabled>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.order}} {{locale.number}}</label>
                    <input v-model="data.order_number" type="text" class="form-control" placeholder="">
                </div>
            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect(data.frontEndpoint)" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getSystemPlansList} from "@/api/api"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            frontEndpoint: '/users/packages',
            endpoint: '/users/packages/',
            recordId: '',
            username: '',
            user_id: '',
            system_plan_id: '',
            start_date: '',
            end_date: '',
            total_allowed_devices: '',
            remark: '',
            order_number: '',

            plansList: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            
            if(resData.notLogin)
                router.push(`/login`)

            let record = resData.data
            data.recordId = record.id
            data.username = record.username
            data.user_id = record.user_id
            data.system_plan_id = record.system_plan_id
            data.start_date = record.start_date
            data.end_date = record.end_date
            data.total_allowed_devices = record.total_allowed_devices
            data.order_number = record.order_number
            data.remark = record.remark

            data.plansList  = await getSystemPlansList()

            data.frontEndpoint = data.frontEndpoint + '/?id=' + data.user_id
        })

        const submit = async() => {

            if(data.total_allowed_devices && data.total_allowed_devices > 100){
                notify({text: "100 devices allowed.", type:'error'})
                return false
            }
           
            data.submitBtn = true
            let _body = {
                user_id: data.user_id,
                system_plan_id: data.system_plan_id,
                start_date:  data.start_date,
                end_date:  data.end_date,
                total_allowed_devices:  data.total_allowed_devices,
                order_number:  data.order_number,
                remark:  data.remark
            }
            
            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push(data.frontEndpoint)
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>