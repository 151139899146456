<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.orders}}</h2>
       <Breadcrumb :item_one_title="locale.manage+' '+locale.orders" :item_one_active="true" :loading="data.loading" />
       
        <button @click="showFilters" class="btn btn-outline-secondary mb-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>
        <button @click="getRecords()" class="btn btn-success ms-3 mb-3 " type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.refresh}}</button>
        <button @click="showPopup" class="btn btn-primary ms-3 mb-3 " type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.generate_pay_link}}</button>

        <button @click="showModalOrderLookup" class="btn btn-secondary ms-3 mb-3 " type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.order}} {{locale.lookup}}</button>

        
        <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-6">
                    <label class="form-label">{{locale.search}}</label>
                    <input v-model="data.filters.keywords" type="text" class="form-control" placeholder="by order number, username, email"  aria-describedby="button-addon2">
                </div>
                <div class="col-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.filters.status">
                        <option value="0">{{locale.un_paid}}</option>
                        <option value="1">{{locale.paid}}</option>
                        <option value="2">{{locale.pending}}</option>
                        <option value="3">{{locale.cancelled}}</option>
                        <option value="4">{{locale.rejected}}</option>
                        <option value="5">{{locale.refund}}</option>
                    </select>
                </div>
                <div class="col-3">
                    <label class="form-label">{{locale.payment}} {{locale.methods}}</label>
                    <select class="form-select" v-model="data.filters.payment_method_id">
                        <option  v-for="(item,i) in data.paymentMethodsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>
            </div>
            
            <div class="row mt-3">
                <div class="col-3">
                    <label class="form-label">{{locale.currency}}</label>
                    <select class="form-select" v-model="data.filters.currency_id">
                        <option  v-for="(item,i) in data.currencyList" v-bind:key="i"  :value="item.id">{{item.name}} ({{item.symbol}})</option>
                    </select>
                </div>
                <div class="col-3">
                    <label class="form-label">{{locale.package}}</label>
                    <select class="form-select" v-model="data.filters.plan_id">
                        <option  v-for="(item,i) in data.plansList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                    </select>
                </div>
                <div class="col-3">
                    <label class="form-label">{{locale.clients}}</label>
                    <select class="form-select" v-model="data.filters.client_id">
                        <option  v-for="(item,i) in data.clientsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-3">
                    <label class="form-label">{{locale.channels}}</label>
                    <select class="form-select" v-model="data.filters.channel_id">
                        <option  v-for="(item,i) in data.channelsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>
                
            </div>

            <div class="row mt-3">
                <div class="col-2">
                    <label class="form-label">{{locale.date}}</label>
                    <select @change="changeDateTitle()" class="form-select" v-model="data.filters.date">
                        <option value="1">{{locale.today}}</option>
                        <option value="8">{{locale.yesterday}}</option>
                        <option value="2">{{locale.last_7_days}}</option>
                        <option value="3">{{locale.last_30_days}}</option>
                        <option value="4">{{locale.last_90_days}}</option>
                        <option value="5">{{locale.last_365_days}}</option>
                        <option value="6">{{locale.last_2_years_days}}</option>
                        <option value="7">{{locale.last_3_years_days}}</option>
                        <option value="9">{{locale.custom_date_filter}}</option>
                    </select>
                </div>

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.start}} {{locale.date}}</label>
                    <input v-model="data.filters.start_date" type="date" class="form-control" placeholder="12/18/2020"  aria-describedby="button-addon2">
                </div>

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.end}} {{locale.date}}</label>
                    <input v-model="data.filters.end_date" type="date" class="form-control" placeholder="12/18/2021"  aria-describedby="button-addon2">
                </div>
                <div class="col-2"><label class="form-label">{{locale.include_test_payments}}</label><select class="form-select" v-model="data.filters.test_Payment"><option value="0">No</option><option value="1">Yes</option></select></div>
            
            </div>

            <button @click="searchRecords" class="btn btn-secondary my-1 mx-1" type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-1 mx-1" type="button" id="button-addon2">{{locale.reset}}</button>

            <hr>
        </div>

        <div class="table-responsive">
            <br>
            <b>{{locale.total}} {{locale.orders}}: {{data.totalRecords}}</b>
            <br>
            <b>{{locale.total}} {{locale.amount}}: ${{data.totalSum}}</b>
            <hr>
            
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{locale.order}} {{locale.number}}</th>
                        <th scope="col">{{locale.username}}</th>
                        <th scope="col">{{locale.amount}} </th>
                        <!-- <th scope="col">{{locale.package}} </th>
                        <th scope="col">{{locale.payment}} {{locale.methods}} </th> -->
                        <th scope="col">{{locale.status}}</th>
                        <!-- <th scope="col">{{locale.clients}}</th> -->
                        <!-- <th scope="col">{{locale.channels}}</th> -->
                        <th scope="col">{{locale.date_created}}</th>
                        <th scope="col">{{locale.date_update}}</th>
                        <th scope="col">{{locale.action}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,i) in data.records" v-bind:key="i" :class="item.is_deleted ? 'bg-danger': ''">
                    <td>{{item.id}}</td>
                    <td>{{item.order_number}}</td>
                    <td>{{item.username}} ({{item.user_id}})</td>
                    <td>{{item.amount}} {{item.currency_symbol}} <!--{{item.currency_id}}-->  <!--({{item.currency_code}})--></td>
                    <!-- <td>{{item.plan_name}}</td>
                    <td>{{item.payment_method_name}}</td> -->
                    <td>
                        <span v-if="item.status==0" class="badge bg-primary rounded-pill">{{locale.un_paid}}</span>
                        <span v-else-if="item.status==1" class="badge bg-success rounded-pill">{{locale.paid}}</span>
                        <span v-else-if="item.status==2" class="badge bg-secondary rounded-pill">{{locale.pending}}</span>
                        <span v-else-if="item.status==3" class="badge bg-warning rounded-pill">{{locale.cancelled}}</span>
                        <span v-else-if="item.status==4" class="badge bg-danger rounded-pill">{{locale.rejected}}</span>
                        <span v-else-if="item.status==5" class="badge bg-info rounded-pill">{{locale.refund}}</span>
                        <span v-else class="badge bg-dark">{{item.status}}</span>
                    </td>
                    <!-- <td>{{item.client_name}}</td> -->
                    <!-- <td>{{item.channel_name}}</td> -->
                    <td>{{ $formatDate(item.created_at) }}</td>
                    <td>{{ $formatDate(item.updated_at) }}</td>
                    
                    
                    <td>
                        <button v-if="item.is_deleted==0" @click="$redirect('/orders/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm ms-1">{{locale.edit}}</button>
                        <button v-if="item.is_deleted==0" @click="showModals(item.id)" type="button" class="btn btn-dark btn-sm ms-1">{{locale.detail}}</button>
                        <button v-if="item.is_deleted==0"  @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm ms-1">{{locale.delete}}</button>
                        <button v-show="item.status!=1" v-if="item.status==0 && item.pay_link && item.pay_link.legnth>0 || item.payment_method_id==9 || item.payment_method_id==8 || item.payment_method_id==16"  @click="copyPayLink(item.pay_link)" type="button" class="btn btn-success btn-sm ms-1">{{locale.copy_pay_link}}</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                <hr>
                <b>{{locale.total}} {{locale.orders}}: {{data.totalRecords}}</b>
                <br>
                <b>{{locale.total}} {{locale.amount}}: ${{data.totalSum}}</b>
                
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div v-if="data.showModal" style="display: block;" class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                <div class="modal-header bg-primary text-white">
                    <h5 class="modal-title" id="exampleModalLabel">{{locale.order}} {{locale.detail}}</h5>
                    <button @click="data.showModal=false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="d-flex justify-content-between align-items-center py-3">
                        <h2 class="h5 mb-0"><a href="#" class="text-muted"></a> {{locale.order}} #{{data.order_detail.order_number}}
                        <br>
                            {{locale.status}}:
                                <span v-if="data.order_detail.status==0" class="badge bg-primary rounded-pill">{{locale.un_paid}}</span>
                                <span v-else-if="data.order_detail.status==1" class="badge bg-success rounded-pill">{{locale.paid}}</span>
                                <span v-else-if="data.order_detail.status==2" class="badge bg-secondary rounded-pill">{{locale.pending}}</span>
                                <span v-else-if="data.order_detail.status==3" class="badge bg-warning rounded-pill">{{locale.cancelled}}</span>
                                <span v-else-if="data.order_detail.status==4" class="badge bg-danger rounded-pill">{{locale.rejected}}</span>
                                <span v-else-if="data.order_detail.status==5" class="badge bg-info rounded-pill">{{locale.refund}}</span>
                                <span v-else class="badge bg-dark">{{data.order_detail.status}}</span>
                        </h2>
                    </div>
                    <div class="row">
                        
                        <!-- Details -->
                        <div class="card mb-4">
                            <div class="card-body">
                            
                            <table class="table table-borderless">
                                <tbody>
                                        <tr>
                                            <td>
                                            <div class="d-flex mb-2">
                                                <div class="flex-shrink-0">
                                                    <img src="https://via.placeholder.com/280x280/87CEFA/000000" alt="" width="35" class="img-fluid">
                                                </div>
                                                <div class="flex-lg-grow-1 ms-3">
                                                    <h6 class="small mb-0">{{locale.package}}: {{data.order_detail.plan_name}}</h6>
                                                </div>
                                            </div>
                                            </td>
                                            <td>1</td>
                                            <td class="text-end">{{data.order_detail.amount}} {{data.order_detail.currency_symbol}} ({{data.order_detail.currency_code}})</td>
                                        </tr>
                                </tbody>
                                <tfoot>
                                    <!-- <tr>
                                        <td colspan="2">Subtotal</td>
                                        <td class="text-end">$159,98</td>
                                    </tr> -->
                                
                                    <tr class="fw-bold">
                                        <td colspan="2">{{locale.total}}</td>
                                        <td class="text-end">{{data.order_detail.amount}} {{data.order_detail.currency_symbol}}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            </div>
                        </div>
                        <!-- Payment -->
                        <div class="card mb-4">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <h3 class="h6">{{locale.payment}} {{locale.methods}}</h3>
                                        <p><strong>{{data.order_detail.payment_method_name}}</strong><br>
                                        </p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h3 class="h6">{{locale.email}}</h3>
                                        <address>
                                            <strong>{{ data.order_detail.email }} ({{data.order_detail.user_id}})</strong><br>
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mb-4">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <h3 class="h6">{{locale.clients}}</h3>
                                        <p><strong>{{data.order_detail.client_name}}</strong><br>
                                        </p>
                                    </div>
                                    <div class="col-lg-6">
                                        <p>
                                            {{locale.date_created}}: <strong>{{data.order_detail.created_at }}</strong><br>
                                            {{locale.date_update}}: <strong>{{ data.order_detail.updated_at }}</strong><br>
                                            {{locale.channels}}:<strong>{{data.order_detail.channel_name}}</strong><br>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h2>Developer Logs</h2>
                        <code><pre>{{data.order_detail}}</pre></code>
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="data.showModal=false" type="button" class="btn btn-danger" data-bs-dismiss="modal">{{locale.close}}</button>
                </div>
                </div>
            </div>
        </div>

        <!-- Show pay link modal -->
        <div
            class="modal fade"
            :class="showForm ? 'show' : ''"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            :style="showForm ? 'display:block' : ''"
        >
            <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-primary text-white">
                <h5>{{ locale.generate_pay_link }}</h5>
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    @click="hidePopup"
                ></button>
                </div>
                <div class="modal-body">
                <h4 class="text-center">
                    
                </h4>
                <div
                >
                    <div class="row">
                    <div class="col-12">
                    <div class="mb-3">
                        <vSelect :loading="data.userLoading" v-model="selectedUser" :options="options" label="username" @search="getUser">
                            <template #header>
                            <div style="opacity: 0.8">{{locale.user}}</div>
                            </template>
                                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                                <template #no-options="{selectedUser}">
                                    {{locale.enter_username_select}}
                            </template>
                            <template #spinner="{ loading }">
                                <div
                                    v-if="loading"
                                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                                    class="vs__spinner"
                                >
                                    The .vs__spinner class will hide the text for me.
                                </div>
                            </template>
                        </vSelect>
                        </div>
                        <div class="mb-3">
                        <label class="form-label">{{locale.package}}</label>
                        <select class="form-select" v-model="data.planId" @change="setPlanId($event.target.value)">
                            <option  v-for="(item,i) in data.plansList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">{{locale.payment}} {{locale.methods}}</label>
                        <select class="form-select" v-model="data.PaymentId" @change="setPaymentId($event.target.value)">
                            <option  v-for="(item,i) in data.filteredpaymentMethod" v-bind:key="i"  :value="item.id" >{{item.key_name}}</option>
                        </select>
                    </div>
                    </div>
                    <div
                        class="
                        mb-3
                        mt-5
                        mx-auto
                        text-center
                        justify-content-center
                        align-items-center
                        "
                    >
                        <button
                        @click="generatePaylink()"
                        :disabled="pay_link_generated"
                        type="button"
                        class="btn btn-warning btn-sm"
                        >
                        {{ locale.generate }}
                        </button>
                    </div>
                    <div v-if="pay_link_generated" class="modal-footer d-flex align-items-center justify-content-center">
                <div>

    


                        <div class="input-group mb-3 mt-3">
                        <input type="text" class="form-control" id="pay_link" :value="data.generated_PayLink" aria-describedby="button-addon2">
                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="copyToClipboard">{{locale.copy_pay_link}}</button>
                        </div>
    

                    </div>
                        </div>
                    
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>


         <!-- Modal Show Order Lookup -->
        <div v-if="data.showModalOrderLookup" style="display: block;" class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                <div class="modal-header bg-primary text-white">
                    <h5 class="modal-title" id="exampleModalLabel">{{locale.order}} {{locale.lookup}}</h5>
                    <button @click="data.showModalOrderLookup=false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    
                    <div class="row">

                        <div class="mb-3">
                            <label class="form-label">{{locale.clients}}</label>
                            <select class="form-select" v-model="data.order_lookup.client_id">
                                <option  v-for="(item,i) in data.clientsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">{{locale.transaction_id}}</label>
                            <input v-model="data.order_lookup.transaction_id" type="text" class="form-control" placeholder="MLJN02DKXJ OR 120001492691933">
                            <p>
                                <small>
                                    Apple Transaction ID: Ask user to open his Purchase History in his device and then see SwoshsVPN app transaction, in right side of the list copy the transaction id i.e. <code>MLJN03DXKJ</code> OR <code>120001492691933</code>
                                </small>
                            </p>
                            <!-- <p><small>Google Play Transaction ID:</small></p> -->
                        </div>

                         <div class="mb-3">
                            <label class="form-label">{{locale.check}} {{locale.status}}</label>
                            <select class="form-select" v-model="data.order_lookup.check_status_type">
                                <option value="1">{{locale.paid}}</option>
                                <option value="2">{{locale.refund}}</option>
                            </select>
                        </div>

                    </div>
                        
                    <div class="mb-3" v-if="data.order_lookup_detail">
                        Refuned: <span class="badge bg-secondary rounded-pill" v-if="data.order_lookup_detail.redfunded">{{data.order_lookup_detail.redfunded}}</span>
                        <br>
                        Refuned Date: <span class="badge bg-secondary rounded-pill" v-if="data.order_lookup_detail.refund_date">{{data.order_lookup_detail.refund_date}}</span>
                        <br>
                    </div>
                    
                    <div v-if="data.order_lookup_detail">
                        <b>Developer Logs:</b>
                        <code><pre>{{data.order_lookup_detail}}</pre></code>
                    </div>
                    
                </div>
                <div class="modal-footer">
                    <button @click="orderLookup" class="btn btn-secondary my-1 mx-1" type="button" id="button-addon2">{{locale.submit}}</button>
                    <button @click="data.showModalOrderLookup=false" type="button" class="btn btn-danger" data-bs-dismiss="modal">{{locale.close}}</button>
                </div>
                </div>
            </div>
        </div>

    </main>
</template>


<script>

import {onMounted, reactive, computed, ref} from "vue"
import Vue from 'vue'
import { useStore } from '@/store';
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, getChannelsList, getCurrencyList, getPaymentMethodsList, getSystemPlansList, getClientsList,getUserList,callPostApi} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {checkAccessSinglePage} from '@/utils/utils'


// import CurrencyComponent from "@/components/common/CurrencyComponent.vue"

export default{
     
    name:'OrdersUsers',
    components: {
      Breadcrumb,
      VPagination,
      vSelect,
    //   CurrencyComponent,
    },
    setup() {
      
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)
        let showForm = ref(false);
        let pay_link_generated =ref(false);
        let showPayLinkBtn= ref(false);
        let options = ref([]);
        let selectedUser = ref('');
        const data = reactive({
            generate_Paylink_endpoint: '/get-PayLink',
            searchUserEndpoint: '/user-list',
            endpoint: '/orders',
            page : 1,
            perPage : 10,
            totalPages : 0,
            totalSum : 0,
        
            records: [],
            loading: false,
            userLoading: false,
            searchUser: '',
            userArr: [],
            userId: [],
            PaymentId:0,
            planId: [],
            generated_PayLink:'',
            selectedUser:[],

            showFilter: false,
            filters:{
                keywords : '',
                payment_method_id: '',
                plan_id: '',
                currency_id: '',
                client_id: '',
                channel_id: '',
                status: 1,
                date: 0,
                start_date: '',
                end_date: '',
                test_Payment: 0,
            },

            clientsList: [],
            channelsList: [],
            paymentMethodsList: [],
            filteredpaymentMethod:[],
            currencyList: [],
            plansList: [],

            showModal : false,

            order_detail : {},

            showModalOrderLookup: false,
            order_lookup :{transaction_id:'',client_id:2,check_status_type:1},
            order_lookup_detail : {},
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)

            let isAllowAccessPage = await checkAccessSinglePage('manage_orders', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()
            let userArr= []
            data.paymentMethodsList = await getPaymentMethodsList()
            data.currencyList = await getCurrencyList()
            data.plansList  = await getSystemPlansList()
            data.channelsList  = await getChannelsList()
            data.clientsList  = await getClientsList()
            //console.log(data.paymentMethodsList)
            //console.log(data.userArr)
            data.filteredpaymentMethod = data.paymentMethodsList.filter((u)=>u.id==8 || u.id==9 || u.id==16)
           // console.log("filter",data.filteredpaymentMethod)
          
        })

        const getRecords = async() => {
            data.loading = true
            
            try{
                data.filters.keywords = data.filters.keywords.trim()
            }catch(err){console.log("Filter keywords trim error", err)}

            let query_filter = new URLSearchParams(data.filters).toString()
            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
            console.log("orders Data",resData)
            data.records = resData.data
            data.totalRecords = resData.totalRecords
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.totalSum = Math.ceil(resData.totalSum)
            data.loading = false
        }

        const getUser = async(search) => {   
            // data.loading = true
            data.userLoading = true
            if(search=='')
                return
            let resData = await callApi(data.searchUserEndpoint+'?username='+search, 'GET')
            console.log(resData)
            data.userArr= await resData.data
            options.value= await resData.data
            data.userLoading=false
            // data.loading = false
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
                data.loading = true
                let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
                data.loading = false
                if(resData.isSuccess){
                    notify({text: resData.message, type:'success'})
                    getRecords()
                }else{
                    notify({text: resData.message, type:'error'})
                }
          }
        }

        const searchRecords = async() => {
            data.page = 1
            getRecords()
        }

        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }

        const resetFilters = async() => {
            data.filters = {
                keywords : '',
                payment_method_id: '',
                plan_id: '',
                currency_id: '',
                client_id: '',
                channel_id: '',
                status: '',
                date: 0,
                start_date: '',
                end_date: '',
                test_Payment:''
                
            }
            searchRecords()
        }

        const changeDateTitle = async() => {
            if(data.filters.date==1)
                data.date_title = locale.value.today
            else if(data.filters.date==2)
                data.date_title = locale.value.last_7_days
            else if(data.filters.date==3)
                data.date_title = locale.value.last_30_days
            else if(data.filters.date==4)
                data.date_title = locale.value.last_90_days
            else if(data.filters.date==5)
                data.date_title = locale.value.last_365_days
            else if(data.filters.date==6)
                data.date_title = locale.value.last_2_years_days
            else if(data.filters.date==7)
                data.date_title = locale.value.last_3_years_days
            else if(data.filters.date==8)
                data.date_title = locale.value.yesterday
            else if(data.filters.date==9)
                data.date_title = locale.value.custom_date_filter
        }

        const showModals = async(id) => {
            getDetail(id)
        }

        const getDetail = async(id) => {
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'GET')
            console.log("getDetail",resData)
            data.order_detail = resData.data
            data.loading = false
            data.showModal = true
        }
        
        const showPopup = () => {
              showForm.value = true;
        }

        const hidePopup=()=>{
                showForm.value=false
                data.generated_PayLink= ''
                selectedUser.value=''
                data.planId=''
                data.PaymentId=''
                pay_link_generated.value=false
        }

        const setUserId = (id) =>{
            data.userId= id
        }

        const setPlanId = (id) =>{
            data.planId= id
        }

        const setPaymentId = (id) =>{
            data.PaymentId=id
        }

        const generatePaylink = async () => {
    
                if(selectedUser.value.length <1){
                    notify({ text: "Please select user", type: "error" })
                }
                else if(data.planId==0){
                    notify({ text: "Please select Package", type: "error" })
                }
                else if(data.PaymentId==0){
                    notify({ text: "Please select Payment method", type: "error" })
                }
                
                else{
                    let _body = {
                        user_id: selectedUser.value.id,
                        plan_id: data.planId,
                        payment_method_id: data.PaymentId
                    }
                    let resData = await callPostApi(
                        data.generate_Paylink_endpoint,
                        "POST",
                        JSON.stringify(_body)
                    )
                    
                    if(resData.isSuccess){
                        notify({text: resData.message, type:'success'})
                        data.generated_PayLink = resData.pay_url
                        pay_link_generated.value=true
                        
                    }else{
                            notify({text: resData.message, type:'error'})
                    }
                }
        
        
        }

        const copyToClipboard = async () => {
            let toCopy = document.querySelector(
                "#pay_link"
            );
            /* Select the text field */
            toCopy.select();
            //toCopy.setSelectionRange(0, 200); /* For mobile devices */

            try {
                await navigator.clipboard.writeText(toCopy.value);
                notify({text: 'Copied to clipboard', type:'success'})
                //console.log(toCopy.value)
            } catch (err) {
                console.error(err)
                notify({text: ' Not Copied to clipboard', type:'error'})
            }
        }

        const copyPayLink = async (pay_link) => {
            let toCopy =pay_link;
            /* Select the text field */
            
            //toCopy.setSelectionRange(0, 200); /* For mobile devices */

            try {
                await navigator.clipboard.writeText(toCopy);
                notify({text: 'Copied to clipboard', type:'success'})
                console.log(toCopy)
            } catch (err) {
                console.error(err)
                notify({text: ' Not Copied to clipboard', type:'error'})
            }
        }

        const showModalOrderLookup = () => {
            data.showModalOrderLookup = true
        }

        const orderLookup = async() => {
            let client_id = data.order_lookup.client_id
            let transaction_id = data.order_lookup.transaction_id.trim()
            let check_status_type = data.order_lookup.check_status_type
            if(!client_id || !transaction_id){
                notify({text: 'Please fill form', type:'error'})
                return
            }

            data.loading = true

            data.order_lookup.transaction_id = data.order_lookup.transaction_id.trim()

            let query_filter = new URLSearchParams(data.order_lookup).toString()
            let endUlr = data.endpoint+'/lookup?'+query_filter
            let resData = await callApi(endUlr, 'GET')
            console.log("orderLookup ",resData)
            if(resData.isSuccess){
               data.order_lookup_detail = resData.data
            }else{
                notify({text: resData.message, type:'error'})
            }
            data.loading = false
        }


        return {
            data,
            locale,
            deleteRecord,
            getRecords,
            searchRecords,
            showFilters,
            resetFilters,
            changeDateTitle,
            showModals,
            showForm,
            showPopup,
            getUser,
            setPaymentId,
            setPlanId,
            setUserId,
            generatePaylink,
            pay_link_generated,
            options,
            selectedUser,
            copyToClipboard,
            hidePopup,
            copyPayLink,
            showPayLinkBtn,

            showModalOrderLookup,
            orderLookup,
        }
    }
}

</script>

<style scoped>
.pay-link{
    text-overflow: hidden;
    word-wrap: inherit;
    white-space: nowrap;
    overflow: hidden;
}
.pay_link_td{
    text-overflow: hidden;
    word-wrap: inherit;
    white-space: nowrap;
    overflow: hidden;
}

#pay_link{
    border: none;
    color: #0f5132;
    background-color: #d1e7dd;
    outline: none;
}
#pay_link:active{
    border: none;
    outline: none;
}
#table_pay_link{
    border: none;
  background-color: transparent;
    outline: none;
}
#table_pay_link:active{
    border: none;
    outline: none;
}
</style>