<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.reports}} {{locale.expired_soon}} {{locale.users}}</h2>
       <Breadcrumb :item_one_title="locale.manage+' '+locale.reports" :item_one_active="true" :loading="data.loading" />
       
        <button @click="showFilters" class="btn btn-outline-secondary mb-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>
        <button class=" btn btn-primary-custom ms-3 mb-3 ">
            <vue-excel-xlsx
                :data="data.Exceldata"
                :columns="data.columns"
                :file-name="'expired_soon_user_list'"
                :file-type="'xlsx'"
                :sheet-name="'expired_soon_user_list'"
                >
                {{locale.download_excel}}
            </vue-excel-xlsx>
        </button>
        <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-3">
                    <label class="form-label">{{locale.date}} {{locale.plan}}</label>
                    <select @change="changeDateTitle()" class="form-select" v-model="data.filters.date">
                        <option value="0">--</option>
                        <option value="1">{{locale.today}}</option>
                        <option value="8">{{locale.yesterday}}</option>
                        <option value="2">{{locale.last_7_days}}</option>
                        <option value="3">{{locale.last_30_days}}</option>
                        <option value="4">{{locale.last_90_days}}</option>
                        <option value="5">{{locale.last_365_days}}</option>
                        <option value="6">{{locale.last_2_years_days}}</option>
                        <option value="7">{{locale.last_3_years_days}}</option>
                        <option value="9">{{locale.custom_date_filter}}</option>
                    </select>
                </div>
                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.start}} {{locale.date}}</label>
                    <input v-model="data.filters.start_date" type="date" class="form-control" placeholder="12/18/2020"  aria-describedby="button-addon2">
                </div>

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.end}} {{locale.date}}</label>
                    <input v-model="data.filters.end_date" type="date" class="form-control" placeholder="12/18/2021"  aria-describedby="button-addon2">
                </div>


                 <div class="col-3">
                    <label class="form-label">{{locale.expired_soon}}</label>
                    <select @change="setDateEmpty()" class="form-select" v-model="data.filters.expired_soon">
                        <option value="0">--</option>
                        <option value="1">{{locale.tomorrow}}</option>
                        <option value="7">{{locale.seven_days}}</option>
                        <option value="30">{{locale.one_month}}</option>
                    </select>
                </div>

                 <!-- <div class="col-3">
                    <label class="form-label">{{locale.package}}</label>
                    <select class="form-select" v-model="data.filters.plan_id">
                        <option  v-for="(item,i) in data.plansList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                    </select>
                </div> -->

            </div>
            <button @click="searchRecords" class="btn btn-secondary my-3 " type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-3 mx-2" type="button" id="button-addon2">{{locale.reset}}</button>
            <hr>
        </div>
       
        

        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{locale.email}}</th>
                    <th scope="col">{{locale.plan}}</th>
                    <th scope="col">{{locale.date_created}}</th>
                    <th scope="col">{{locale.expired}} {{locale.at}}</th>
                
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,i) in data.records" v-bind:key="i" :class="item.is_deleted ? 'bg-danger': ''">
                    <td >{{item.id}}</td>
                    <td>
                        {{item.email}} ({{item.user_id}})
                    </td>
                    <td >{{item.user_plan_name}} </td>

                    <td v-if="item.end_date!=null">{{$formatDate(item.start_date)}} </td>
                    <td v-else>{{}}</td>
                    
                    <td v-if="item.start_date !=null">{{$formatDate(item.end_date )}} </td>
                    <td v-else>{{}}</td>
                    
                    </tr>
                </tbody>
            </table>

            <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                <b>{{locale.total}}: {{data.totalRecords}}</b>
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
        </div>

    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'

import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, getSystemPlansList} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'UsersExpiredSoon',
    components: {
      Breadcrumb,
      VPagination,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/reports/users/expired-soon',
            page : 1,
            perPage : 10,
            totalPages : 0,
            totalRecords : 0,
            records: [],
            loading: false,
            Exceldata:[],
            columns : [
                    {
                        label: "Email",
                        field: "email",
                        
                    },
                    {
                        label: "Plan",
                        field: "plan",
                    },
                    {
                        label: "Start At",
                        field: "start_date",
                    },
                    {
                        label: "Expired At",
                        field: "end_date",
                    }
                    
                ],

            showFilter: false,
            filters:{
                date: 1,
                start_date: '',
                end_date: '',
                plan_id: '',
                expired_soon: 0,
            },

            showModal : false,
            plansList: [],

            submitBtn: false,

            date_title : ''

        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_reports', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()

            data.plansList  = await getSystemPlansList()
            data.date_title = locale.value.today
        })

        const getRecords = async() => {
            data.loading = true
            let query_filter = new URLSearchParams(data.filters).toString()

            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
            console.log(resData)
            data.records = resData.data
            data.totalRecords = resData.totalRecords
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            
            data.Exceldata = data.records.map(u => ({ email: u.email, plan: u.user_plan_name, start_date: u.start_date.replace('Z', '').replace('T', ' ').split('.')[0], end_date: u.end_date.replace('Z', '').replace('T', ' ').split('.')[0] }))
            console.log("Excel Data", data.Exceldata)
            data.loading = false
        }

        const searchRecords = async() => {
            data.page = 1
            if(data.filters.date==9){
                if(data.filters.start_date == '' || data.filters.start_date == ''){
                    //notify({text: "Please select start date and end date",type:'error'})
                    return false
                }
            }
            getRecords()
        }
        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }

        const resetFilters = async() => {
            data.filters = {
                date: 0,
                expired_soon: 0,
                plan_id: '',
            }
            searchRecords()
            data.date_title = locale.value.today
        }

        const changeDateTitle = async() => {
            if(data.filters.date==1)
                data.date_title = locale.value.today
            else if(data.filters.date==2)
                data.date_title = locale.value.last_7_days
            else if(data.filters.date==3)
                data.date_title = locale.value.last_30_days
            else if(data.filters.date==4)
                data.date_title = locale.value.last_90_days
            else if(data.filters.date==5)
                data.date_title = locale.value.last_365_days
            else if(data.filters.date==6)
                data.date_title = locale.value.last_2_years_days
            else if(data.filters.date==7)
                data.date_title = locale.value.last_3_years_days
            else if(data.filters.date==8)
                data.date_title = locale.value.yesterday
            else if(data.filters.date==9)
                data.date_title = locale.value.custom_date_filter

            data.filters.expired_soon = 0
        }


        const setDateEmpty = async() => {
            data.filters = {
                date: 0,
                expired_soon: data.filters.expired_soon
            }
        }

        return {
            data,
            locale,
            getRecords,
            showFilters,
            resetFilters,
            searchRecords,
            changeDateTitle,
            setDateEmpty,
        }
    }
}

</script>

<style scoped>
.btn-primary-custom button{
  display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;

}


 
</style>