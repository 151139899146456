<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.languages}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.languages" :item_one_url="'/settings/languages'" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="row">

            <div class="col-6">
                <h3>{{locale.edit}} {{locale.title}} & {{locale.description}} {{locale.for}} {{locale.all}} {{locale.languages}}</h3>
                <ol class="list-group list-group-numbered">
                    <li class="list-group-item" v-for="(item,i) in data.languagesList" v-bind:key="i">
                        <div class="mb-3">
                            <label class="form-label">{{locale.title}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                            <input v-model="data.translationArr[item.lang_code]" type="text" class="form-control" required>
                        </div>
                    </li>
                </ol>
            </div>

            <div class="col-4">
                <div class="mb-3">
                    <label class="form-label">{{locale.code}}</label>
                    <input v-model="data.lang_code" type="text" class="form-control" placeholder="EN" disabled>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.sorting_order}}</label>
                    <input v-model="data.sorting_order" type="text" class="form-control" placeholder="1" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="2">{{locale.in_active}}</option>
                    </select>
                </div>
            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/settings/languages')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getLanguageList} from "@/api/api"

export default{
    name:'Edit',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/language/',
            recordId: '',
            translationArr : [],
            lang_code: '',
            sorting_order: '',
            status: 1,
            languagesList: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            data.recordId = route.query.id
            let resData = await callApi(data.endpoint+data.recordId, 'GET')
            if(resData.notLogin)
                router.push(`/login`)

            let record = resData.data
            data.recordId = record.id
            data.lang_code = record.lang_code
            data.sorting_order = record.sorting_order
            data.status = record.status
            resData.translationArr.forEach(item => {
              data.translationArr[item.language] = item.txt
            })
            data.languagesList  = await getLanguageList()
        })

        const submit = async() => {
            let tempTranslationArr = []
            for(const property in data.translationArr){
                if(property!=null){
                    let tmpObj = {
                        language_code : property,
                        title : data.translationArr[property],
                        description :  ''
                    }
                    tempTranslationArr.push(tmpObj)
                }
            }
           
            if(tempTranslationArr.length == 0){
                notify({
                    text: "Please add title",
                    type:'error'
                });
                return false
            }

            data.submitBtn = true
            let _body = {
                tempTranslationObj: tempTranslationArr,
                lang_code: data.lang_code,
                sorting_order: data.sorting_order,
                status: data.status
            }
            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/settings/languages')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>