<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.assign_permissions}}</h2>
        <Breadcrumb :item_one_title="locale.assign_permissions" :item_one_url="'/settings/assign-permissions'" :item_two_title="locale.add" :item_two_active="true" />
        <div class="w-50">
            <div class="mb-3">
                <label class="form-label">{{locale.select}} {{locale.role}}</label>
                <select class="form-select" v-model="data.selected_role" @change="changeDropdown($event,'selected_role')">
                    <option v-for="(item,i) in data.records" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                </select>
            </div>

            <div class="mb-3" v-if="data.selected_role">
                <div class="form-check" v-for="(item,i) in data.permissionsRecords" v-bind:key="i">
                    <input v-model="data.checkedPermissionsArr" class="form-check-input" type="checkbox" :value="item.id" :id="item.id">
                    <label class="form-check-label" for="flexCheckDefault">
                        {{item.name}}
                    </label>
                </div>
            </div>
            <div class="mb-3">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/settings/assign-permissions')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getRoles} from "@/api/api"

export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/permissions-assign',
            selected_role: '',
            name: '',
            records: [],
            permissionsRecords: [],
            checkedPermissionsArr: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            getRecords()
            getPermissionsRecords()

            if(route.query.id){
                data.selected_role = route.query.id
                getPermissionsRecordsByRole()
            }

        })

        const submit = async() => {
            if(data.selected_role == ''){
                notify({
                    text: "Please select role",
                    type:'error'
                });
                return false
            }
            data.submitBtn = true
            let _body = {
                role_id: data.selected_role,
                assign_roles: data.checkedPermissionsArr.toString()
            }
            let resData = await callPostApi(data.endpoint,'POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/settings/assign-permissions')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        const changeDropdown = async (event, property_name) => {
            if(property_name=='selected_role'){
                data.selected_role = event.target.value
                getPermissionsRecordsByRole()
            }
        }

        const getRecords = async() => {
            let resData = await getRoles()
            //Remove admin role from list
            resData.pop()
            data.records = resData
        }

        const getPermissionsRecords = async() => {
            let resData = await callApi('/permissions?all', 'GET')
            data.permissionsRecords = resData.data
        }

        const getPermissionsRecordsByRole = async() => {
            let resData = await callApi('/roles/'+data.selected_role, 'GET')
            data.checkedPermissionsArr = resData.rolesPermissionsArr
        }

        return {
            data,
            locale,
            submit,
            changeDropdown,
        }
    }
}

</script>