<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.reports}} {{locale.download}} {{locale.stats}}</h2>
       <Breadcrumb :item_one_title="locale.manage+' '+locale.reports" :item_one_active="true" :loading="data.loading" />
       
        <div class="row mt-3 mb-3">
            <div class="col-4">
                <div class="card text-center">
                    <div class="card-header bg-primary text-white h5">
                       {{data.date_title}}  {{locale.download}} {{locale.stats}} 
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{data.totalRecords}}</h5>
                    </div>
                </div>
            </div>
        </div>

        <button @click="showFilters" class="btn btn-outline-secondary mb-3" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>

        <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-2">
                    <label class="form-label">{{locale.date}}</label>
                    <select @change="changeDateTitle()" class="form-select" v-model="data.filters.date">
                        <option value="1">{{locale.today}}</option>
                        <option value="8">{{locale.yesterday}}</option>
                        <option value="2">{{locale.last_7_days}}</option>
                        <option value="3">{{locale.last_30_days}}</option>
                        <option value="4">{{locale.last_90_days}}</option>
                        <option value="5">{{locale.last_365_days}}</option>
                        <option value="6">{{locale.last_2_years_days}}</option>
                        <option value="7">{{locale.last_3_years_days}}</option>
                        <option value="9">{{locale.custom_date_filter}}</option>
                    </select>
                </div>

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.start}} {{locale.date}}</label>
                    <input v-model="data.filters.start_date" type="date" class="form-control" placeholder="12/18/2020"  aria-describedby="button-addon2">
                </div>

                <div class="col-2" v-if="data.filters.date==9">
                    <label class="form-label">{{locale.end}} {{locale.date}}</label>
                    <input v-model="data.filters.end_date" type="date" class="form-control" placeholder="12/18/2021"  aria-describedby="button-addon2">
                </div>
                

                <div class="col-3">
                    <label class="form-label">{{locale.search}}</label>
                    <input v-model="data.filters.keywords" type="text" class="form-control" placeholder="by id, userID, ip, url"  aria-describedby="button-addon2">
                </div>
                <div class="col-2">
                    <label class="form-label">{{locale.source_type }}</label>
                    <select class="form-select" v-model="data.filters.source_type ">
                        <option value="1">Website</option>
                        <option value="2">PC</option>
                    </select>
                </div>
                 <div class="col-2">
                    <label class="form-label">{{locale.clients}}</label>
                    <select class="form-select" v-model="data.filters.client_id">
                        <option  v-for="(item,i) in data.clientsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>
                <div class="col-2">
                    <label class="form-label">{{locale.channels}}</label>
                    <select class="form-select" v-model="data.filters.channel_id">
                        <option  v-for="(item,i) in data.channelsList" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                    </select>
                </div>
            </div>
            
            <button @click="searchRecords" class="btn btn-secondary my-3 " type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-3 mx-2" type="button" id="button-addon2">{{locale.reset}}</button>
            <hr>

        </div>
        

        <div class="table-responsive">
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{locale.ip}}</th>
                    <th scope="col">{{locale.source}} {{locale.type}}</th>
                    <th scope="col">{{locale.client}}</th>
                    <th scope="col">{{locale.user}}</th>
                    <th scope="col">{{locale.url}}</th>
                    <th scope="col">{{locale.channels}}</th>
                    <th scope="col">{{locale.action}} {{locale.id}}</th>
                    <th scope="col">{{locale.date_created}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,i) in data.records" v-bind:key="i" :class="item.is_deleted ? 'bg-danger': ''">
                    <td >{{item.id}}</td>
                    <td>{{item.ip}}</td>
                    <td>{{item.source_type  == 1 ? 'Website' : 'PC' }}</td>
                    <td>{{item.client_name}}</td>
                    <td>{{item.email}} ({{item.session}})</td>
                    <td>{{item.url}}</td>
                    <td>{{item.channel_name}}</td>
                    <td>{{item.action_id}}</td>
                    <td>{{ $moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
            </div>
        </div>

    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi, callPostApi, getChannelsList, getClientsList} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'TodayRegistered',
    components: {
      Breadcrumb,
      VPagination,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/reports/downloads/stats',
            page : 1,
            perPage : 10,
            totalPages : 0,
            totalRecords : 0,
            records: [],
            loading: false,

            showFilter: false,
            filters:{
                date: 1,
                start_date: '',
                end_date: '',
                keywords : '',
                client_id: '',
                channel_id: '',
                source_type : 1,
            },

            clientsList: [],
            channelsList: [],

            showModal : false,
            plansList: [],

            submitBtn: false,

            date_title : ''

        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            let isAllowAccessPage = await checkAccessSinglePage('manage_reports', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()

            data.channelsList  = await getChannelsList()
            data.clientsList  = await getClientsList()

            data.date_title = locale.value.today
        })

        const getRecords = async() => {
            data.loading = true
            let query_filter = new URLSearchParams(data.filters).toString()

            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
            console.log(resData)
            data.records = resData.data
            data.totalRecords = resData.totalRecords
            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.loading = false
        }


        const searchRecords = async() => {
            data.page = 1

            if(data.filters.date==9){
                if(data.filters.start_date == '' || data.filters.start_date == ''){
                    notify({text: "Please select start date and end date",type:'error'})
                    return false
                }
            }
            getRecords()
        }

        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }

        const resetFilters = async() => {
            data.filters = {
                start_date: '',
                end_date: '',
                keywords : '',
                client_id: '',
                channel_id: '',
                source_type: '',
            }
            searchRecords()

            data.date_title = locale.value.today
        }

        const changeDateTitle = async() => {
            if(data.filters.date==1)
                data.date_title = locale.value.today
            else if(data.filters.date==2)
                data.date_title = locale.value.last_7_days
            else if(data.filters.date==3)
                data.date_title = locale.value.last_30_days
            else if(data.filters.date==4)
                data.date_title = locale.value.last_90_days
            else if(data.filters.date==5)
                data.date_title = locale.value.last_365_days
            else if(data.filters.date==6)
                data.date_title = locale.value.last_2_years_days
            else if(data.filters.date==7)
                data.date_title = locale.value.last_3_years_days
            else if(data.filters.date==8)
                data.date_title = locale.value.yesterday
            else if(data.filters.date==9)
                data.date_title = locale.value.custom_date_filter
        }

        return {
            data,
            locale,
            getRecords,
            searchRecords,
            showFilters,
            resetFilters,
            changeDateTitle,
        }
    }
}

</script>