<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage_admin_users}}</h2>
        <Breadcrumb :item_one_title="locale.manage_admin_users" :item_one_url="'/settings/admin-users'" :item_two_title="locale.add" :item_two_active="true" />
        <div class="w-50">
            <div class="mb-3">
                <label class="form-label">{{locale.username}}</label>
                <input v-model="data.username" type="text" class="form-control" placeholder="abc" required>
            </div>
            <label class="form-label">{{locale.password}}</label>
            <div class="input-group mb-3">
                    <input v-model="data.password" type="password" id="password" class="form-control"  required>
                    <span class="input-group-text cursor-pointer " @click="switchVisibility()">
                        <div class="eye"></div>
                    </span>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.email}}</label>
                <input v-model="data.email" type="text" class="form-control" placeholder="test@swoshs.com" required>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.phone}}</label>
                <input v-model="data.phone" type="text" class="form-control" placeholder="+971 526401286" required>
            </div>

            <div class="mb-3">
                <label class="form-label">{{locale.role}}</label>
                <select class="form-select" v-model="data.selected_role" @change="changeDropdown($event,'selected_role')">
                    <option selected>{{locale.select}} {{locale.role}}</option>
                    <option v-for="(item,i) in data.records" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                </select>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.status}}</label>
                <select class="form-select" v-model="data.selected_status">
                    <option value="1">{{locale.active}}</option>
                    <option value="2">{{locale.in_active}}</option>
                </select>
            </div>

            <div class="mb-3">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/settings/admin-users')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>

    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import {callApi,callPostApi, getRoles} from "@/api/api"

export default{
    name:'ManageAdminUsersAdd',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
       // const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            username: '',
            password: '',
            email: '',
            phone: '',
            selected_role: 3,
            selected_status: 1,
            selected_lang: null,
            records: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)

            let resData = await getRoles()
            data.records = resData
        })

        const submit = async() => {
            if(data.username == '' || data.password == ''){
                notify({
                    text: "Please enter username and password",
                    type:'error'
                });
                return false
            }
            data.submitBtn = true
            let _body = {
                username: data.username,
                password: data.password,
                email: data.email,
                phone: data.phone,
                role_id: data.selected_role,
                status: data.selected_status,
            }
            let resData = await callPostApi('/admin/users','POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/settings/admin-users')
            }else{
                notify({text: resData.message, type:'error'})
            }

        }

        const changeDropdown = async (event, property_name) => {
            if(property_name=='selected_role'){
                data.selected_role = event.target.value
            }else if(property_name=='selected_status'){
                data.selected_status = event.target.value
            }
        }

        const switchVisibility = () => {
            const passwordField = document.querySelector('#password')
            passwordField.getAttribute('type')==='password' ? passwordField.setAttribute('type','text') : passwordField.setAttribute('type','password')
        }

        return {
            data,
            locale,
            changeDropdown,
            switchVisibility,
            submit,
        }
    }
}

</script>