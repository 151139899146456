<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
       <h2>{{locale.manage}} {{locale.languages}}</h2>
       <Breadcrumb :item_one_title="locale.manage +' '+ locale.languages" :item_one_active="true" :loading="data.loading" />
       <button @click="$redirect('/settings/languages/add')" type="button" class="btn btn-secondary btn-sm">{{locale.add}}</button>

      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{locale.name}}</th>
              <th scope="col">{{locale.code}}</th>
              <th scope="col">{{locale.sorting_order}}</th>
              <th scope="col">{{locale.status}}</th>
              <th scope="col">{{locale.action}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{item.id}}</td>
                <td>{{item.name}}</td>
                <td>{{item.lang_code}}</td>
                <td>{{item.sorting_order}}</td>
               <td> <span class="badge bg-light text-dark rounded-pill">{{item.status == 1 ? locale.active : locale.in_active}}</span></td>
                <td>
                   <button @click="$redirect('/settings/languages/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm">{{locale.edit}}</button>
                   <button @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm ms-1">{{locale.delete}}</button>
                </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi,callPostApi} from "@/api/api"
import {checkAccessSinglePage} from '@/utils/utils'


export default{
    name:'ManageLanguage',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/language',
            records: [],
            loading: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)

            let isAllowAccessPage = await checkAccessSinglePage('manage_settings', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()
        })

        const getRecords = async() => {
            data.loading = true
            let resData = await callApi(data.endpoint, 'GET')
            data.records = resData.data
            data.loading = false
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
            }else{
                notify({text: resData.message, type:'error'})
            }
          }
        }
        return {
            data,
            locale,
            deleteRecord,
        }
    }
}

</script>