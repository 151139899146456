


let apiHostUrl = 'http://localhost:9000/console'
//console.log("process.env ", process.env)
//console.log("window.location.href",window.location.href)

//if(process.env.NODE_ENV=='production'){
    apiHostUrl = 'https://console.iotegrity.net/console'
//}
//else 

if(process.env.NODE_ENV=='development'){
    apiHostUrl = 'https://console.dev.iotegrity.net/console'
}

if(window.location.href.indexOf("localhost") >= 0 || window.location.href.indexOf("192.168.0.111") >= 0) {
    apiHostUrl= 'http://localhost:9000/console'
}else if(window.location.href.indexOf("dev") >= 0){
    apiHostUrl = 'https://console.dev.iotegrity.net/console'
}
else{
    apiHostUrl = 'https://console.iotegrity.net/console'
}

//console.log("apiHostUrl", apiHostUrl)

export const apiHost = apiHostUrl

export const apiUploadUrl = apiHostUrl + '/upload/ckeditor'