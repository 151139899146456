<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.servers}}</h2>
        <Breadcrumb :item_one_title="locale.manage +' '+ locale.servers" :item_one_active="true" :loading="data.loading" />
        <button @click="$redirect('/servers/list/add')" type="button" class="btn btn-secondary mb-3 ms-3 btn-sm">{{locale.add}}</button>

        <button @click="showFilters" class="btn btn-outline-secondary mb-3 ms-3 btn-sm" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">{{locale.filters}}</button>
        <button @click="getRecords()" type="button" class="btn btn-success mb-3 ms-3 btn-sm">{{locale.refresh}}</button>

        <div v-if="data.showFilter" class="collapse show" id="collapseExample">
            <div class="row">
                <div class="col-3">
                    <label class="form-label">{{locale.search}}</label>
                    <input v-model="data.filters.keywords" type="text" class="form-control" placeholder="by Name, IP, MID"  aria-describedby="button-addon2">
                </div>
                <div class="col-2">
                     <label class="form-label">{{locale.servers}} {{locale.areas}}</label>
                    <select class="form-select" v-model="data.filters.server_area_id">
                        <option v-for="(item,i) in data.serversAreasList" v-bind:key="i"  :value="item.id">{{item.title}}</option>
                    </select>
                </div>
                <div class="col-2">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.filters.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="2">{{locale.in_active}}</option>
                    </select>
                </div>
                
                <div class="col-2">
                    <label class="form-label">{{locale.allowed}} {{locale.for}} {{locale.free}} </label>
                    <select class="form-select" v-model="data.filters.allowed_for_free">
                      <option value="1">{{locale.yes}}</option>
                        <option value="0">{{locale.no}}</option>
                        
                    </select>
                </div>
            </div>
            <button @click="searchRecords" class="btn btn-secondary my-3 " type="button" id="button-addon2">{{locale.search}}</button>
            <button @click="resetFilters" class="btn btn-secondary my-3 mx-2" type="button" id="button-addon2">{{locale.reset}}</button>
            <hr>
        </div>
        <button class=" btn btn-primary-custom ms-3 mb-3 ">
            <vue-excel-xlsx
                :data="data.Exceldata"
                :columns="data.columns"
                :file-name="'server_list'"
                :file-type="'xlsx'"
                :sheet-name="'Servers List'"
                >
                {{locale.download_excel}}
            </vue-excel-xlsx>
            <span class="m-3">{{locale.total_free_servers}} {{locale.active}}: {{data.total_free_servers}}</span>
            <span class="m-3">{{locale.total_premium_servers}} {{locale.active}}: {{data.total_premium_servers}}</span>
            <span class="m-3">{{locale.total_pro_servers}} {{locale.active}}: {{data.total_pro_servers}}</span>
        </button>

    <!-- <span class="mt-0">{{locale.total_free_servers}}: {{data.total_free_servers}}</span>
    <span class="m-3">{{locale.total_premium_servers}}: {{data.total_premium_servers}}</span> -->

      <div class="table-responsive" id="table-body" >
        <table class="table table-striped table-hover" >
          <thead style="position: sticky;top: 0; background-color:white; border-bottom: 2px solid black">
            <tr>
              <th class="header" scope="col">#</th>
              <th class="header" scope="col">{{locale.name}}</th>
              <th class="header" scope="col">{{locale.servers}} {{locale.areas}}</th>
              <th class="header" scope="col">IP</th>
              <th class="header" scope="col">{{locale.servers}} {{locale.types}}</th>
              <th class="header" scope="col">{{locale.status}}</th>
              <th class="header" scope="col">{{locale.allowed}} {{locale.for}} {{locale.free}}</th>
              <th class="header" scope="col">{{locale.sorting_order}}</th>
              <th class="header" scope="col">{{locale.mid}}</th>
              <th class="header" scope="col">{{locale.restricted_countries}}</th>
              <th class="header" scope="col">{{locale.allowed_countries}}</th>
              <th class="header" scope="col">{{locale.network_bandwidth}}</th>
              <th class="header" scope="col">{{locale.load}}</th>
              <th class="header" scope="col">{{locale.version}}</th>
              <th class="header" scope="col">{{locale.action}}</th>
            </tr>
          </thead>
         
          <tbody >
            <tr v-for="(item,i) in data.records" v-bind:key="i">
                <td>{{item.id}}</td>
                <td>
                    {{item.title}}  
                    <i v-if="item.allowed_for_free==0"   title="Allowed for Premium/Pro Users" v-html="icon_trophy"></i>
                    <i v-if="item.allowed_for_pro==1 && item.allowed_for_free==2"   title="Allowed for SwoshsVPN Pro" v-html="icon_diamond"></i>
                </td>
                <td>{{item.server_area_title}}</td>
                <td>{{item.ip}} ({{item.port}})</td>
                <td>{{item.servers_types_title}}</td>
                <td> <span class="badge bg-light text-dark rounded-pill">{{item.status == 1 ? locale.active : locale.in_active}}</span></td>
                <td> <span class="badge bg-dark text-white rounded-pill">{{item.allowed_for_free == 1 ? locale.yes : item.allowed_for_free == 0 ? locale.no : locale.pro}}</span></td>
                <td>{{item.sorting_order}}</td>
                
                <td>{{item.mid}}</td>
                <td>{{item.restricted_countries}}</td>
                <td>{{item.allowed_countries}}</td>
                <td>{{item.max_network_bandwidth}}</td>
                <td>{{item.server_load}}</td>
                <td>{{item.node_version}}</td>
                <td>
                   <!-- <button @click="$redirect('/servers/list/edit/?id='+item.id)" type="button" class="btn btn-warning btn-sm">{{locale.edit}}</button> -->
                   <a :href="'/servers/list/edit/?id='+item.id" @click="$redirect('/servers/list/edit/?id='+item.id)" class="btn btn-warning btn-sm">{{locale.edit}}</a>
                   <button @click="deleteRecord(item.id)" type="button" class="btn btn-danger btn-sm ms-1">{{locale.delete}}</button>
                </td>
            </tr>
          </tbody>
        </table>

          <div class="d-flex mb-5">
                <div class="col-10">
                    <v-pagination
                    v-model="data.page"
                    :pages="data.totalPages"
                    :range-size="2"
                    active-color="#d2f4ea"
                    @update:modelValue="getRecords"
                />
                <b>{{locale.total}}: {{data.totalRecords}}</b>
                </div>
                <div class="col-2">
                    <select @change="getRecords()" class="form-select" v-model="data.perPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </div>
          </div>

      </div>
    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store';
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification"
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callApi,callPostApi, getServersAreasList} from "@/api/api"
import VPagination from "@hennge/vue3-pagination"
import "@hennge/vue3-pagination/dist/vue3-pagination.css"
import {checkAccessSinglePage} from '@/utils/utils'

export default{
    name:'ManageServersTypes',
    components: {
      Breadcrumb,
      VPagination,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/servers',
            page : 1,
            perPage : 200,
            totalPages : 0,
            totalRecords : 0,
            total_premium_servers:0,
            total_free_servers:0,
            total_pro_servers:0,
            keywords : '',
            records: [],
            Exceldata:[],
            loading: false,
            columns : [
                    {
                        label: "ID",
                        field: "id",
                        
                    },
                    {
                        label: "Name",
                        field: "name",
                        
                    },
                    {
                        label: "Server Areas",
                        field: "servers_areas",
                    },
                    {
                        label: "IP",
                        field: "IP",
                    },
                    {
                        label: "Servers Types",
                        field: "servers_types",
                    },
                    {
                        label: "Status",
                        field: "status",
                    },
                     {
                        label: "Allowed for free",
                        field: "allowed_for_free",
                    },
                     {
                        label: "Sorting Order",
                        field: "sorting_order",
                    },
                     {
                        label: "MID",
                        field: "mid",
                    },
                     {
                        label: "Restricted Country",
                        field: "restricted_countries",
                    },
                    {
                        label: "Allowed Country",
                        field: "allowed_countries",
                    },

                     {
                        label: "Network Bandwidth",
                        field: "network_bandwidth",
                    },
                    {
                        label: "Server Load",
                        field: "server_load"
                    }
                ],

            showFilter: false,
            filters:{
                keywords : '',
                server_area_id: '',
                allowed_for_free: '',
                status: '',
            },



            serversAreasList: [],

        })

        onMounted( async() =>{
            if(!auth.value)
                router.push(`/login`)

            let isAllowAccessPage = await checkAccessSinglePage('manage_servers', authUser.value)
            if(!isAllowAccessPage)
                await router.push('/')

            getRecords()

            data.serversAreasList   = await getServersAreasList()

        })

        const getRecords = async() => {
            data.loading = true
            
            let query_filter = new URLSearchParams(data.filters).toString()
            console.log("query_filter", query_filter)
            let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&"+query_filter, 'GET')
            console.log("resData, ", resData)
            //let resData = await callApi(data.endpoint+'?page='+data.page+'&perPage='+data.perPage+"&keywords="+data.keywords, 'GET')
           
            data.records = resData.data
            console.log(data.records)
            data.totalRecords = resData.totalRecords
            data.total_free_servers= resData.totalFreeServers
            data.total_pro_servers= resData.totalProServers
            data.total_premium_servers= resData.totalPremiumServers

            data.totalPages = Math.ceil(resData.totalRecords/data.perPage)
            data.Exceldata = data.records.map(u => ({ id: u.id, servers_areas: u.server_area_title, IP: u.ip, servers_types: u.servers_types_title, restricted_countries: u.restricted_countries, status:u.status==1? 'Active': 'In Active',
                    allowed_for_free: u.allowed_for_free==1? 'yes': 'No', sorting_order: u.sorting_order, name:u.title, mid: u.mid, network_bandwidth: u.max_network_bandwidth, 
                    server_load:u.server_load }));
            data.loading = false
            console.log("excel table data",data.Exceldata)
        }

        const deleteRecord = async(id)=>{
          if(confirm(locale.value.confirm_delete)){
            data.loading = true
            let resData = await callApi(data.endpoint+'/'+id, 'DELETE')
            data.loading = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                getRecords()
            }else{
                notify({text: resData.message, type:'error'})
            }
          }
        }


        const searchRecords = async() => {
            data.page = 1
            getRecords()
        }
        const showFilters = async() => {
            data.showFilter = data.showFilter ? false : true
        }
        const resetFilters = async() => {
            data.filters = {
                keywords : '',
                server_area_id: '',
                allowed_for_free: '',
                status: '',
            }
            searchRecords()
        }

        return {
            data,
            locale,
            deleteRecord,
            getRecords,
            searchRecords,
            showFilters,
            resetFilters,
        }
    }
}

</script>

<style scoped>
.btn-primary-custom button{
  display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;

}
#table-body{
    max-height: 780px;
    height: 780px;
    min-height: 400px;
    
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


/* @media screen and (min-width:993px){
#table-body::-webkit-scrollbar {
  display: none;
} 
 #table-body {
  -ms-overflow-style: none;
  scrollbar-width: none; 
}
} */

 
</style>