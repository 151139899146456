<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage_roles}} | {{locale.add}}</h2>
        <Breadcrumb :item_one_title="locale.manage_roles" :item_one_url="'/settings/roles'" :item_two_title="locale.add" :item_two_active="true" />
        <div class="w-50">
            <div class="mb-3">
                <label class="form-label">{{locale.name}}</label>
                <input v-model="data.name" type="text" class="form-control" placeholder="Access user" required>
            </div>
            <div class="mb-3">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/settings/roles')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>
        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"
import {callPostApi} from "@/api/api"

export default{
    name:'ManageRolesAdd',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/roles',
            name: '',
            records: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
        })

        const submit = async() => {
            if(data.name == ''){
                notify({
                    text: "Please enter name",
                    type:'error'
                });
                return false
            }
            data.submitBtn = true
            let _body = {
                name: data.name
            }
            let resData = await callPostApi(data.endpoint,'POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/settings/roles')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        return {
            data,
            locale,
            submit,
        }
    }
}

</script>