<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage_admin_users}}</h2>
        <Breadcrumb :item_one_title="locale.manage_admin_users" :item_one_url="'/admin-users'" :item_two_title="locale.edit" :item_two_active="true" />
        <div class="w-50">
            <div class="mt-3 mb-3">
                <label class="form-label">{{locale.username}}:&nbsp;</label>
                <b>{{data.username}}</b>
            </div>
            
             <div class="mb-3">
                <label class="form-label">{{locale.email}}</label>
                <input v-model="data.email" type="text" class="form-control" placeholder="test@swoshs.com" required>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.phone}}</label>
                <input v-model="data.phone" type="text" class="form-control" placeholder="+971 5xxxxxxxx" required>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.role}}</label>
                <select class="form-select" v-model="data.selected_role" @change="changeDropdown($event,'selected_role')">
                    <option selected>{{locale.select}} {{locale.role}}</option>
                    <option v-for="(item,i) in data.records" v-bind:key="i"  :value="item.id">{{item.name}}</option>
                </select>
            </div>
            <div class="mb-3">
                <label class="form-label">{{locale.status}}</label>
                <select class="form-select" v-model="data.selected_status">
                    <option value="1">{{locale.active}}</option>
                    <option value="2">{{locale.in_active}}</option>
                </select>
            </div>

            <label class="form-label">{{locale.password}} <small>({{locale.password_change_info}})</small></label>
            <div class="input-group mb-3">
                    <input v-model="data.newpassword" type="password" id="password" class="form-control"  required>
                    <span class="input-group-text cursor-pointer " @click="switchVisibility()">
                        <div class="eye"></div>
                    </span>
            </div>

            <div class="mb-3">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/settings/admin-users')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>

    </main>
</template>


<script>

import {onMounted, reactive, computed} from "vue"
import { useStore } from '@/store'
import {useRouter, useRoute} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import {callApi,callPostApi,getRoles} from "@/api/api"

export default{
    name:'ManageAdminUsersEdit',
    components: {
      Breadcrumb,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const auth = computed( () => store.state.authenticated)
       // const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            endpoint: '/admin/users/',
            recordId: '',
            username: '',
            newpassword: '',
            email: '',
            phone: '',
            selected_role: 3,
            selected_status: 1,
            selected_lang: null,
            records: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
           
            let resData = await getRoles()
            data.records = resData

            data.recordId = route.query.id
            let resDataDetail = await callApi(data.endpoint+data.recordId, 'GET')
            let user = resDataDetail.data
            data.recordId = user.id
            data.username =  user.username
            data.email = user.email
            data.phone = user.phone
            data.selected_role = user.role_id
            data.selected_status = user.status
            data.selected_lang =  user.preferred_language

        })

        const submit = async() => {
            data.submitBtn = true
            let _body = {
                username: data.username,
                role_id: data.selected_role,
                status: data.selected_status,
            }
            if(data.newpassword){
                _body.newpassword = data.newpassword
            }
            if(data.email){
                _body.email = data.email
            }
            if(data.phone){
                _body.phone = data.phone
            }

            let resData = await callPostApi(data.endpoint+data.recordId,'PATCH',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/settings/admin-users')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }

        const changeDropdown = async (event, property_name) => {
            if(property_name=='selected_role'){
                data.selected_role = event.target.value
            }else if(property_name=='selected_status'){
                data.selected_status = event.target.value
            }
        }

        const switchVisibility = () => {
            const passwordField = document.querySelector('#password')
            passwordField.getAttribute('type')==='password' ? passwordField.setAttribute('type','text') : passwordField.setAttribute('type','password')
        }

        return {
            data,
            locale,
            changeDropdown,
            switchVisibility,
            submit,
        }
    }
}

</script>