<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <h2>{{locale.manage}} {{locale.payment}} {{locale.methods}}</h2>
        <Breadcrumb :item_one_title="locale.manage+' '+locale.payment+' '+locale.methods" :item_one_url="'/payment-methods'" :item_two_title="locale.add" :item_two_active="true" />

        <div class="row">

            <div class="col-6">
                <h3>{{locale.add}} {{locale.title}} & {{locale.description}} {{locale.for}} {{locale.all}} {{locale.languages}}</h3>
                <ol class="list-group list-group-numbered">
                    <li class="list-group-item" v-for="(item,i) in data.languagesList" v-bind:key="i">
                        <div class="mb-3">
                            <label class="form-label">{{locale.title}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                            <input v-model="data.translationArr[item.lang_code]" type="text" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">{{locale.description}} <span class="badge bg-light text-dark">{{item.name}}</span></label>
                            <input v-model="data.translationDescriptionArr[item.lang_code]" type="text" class="form-control" required>
                        </div>
                    </li>
                </ol>
            </div>

            <div class="col-4">
                <div class="mb-3">
                    <label class="form-label">{{locale.key}} {{locale.name}}</label>
                    <input v-model="data.key_name" type="text" class="form-control" placeholder="stripe" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.sorting_order}}</label>
                    <input v-model="data.sorting_order" type="text" class="form-control" placeholder="1" required>
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.icon}}</label>
                    <input accept="image/*" ref="file"  v-on:change="handleFileUpload()"  type="file" class="form-control">
                </div>
                <div class="mb-3">
                    <label class="form-label">{{locale.mode}}</label>
                    <select class="form-select" v-model="data.mode">
                        <option value="1">{{locale.live}}</option>
                        <option value="2">{{locale.test}}</option>
                    </select>
                </div>
                <!-- <div class="mb-3">
                    <label class="form-label">{{locale.status}}</label>
                    <select class="form-select" v-model="data.status">
                        <option value="1">{{locale.active}}</option>
                        <option value="0">{{locale.in_active}}</option>
                    </select>
                </div> -->

                <div class="mb-3">
                    <label class="form-label">{{locale.manage_currency}}</label>
                    <select class="form-select" v-model="data.currency_id">
                        <option  v-for="(item,i) in data.currencyList" v-bind:key="i"  :value="item.id">{{item.name}} ({{item.symbol}})</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label">{{locale.data}}</label>
                   <textarea v-model="data.payment_method_data" class="form-control" placeholder="{}"></textarea>
                </div>

            </div>

            <div class="mb-3 mt-5">
                <button @click="submit" type="button" class="btn btn-secondary btn-sm">{{locale.submit}}<span v-if="data.submitBtn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                <button @click="$redirect('/payment-methods')" type="button" class="btn btn-secondary btn-sm ms-1">{{locale.go_back}}</button>
            </div>

        </div>
    </main>
</template>

<script>

import {onMounted, reactive, computed, ref} from "vue"
import { useStore } from '@/store'
import {useRouter} from 'vue-router'
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue"

import {callPostApi, getLanguageList, getCurrencyList, uploadFile} from "@/api/api"

export default{
    name:'Add',
    components: {
      Breadcrumb,
    },
    setup() {
        const file = ref(null)
        const store = useStore()
        const router = useRouter()
        const auth = computed( () => store.state.authenticated)
        //const authUser = computed( () => store.state.authUser)
        const locale = computed(() => store.state.translation.locale)

        const data = reactive({
            translationArr : [],
            translationDescriptionArr : [],
            key_name: '',
            sorting_order: '',
            mode: 2,
            status: 0,
            icon: 'default.png',
            currency_id: 1,
            payment_method_data: '',
            
            languagesList: [],
            currencyList: [],
            submitBtn: false,
        })

        onMounted( async() =>{
            if(!auth.value)
                 router.push(`/login`)
            
            data.languagesList  = await getLanguageList()
            data.currencyList = await getCurrencyList()
        })

        const submit = async() => {

            let tempTranslationArr = []
            for(const property in data.translationArr){
                if(property!=null){
                    let tmpObj = {
                        language_code : property,
                        title : data.translationArr[property],
                        description : data.translationDescriptionArr[property] || ''
                    }
                    tempTranslationArr.push(tmpObj)
                }
            }


            if(tempTranslationArr.length == 0){
                notify({
                    text: "Please add title",
                    type:'error'
                });
                return false
            }
            data.submitBtn = true
            let _body = {
                tempTranslationObj: tempTranslationArr,
                key_name: data.key_name,
                sorting_order: data.sorting_order || 0,
                mode: data.mode,
                status: data.status,
                data: data.payment_method_data,
                icon: data.icon,
                currency_id: data.currency_id,
            }

            let resData = await callPostApi('/paymentmethods','POST',JSON.stringify(_body))
            data.submitBtn = false
            if(resData.isSuccess){
                notify({text: resData.message, type:'success'})
                router.push('/payment-methods')
            }else{
                notify({text: resData.message, type:'error'})
            }
        }
        const handleFileUpload = async() => {
            //debugger
            data.submitBtn = true
            try{
                const file_upload = file.value.files[0]
                const pattern = /image-*/
                if (file_upload && !file_upload.type.match(pattern)) {
                    notify({text: "Invalid image", type:'error'})
                    data.submitBtn = false
                    return
                }else{
                    const formData = new FormData()
                    formData.append('folder_name', 'client/paymentmethods')
                    formData.append('upload', file_upload)
                    let resData = await uploadFile(formData)
                    data.icon = resData.file_url
                    data.submitBtn = false
                }
            }catch(err){
                data.submitBtn = false
                console.log("image upload error",err)
            }
        }


        return {
            data,
            locale,
            submit,
            handleFileUpload,
            file
        }
    }
}

</script>