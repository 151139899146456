<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <h2>{{ locale.manage }} {{ locale.users }}</h2>
    <Breadcrumb
      :item_one_title="locale.manage + ' ' + locale.users"
      :item_one_active="true"
      :loading="data.loading"
    />

    <button
      @click="showFilters"
      class="btn btn-outline-secondary mb-3"
      type="button"
      data-toggle="collapse"
      data-target="#collapseExample"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      {{ locale.filters }}
    </button>
    <button
      @click="getRecords()"
      type="button"
      class="btn btn-success mb-3 ms-3"
    >
      {{ locale.refresh }}
    </button>
    <div v-if="data.showFilter" class="collapse show" id="collapseExample">
      <div class="row">
        <div class="col-3">
          <label class="form-label">{{ locale.search }}</label>
          <input
            v-model="data.filters.keywords"
            type="text"
            class="form-control"
            placeholder="by id, username, email"
            aria-describedby="button-addon2"
          />
        </div>
        <div class="col-2">
          <label class="form-label">{{ locale.status }}</label>
          <select class="form-select" v-model="data.filters.status">
            <option value="1">{{ locale.active }}</option>
            <option value="0">{{ locale.in_active }}</option>
          </select>
        </div>
        <div class="col-2">
          <label class="form-label">{{ locale.clients }}</label>
          <select class="form-select" v-model="data.filters.client_id">
            <option
              v-for="(item, i) in data.clientsList"
              v-bind:key="i"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <label class="form-label">{{ locale.channels }}</label>
          <select class="form-select" v-model="data.filters.channel_id">
            <option
              v-for="(item, i) in data.channelsList"
              v-bind:key="i"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <!-- <div class="col-2">
                    <label class="form-label">{{locale.view}} {{locale.delete}} {{locale.users}} </label>
                    <select class="form-select" v-model="data.filters.is_deleted">
                        <option value="0">{{locale.no}}</option>
                        <option value="1">{{locale.yes}}</option>
                    </select>
                </div> -->
      </div>

      <!--Test comment 2-->

      <div class="row mt-3">
        <div class="col-2">
          <label class="form-label">{{ locale.date }}</label>
          <select
            @change="changeDateTitle()"
            class="form-select"
            v-model="data.filters.date"
          >
            <option value="1">{{ locale.today }}</option>
            <option value="8">{{ locale.yesterday }}</option>
            <option value="2">{{ locale.last_7_days }}</option>
            <option value="3">{{ locale.last_30_days }}</option>
            <option value="4">{{ locale.last_90_days }}</option>
            <option value="5">{{ locale.last_365_days }}</option>
            <option value="6">{{ locale.last_2_years_days }}</option>
            <option value="7">{{ locale.last_3_years_days }}</option>
            <option value="9">{{ locale.custom_date_filter }}</option>
          </select>
        </div>

        <div class="col-2" v-if="data.filters.date == 9">
          <label class="form-label">{{ locale.start }} {{ locale.date }}</label>
          <input
            v-model="data.filters.start_date"
            type="date"
            class="form-control"
            placeholder="12/18/2020"
            aria-describedby="button-addon2"
          />
        </div>

        <div class="col-2" v-if="data.filters.date == 9">
          <label class="form-label">{{ locale.end }} {{ locale.date }}</label>
          <input
            v-model="data.filters.end_date"
            type="date"
            class="form-control"
            placeholder="12/18/2021"
            aria-describedby="button-addon2"
          />
        </div>
      </div>

      <button
        @click="searchRecords"
        class="btn btn-secondary my-3"
        type="button"
        id="button-addon2"
      >
        {{ locale.search }}
      </button>
      <button
        @click="resetFilters"
        class="btn btn-secondary my-3 mx-2"
        type="button"
        id="button-addon2"
      >
        {{ locale.reset }}
      </button>
      <hr />
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ locale.email }}</th>
            <th scope="col">{{ locale.status }}</th>
            <th scope="col">{{ locale.date_created }}</th>
            <th scope="col">{{ locale.extra }}</th>
            <th scope="col">{{ locale.action }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in data.records"
            v-bind:key="i"
            :class="item.is_deleted ? 'bg-danger' : ''"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.email ? item.email : item.username }}
              <i v-if="item.is_pro==1" title="SwoshsVPN Pro app user" v-html="icon_diamond"></i>
            </td>
            <td>{{ item.status == 1 ? locale.active : locale.in_active }}</td>
            <td>
              {{ $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}<br />
              {{ locale.date_update }}:
              {{ $moment(item.updatedAt).format("YYYY-MM-DD HH:mm:ss") }}
            </td>
            <td>
              <!-- {{locale.package}}: {{item.user_plan_name}}<br> -->
              {{ locale.email }} {{ locale.confirmed }}:
              {{
                item.is_email_verified == 1
                  ? locale.temp + " " + locale.yes
                  : item.is_email_verified == 2
                  ? locale.yes
                  : locale.no
              }}
              <br />
              {{ locale.cerification }} {{ locale.code }}:
              {{ item.verification_code }}<br />
              {{ locale.login }} {{ locale.code }}: {{ item.login_code }}<br />
              <!-- {{locale.user}} {{locale.level}}: {{item.user_level}}<br> -->
              <!-- {{locale.clients}}: {{item.client_name}}<br> -->
              <!-- {{locale.channels}}: {{item.channel_name}}<br> -->
              <!-- {{locale.language}}: {{item.preferred_language}} <br> -->
              {{ locale.invite }} {{ locale.code }}: {{ item.invite_code
              }}<br />
              {{ locale.referrer }} {{ locale.id }}: {{ item.referral_id
              }}<br />
              {{ locale.last_active_time }}:
              {{ $moment(item.last_active_time).format("YYYY-MM-DD HH:mm:ss")
              }}<br />
            </td>
            <td>
              <button
                v-if="item.id !== 1 && item.is_deleted == 0"
                @click="$redirect('/users/edit/?id=' + item.id)"
                type="button"
                class="btn btn-warning btn-sm my-1 mx-1"
              >
                {{ locale.edit }}
              </button>
              <button
                v-if="item.id !== 1 && item.is_deleted == 0"
                @click="deleteRecord(item.id)"
                type="button"
                class="btn btn-danger btn-sm my-1 mx-1"
              >
                {{ locale.delete }}
              </button>
              <button
                v-if="item.id !== 1 && item.is_deleted == 0"
                @click="showModals(item.id, item.username, item.user_plan_id)"
                type="button"
                class="btn btn-info btn-sm my-1 mx-1"
              >
                {{ locale.add }} {{ locale.package }}
              </button>
              <button
                v-if="item.id !== 1 && item.is_deleted == 0"
                @click="$redirect('/users/packages/?id=' + item.id)"
                type="button"
                class="btn btn-dark btn-sm my-1 mx-1"
              >
                {{ locale.manage_package }}
              </button>
              <button
                v-if="item.id !== 1 && item.is_deleted == 0"
                @click="$redirect('/users/devices/?id=' + item.id)"
                type="button"
                class="btn btn-primary btn-sm my-1 mx-1"
              >
                {{ locale.manage }} {{ locale.devices }}
              </button>
              <button
                @click="userModal(item.id)"
                type="button"
                class="btn btn-success btn-sm my-1 mx-1"
              >
                {{ locale.detail }}
              </button>
              <button
                v-if="item.id !== 1 && item.is_deleted == 0"
                @click="showModalsAddPoints(item.id,item.username,)"
                type="button"
                class="btn btn-secondary btn-sm my-1 mx-1"
              >
                {{ locale.add }} {{ locale.points }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex mb-5">
        <div class="col-10">
          <v-pagination
            v-model="data.page"
            :pages="data.totalPages"
            :range-size="2"
            active-color="#d2f4ea"
            @update:modelValue="getRecords"
          />
          <b>{{ locale.total }}: {{ data.totalRecords }}</b>
        </div>
        <div class="col-2">
          <select
            @change="getRecords()"
            class="form-select"
            v-model="data.perPage"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      v-if="data.showModal"
      style="display: block"
      class="modal fade show"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ locale.add }} {{ locale.package }}
            </h5>
            <button
              @click="data.showModal = false"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label class="form-label">{{ locale.username }} *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.add_package.username"
                  disabled
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{ locale.package }} *</label>
                <select class="form-select" v-model="data.add_package.plan_id">
                  <option
                    v-for="(item, i) in data.plansList"
                    v-bind:key="i"
                    :value="item.id"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="message-text" class="form-label">{{
                  locale.remark
                }}</label>
                <textarea
                  class="form-control"
                  v-model="data.add_package.remark"
                ></textarea>
              </div>
              <div class="mb-3">
                <label class="form-label"
                  >{{ locale.order }} {{ locale.number }}</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="data.add_package.order_number"
                />
              </div>
              <div class="mb-3">
                <label class="form-label"
                  >{{ locale.allowed }} {{ locale.devices }} *</label
                >
                <select
                  class="form-select"
                  v-model="data.add_package.total_allowed_devices"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>
                  <option value="60">60</option>
                  <option value="61">61</option>
                  <option value="62">62</option>
                  <option value="63">63</option>
                  <option value="64">64</option>
                  <option value="65">65</option>
                  <option value="66">66</option>
                  <option value="67">67</option>
                  <option value="68">68</option>
                  <option value="69">69</option>
                  <option value="70">70</option>
                  <option value="71">71</option>
                  <option value="72">72</option>
                  <option value="73">73</option>
                  <option value="74">74</option>
                  <option value="75">75</option>
                  <option value="76">76</option>
                  <option value="77">77</option>
                  <option value="78">78</option>
                  <option value="79">79</option>
                  <option value="80">80</option>
                  <option value="81">81</option>
                  <option value="82">82</option>
                  <option value="83">83</option>
                  <option value="84">84</option>
                  <option value="85">85</option>
                  <option value="86">86</option>
                  <option value="87">87</option>
                  <option value="88">88</option>
                  <option value="89">89</option>
                  <option value="90">90</option>
                  <option value="91">91</option>
                  <option value="92">92</option>
                  <option value="93">93</option>
                  <option value="94">94</option>
                  <option value="95">95</option>
                  <option value="96">96</option>
                  <option value="97">97</option>
                  <option value="98">98</option>
                  <option value="99">99</option>
                  <option value="100">100</option>
                </select>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              @click="submitAddPackage"
              type="button"
              class="btn btn-secondary"
            >
              {{ locale.submit }}
              <span
                v-if="data.submitBtn"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button
              @click="data.showModal = false"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              {{ locale.close }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add Points-->
    <div
      v-if="data.showModalAddPoint"
      style="display: block"
      class="modal fade show"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ locale.add }} {{ locale.points }}
            </h5>
            <button
              @click="data.showModalAddPoint = false"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form>
              <div class="mb-3">
                <label class="form-label">{{ locale.username }} *</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="data.add_points.username"
                  disabled
                />
              </div>
              <div class="mb-3">
                <label class="form-label">{{locale.points}} {{locale.settings}}</label>
                <select class="form-select" v-model="data.add_points.point_type_settings_id">
                  <option
                    v-for="(item, i) in data.point_type_settings_list"
                    v-bind:key="i"
                    :value="item.id"
                  >
                    {{ checkEarnfrom(item.id) }} ({{ item.total_point }})
                  </option>
                </select>
              </div>
              
              
            </form>
          </div>
          <div class="modal-footer">
            <button
              @click="submitAddPoints"
              type="button"
              class="btn btn-secondary"
            >
              {{ locale.submit }}
              <span
                v-if="data.submitBtn"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button
              @click="data.showModalAddPoint = false"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              {{ locale.close }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- user detail Modal -->
    <div
      v-if="data.show_userModal"
      style="display: block"
      class="modal fade show"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ locale.user }} {{ locale.detail }}
            </h5>
            <button
              @click="data.show_userModal = false"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.user_id }} :</b></span
                    >&nbsp;&nbsp;<span>{{ data.user_detail.id }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.username }}:</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.username || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.email }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.email || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.first }} {{ locale.name }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.first_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.last }} {{ locale.name }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.last_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.phone }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.phone || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.country_code }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.phone_country_code || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.user }} {{ locale.ip }} :</b></span
                    >&nbsp;&nbsp;<span>{{ data.user_detail.user_ip }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.date_created }} :</b></span
                    >&nbsp;&nbsp;<span>{{ data.user_detail.createdAt }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.date_update }} :</b></span
                    >&nbsp;&nbsp;<span>{{ data.user_detail.updatedAt }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.preffered_language }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.preferred_language || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.preffered_theme }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.preferred_theme || "N/A"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.channels }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.channel_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.clients }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.client_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.status }} :</b></span
                    >&nbsp;&nbsp;<span v-if="data.user_detail.status == 0">{{
                      locale.in_active
                    }}</span
                    ><span v-else>{{ locale.active }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.last_active_time }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.last_active_time || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.guest_user }} :</b></span
                    >&nbsp;&nbsp;<span v-if="data.user_detail.is_guest == 0">{{
                      locale.no
                    }}</span
                    ><span v-else>{{ locale.yes }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.deleted_user }} :</b></span
                    >&nbsp;&nbsp;<span
                      v-if="data.user_detail.is_deleted == 0"
                      >{{ locale.no }}</span
                    ><span v-else>{{ locale.yes }}</span>
                  </div>

                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.pro }} {{ locale.user }} :</b></span
                    >&nbsp;&nbsp;<span v-if="data.user_detail.is_pro == 1">{{
                      locale.yes
                    }} <i title="SwoshsVPN Pro app user" v-html="icon_diamond"></i>
                    </span
                    ><span v-else>{{ locale.no }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.email }} {{ locale.confirmed }} :</b></span
                    >&nbsp;&nbsp;<span
                      >{{
                        data.user_detail.is_email_verified == 1
                          ? locale.temp + " " + locale.yes
                          : data.user_detail.is_email_verified == 2
                          ? locale.yes
                          : locale.no
                      }}
                    </span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.verification_code }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.verification_code || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.login }} {{ locale.code }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.login_code || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.invite }} {{ locale.code }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.invite_code || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.refferal_id }} :</b></span
                    >&nbsp;&nbsp;<span>{{
                      data.user_detail.referral_id || "N/A"
                    }}</span>
                  </div>
                  <div class="col-lg-6 mb-3">
                    <span
                      ><b>{{ locale.points }} :</b></span
                    >&nbsp;&nbsp;<span>{{ data.user_detail.points }}</span>
                  </div>
                </div>
              </div>
            </div>

            <h4 class="text-center mt-3 mb-3">{{ locale.order_history }}</h4>
            <div class="card mb-3 mt-3">
              <div class="card-body">
                <div
                  v-show="data.user_order_history.length == 0"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ locale.order_not_find }}
                </div>
                <div
                  class="table-responsive"
                  v-show="data.user_order_history.length > 0"
                >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">
                          {{ locale.order }} {{ locale.number }}
                        </th>
                        <th scope="col">{{ locale.amount }}</th>
                        <th scope="col">
                          {{ locale.payment }} {{ locale.methods }}
                        </th>
                        <th scope="col">{{ locale.status }}</th>
                        <th scope="col">{{ locale.date_created }}</th>
                        <th scope="col">{{ locale.date_update }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in data.user_order_history"
                        v-bind:key="i"
                        :class="item.is_deleted ? 'bg-danger' : ''"
                      >
                        <td>{{ ++i }}</td>
                        <td>{{ item.order_number }}</td>
                        <td>{{ item.amount }} {{ item.currency_symbol }}</td>
                        <td>{{ item.payment_method_name }}</td>
                        <td>
                          <span
                            v-if="item.status == 0"
                            class="badge bg-primary rounded-pill"
                            >{{ locale.un_paid }}</span
                          >
                          <span
                            v-else-if="item.status == 1"
                            class="badge bg-success rounded-pill"
                            >{{ locale.paid }}</span
                          >
                          <span
                            v-else-if="item.status == 2"
                            class="badge bg-secondary rounded-pill"
                            >{{ locale.pending }}</span
                          >
                          <span
                            v-else-if="item.status == 3"
                            class="badge bg-warning rounded-pill"
                            >{{ locale.cancelled }}</span
                          >
                          <span
                            v-else-if="item.status == 4"
                            class="badge bg-danger rounded-pill"
                            >{{ locale.rejected }}</span
                          >
                          <span
                            v-else-if="item.status == 5"
                            class="badge bg-info rounded-pill"
                            >{{ locale.refund }}</span
                          >
                          <span v-else class="badge bg-dark">{{
                            item.status
                          }}</span>
                        </td>
                        <td>{{ $formatDate(item.created_at) }}</td>
                        <td>{{ $formatDate(item.updated_at) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <h4 class="text-center mt-3 mb-3">{{ locale.packages_history }}</h4>
            <div class="card mb-3 mt-3">
              <div class="card-body">
                <div
                  v-show="data.user_packages_list.length == 0"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ locale.packages_not_find }}
                </div>
                <div
                  class="table-responsive"
                  v-show="data.user_packages_list.length > 0"
                >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ locale.plan }}</th>
                        <th scope="col">
                          {{ locale.start }} {{ locale.date }}
                        </th>
                        <th scope="col">{{ locale.end }} {{ locale.date }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in data.user_packages_list"
                        v-bind:key="i"
                      >
                        <td>{{ ++i }}</td>
                        <td>{{ item.plan_name }}</td>
                        <td>{{ $formatDate(item.start_date) }}</td>
                        <td>{{ $formatDate(item.end_date) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <h4 class="text-center mt-3 mb-3">{{ locale.earn_points }}</h4>
            <div class="card mb-3 mt-3">
              <div class="card-body">
                <div
                  v-show="data.user_earned_points_list.length == 0"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ locale.earn_points_not_found }}
                </div>
                <div
                  class="table-responsive"
                  v-show="data.user_earned_points_list.length > 0"
                >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ locale.date }}</th>
                        <th scope="col">{{ locale.points }}</th>
                        <th scope="col">{{ locale.earn_from }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in data.user_earned_points_list"
                        v-bind:key="i"
                      >
                        <td>{{ ++i }}</td>
                        <td>{{ $formatDate(item.created_at) }}</td>
                        <td>{{ item.point }}</td>
                        <td>{{ checkEarnfrom(item.point_type) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <h4 class="text-center mt-3 mb-3">{{ locale.redeem_points }}</h4>
            <div class="card mb-3 mt-3">
              <div class="card-body">
                <div
                  v-show="data.user_redeemed_points_list.length == 0"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ locale.redeem_points_not_found }}
                </div>
                <div
                  class="table-responsive"
                  v-show="data.user_redeemed_points_list.length > 0"
                >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ locale.date }}</th>
                        <th scope="col">{{ locale.redeem_points }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in data.user_redeemed_points_list"
                        v-bind:key="i"
                      >
                        <td>{{ ++i }}</td>
                        <td>{{ $formatDate(item.created_at) }}</td>
                        <td>{{ item.redeem_points }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <h4 class="text-center mt-3 mb-3">
              {{ locale.user }} {{ locale.devices }}
            </h4>
            <div class="card mb-3 mt-3">
              <div class="card-body">
                <div
                  v-show="data.user_devices_list.length == 0"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ locale.devices_not_found }}
                </div>
                <div
                  class="table-responsive"
                  v-show="data.user_devices_list.length > 0"
                >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">#</th>

                        <th scope="col">{{ locale.name }}</th>
                        <th scope="col">{{ locale.udid }}</th>
                        <th scope="col">{{ locale.type }}</th>
                        <th scope="col">{{ locale.ip }}</th>
                        <th scope="col">{{ locale.date_created }}</th>
                        <th scope="col">{{ locale.firebase_token }}</th>
                        <th scope="col">{{ locale.firebase_updated_token }}</th>
                        <th scope="col">{{ locale.token }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in data.user_devices_list"
                        v-bind:key="i"
                        :class="item.is_deleted ? 'bg-danger' : ''"
                      >
                        <td>{{ ++i }}</td>

                        <td>{{ item.device_name }}</td>
                        <td>{{ item.device_udid }}</td>
                        <td>{{ item.device_type }}</td>
                        <td>{{ item.ip }}</td>
                        <td>{{ $formatDate(item.login_date) }}</td>
                        <td>{{ item.firebase_token }}</td>
                        <td>{{ item.firebase_token_updated }}</td>
                        <td>{{ item.auth_token }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <h4 class="text-center mt-3 mb-3">
              {{ locale.user }} {{ locale.ticket }}
            </h4>
            <div class="card mb-3 mt-3">
              <div class="card-body">
                <div
                  v-show="data.user_tickets_list.length == 0"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ locale.tickets_not_found }}
                </div>
                <div
                  class="table-responsive"
                  v-show="data.user_tickets_list.length > 0"
                >
                  <table class="table table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">#</th>

                        <th scope="col">{{ locale.subject }}</th>
                        <th scope="col">{{ locale.description }}</th>
                        <th scope="col">{{ locale.status }}</th>
                        <th scope="col">{{ locale.ip }}</th>
                        <th scope="col">{{ locale.date_created }}</th>
                        <th scope="col">{{ locale.date_update }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, i) in data.user_tickets_list"
                        v-bind:key="i"
                        :class="item.is_deleted ? 'bg-danger' : ''"
                      >
                        <td>{{ item.id }}</td>

                        <td>{{ item.subject }}</td>
                        <td>{{ item.description }}</td>
                        <td>
                          <span
                            v-if="item.status == 0"
                            class="badge bg-warning text-dark rounded-pill"
                            >{{ locale.pending }}</span
                          >
                          <span
                            v-else-if="item.status == 1"
                            class="badge bg-success rounded-pill"
                            >{{ locale.resolved }}</span
                          >
                          <span
                            v-else-if="item.status == 2"
                            class="badge bg-secondary rounded-pill"
                            >{{ locale.replied }}</span
                          >
                          <span
                            v-else-if="item.status == 3"
                            class="badge bg-danger rounded-pill"
                            >{{ locale.spam }}</span
                          >
                          <span v-else class="badge bg-dark">{{
                            item.status
                          }}</span>
                        </td>
                        <td>{{ item.ip }}</td>
                        <td>{{ $formatDate(item.createdAt) }}</td>
                        <td>{{ $formatDate(item.updatedAt) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              @click="close_user_modal()"
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              {{ locale.close }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, reactive, computed } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import {
  callApi,
  callPostApi,
  getChannelsList,
  getClientsList,
  getSystemPlansList,
  getPointTypeSettings,
} from "@/api/api";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { checkAccessSinglePage } from "@/utils/utils";

export default {
  name: "Users",
  components: {
    Breadcrumb,
    VPagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = computed(() => store.state.authenticated);
    const authUser = computed(() => store.state.authUser);
    const locale = computed(() => store.state.translation.locale);

    const data = reactive({
      endpoint: "/users",
      page: 1,
      perPage: 30,
      totalPages: 0,
      totalRecords: 0,
      records: [],
      loading: false,

      showFilter: false,
      filters: {
        keywords: "",
        client_id: "",
        channel_id: "",
        status: "",
        is_deleted: 0,
        date: 0,
        start_date: "",
        end_date: "",
      },

      clientsList: [],
      channelsList: [],

      showModal: false,
      showModalAddPoint: false,
      show_userModal: false,
      user_detail: {},
      plansList: [],
      user_order_history: [],
      user_packages_list: [],
      user_earned_points_list: [],
      user_redeemed_points_list: [],
      user_devices_list: [],
      user_tickets_list: [],
      point_type_settings_list: [],

      add_package: {
        user_id: "",
        username: "",
        plan_id: "",
        order_number: "",
        remark: "",
        total_allowed_devices: 1,
      },

      add_points: {
            user_id: "",
            username: "",
            point_type_settings_id: "",
      },

      submitBtn: false,
    });

    onMounted(async () => {
      if (!auth.value) router.push(`/login`);
      let isAllowAccessPage = await checkAccessSinglePage(
        "manage_users",
        authUser.value
      );
      if (!isAllowAccessPage) await router.push("/");

      getRecords();

      data.channelsList = await getChannelsList();
      data.clientsList = await getClientsList();

      data.plansList = await getSystemPlansList();
      
    });

    const getRecords = async () => {
      data.loading = true;
      let query_filter = new URLSearchParams(data.filters).toString();

      let resData = await callApi(
        data.endpoint +
          "?page=" +
          data.page +
          "&perPage=" +
          data.perPage +
          "&" +
          query_filter,
        "GET"
      );
      console.log(resData);
      data.records = resData.data;
      data.totalRecords = resData.totalRecords;
      data.totalPages = Math.ceil(resData.totalRecords / data.perPage);
      data.loading = false;
    };
    const deleteRecord = async (id) => {
      if (confirm(locale.value.confirm_delete)) {
        data.loading = true;
        let resData = await callApi(data.endpoint + "/" + id, "DELETE");
        data.loading = false;
        if (resData.isSuccess) {
          notify({ text: resData.message, type: "success" });
          getRecords();
        } else {
          notify({ text: resData.message, type: "error" });
        }
      }
    };

    const showModals = async (id, username, user_plan_id) => {
      data.showModal = true;
      data.add_package.user_id = id;
      data.add_package.username = username;
      data.add_package.plan_id = user_plan_id;
    }

    const searchRecords = async () => {
      data.page = 1;
      getRecords();
    };
    const showFilters = async () => {
      data.showFilter = data.showFilter ? false : true;
    };
    const resetFilters = async () => {
      data.filters = {
        keywords: "",
        client_id: "",
        channel_id: "",
        status: "",
        date: 0,
        start_date: "",
        end_date: "",
      };
      searchRecords();
    };


    const submitAddPackage = async () => {
      if (data.add_package.plan_id == "") {
        notify({ text: "Please select a package", type: "error" });
        return false;
      }
      data.submitBtn = true;
      let _body = data.add_package;
      console.log("add package", _body);
      let resData = await callPostApi(
        "/users/packages",
        "POST",
        JSON.stringify(_body)
      );
      data.submitBtn = false;
      if (resData.isSuccess) {
        notify({ text: resData.message, type: "success" });
        getRecords();
        data.showModal = false;
        data.add_package = {
          user_id: "",
          username: "",
          plan_id: "",
          order_number: "",
          remark: "",
          total_allowed_devices: 1,
        };
      } else {
        notify({ text: resData.message, type: "error" });
      }
    };
    // const refresh = ()=>{
    //     getRecords();
    // }

    
    const showModalsAddPoints = async (userId, username) => {
        data.point_type_settings_list = await getPointTypeSettings();
        data.showModalAddPoint = true
        data.add_points.user_id = userId
        data.add_points.username = username
    };

    const submitAddPoints = async () => {
      if (data.add_points.point_type_settings_id == "") {
        notify({ text: "Please select a point type", type: "error" });
        return false;
      }
      data.submitBtn = true;
      let _body = data.add_points;
      console.log("add points", _body);
      let resData = await callPostApi(
        "/earned-points",
        "POST",
        JSON.stringify(_body)
      );
      data.submitBtn = false;
      if (resData.isSuccess) {
        notify({ text: resData.message, type: "success" });
        getRecords();
        data.showModalAddPoint = false;
        userModal(data.add_points.user_id)
        data.add_points = {
          user_id: "",
          username: "",
          point_type_settings_id: "",
          
        };
        
      } else {
        notify({ text: resData.message, type: "error" });
      }
    };

    const changeDateTitle = async () => {
      if (data.filters.date == 1) data.date_title = locale.value.today;
      else if (data.filters.date == 2)
        data.date_title = locale.value.last_7_days;
      else if (data.filters.date == 3)
        data.date_title = locale.value.last_30_days;
      else if (data.filters.date == 4)
        data.date_title = locale.value.last_90_days;
      else if (data.filters.date == 5)
        data.date_title = locale.value.last_365_days;
      else if (data.filters.date == 6)
        data.date_title = locale.value.last_2_years_days;
      else if (data.filters.date == 7)
        data.date_title = locale.value.last_3_years_days;
      else if (data.filters.date == 8) data.date_title = locale.value.yesterday;
      else if (data.filters.date == 9)
        data.date_title = locale.value.custom_date_filter;
    };

    const userModal = async (id) => {
      data.loading = true;
      let resData = await callApi(data.endpoint + "/" + id, "GET");
      console.log("UserDetail", resData);
      data.user_detail = await resData.data;
      data.user_order_history = await resData.orders;
      console.log("orderArrAy", data.user_order_history);
      data.user_packages_list = await resData.packages;
      console.log("UserPackages", data.user_packages_list);
      data.user_earned_points_list = await resData.earn_points;
      console.log("earnpoints", data.user_earned_points_list);
      data.user_redeemed_points_list = await resData.redeem_points;
      console.log("redeempoints", data.user_redeemed_points_list);
      data.user_devices_list = await resData.devices;
      console.log("deviceslist", data.user_devices_list);
      data.user_tickets_list = await resData.tickets;
      console.log("usertickets", data.user_tickets_list);
      data.loading = false;
      data.show_userModal = true;
    };

    const close_user_modal = () => {
      (data.user_order_history = []),
        (data.user_packages_list = []),
        (data.show_userModal = false);
    };

    const checkEarnfrom = (item) => {
      switch (item) {
        case 1:
          return locale.value.purchase_1_month;

        case 2:
          return locale.value.purchase_6_month;

        case 3:
          return locale.value.purchase_12_month;

        case 4:
          return locale.value.verify_email;

        case 5:
          return locale.value.refer_friend;

        case 6:
          return locale.value.purcahse_1_1_month_package;
      }
    };

    return {
      data,
      locale,
      deleteRecord,
      getRecords,
      searchRecords,
      showFilters,
      resetFilters,
      showModals,
      submitAddPackage,
      showModalsAddPoints,
      submitAddPoints,
      // refresh
      changeDateTitle,
      userModal,
      close_user_modal,
      checkEarnfrom,
    };
  },
};
</script>
