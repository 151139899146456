import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd69ceb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-9 ms-sm-auto col-lg-10 px-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString($setup.locale.welcome) + " " + _toDisplayString($setup.locale.to) + " Swoshs " + _toDisplayString($setup.locale.console), 1),
    _createVNode(_component_Breadcrumb, {
      is_home: true,
      item_one_title: '',
      item_one_active: false,
      loading: $setup.data.loading
    }, null, 8, ["loading"])
  ]))
}